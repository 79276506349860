import {Component, Input} from '@angular/core';
import {MediaSourceController} from "../media.source.controller";

@Component({
  selector: 'app-media-numpad',
  templateUrl: './media-numpad.component.html',
  styleUrls: ['./media-numpad.component.scss']
})
export class MediaNumpadComponent {
  @Input() activeController: MediaSourceController | undefined;
}
