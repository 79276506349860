<div class="sleep-timer-control" *ngIf="area">
<!--  <label for="sleep-timer-slider">Sleep Timer</label>-->
  <span>Sleep Timer</span>
  <app-toggle [state]="area.sleepTimerActive"
              (stateChange)="sleepTimerToggle()"></app-toggle>

<!--  <section class="slider-component" #container *ngIf="area.sleepTimerActive">-->
<!--    <div class="slider-container">-->
<!--      <div class="slider-fill-left" #fill></div>-->
<!--      <div class="slider-fill-right" #bg></div>-->
<!--      <div class="slider-bubble" #bubble></div>-->
<!--      <input-->
<!--        #slider-->
<!--        id="sleep-timer-slider"-->
<!--        class="slider"-->
<!--        type="range"-->
<!--        [min]="this.minLevel"-->
<!--        [max]="this.maxLevel"-->
<!--        step="300"-->
<!--        [(ngModel)]="sleepTimerValue"-->
<!--        (input)="updateSlider($event)"-->
<!--        (pointerdown)="sliderPointerEvent($event)"-->
<!--        (pointerup)="sliderPointerEvent($event)"-->
<!--        (change)="sliderChangeEvent($event)">-->
<!--    </div>-->
<!--  </section>-->
  <span class="timer-display"  *ngIf="area.sleepTimerActive">{{ area.sleepTimerDisplay }}</span>
</div>
