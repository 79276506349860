import {Component, Input, OnInit, Type} from '@angular/core';
import {Area} from "../../../services/configuration/area";
import {Controller} from "../../../services/configuration/controller";
import {PageConfig} from "../../pagination/pagination.component";
import {CardRegistryService} from "../../../services/registries/card.registry.service";
import {MediaAreaController} from "../../../pages/media/media.area.controller";
import {ControlType} from "../../../services/registries/control.type";
import {LightingAreaController} from "../../../pages/lighting/lighting.area.controller";

@Component({
  selector: 'app-controller-container-card',
  templateUrl: './controller-container-card.component.html',
  styleUrl: './controller-container-card.component.scss'
})
export class ControllerContainerCardComponent implements OnInit {
  @Input() area: Area | undefined;
  @Input() controllers: Controller | Controller[] | undefined;

  protected pageConfigs: PageConfig[] = [];
  protected hideDots: boolean = true;

  ngOnInit(): void {
    if (this.controllers == undefined)
      return;
    if (this.controllers instanceof Array) {
      this.addControllersAsArray(this.controllers);
      return;
    }
    this.addControllersAsSingle(this.controllers);
  }

  private addControllersAsArray(controllers: Controller[]): void {
    this.hideDots = controllers.length <= 1;
    controllers.forEach((controller: Controller): void => {
      const componentType = CardRegistryService.getComponent(controller.controlType);
      if (componentType === undefined) return;
      this.pageConfigs.push({
        visible: true,
        component: componentType,
        inputs: {
          controller: controller
        }
      })
    });
  }

  private addControllersAsSingle(controller: Controller): void {
    let componentType: Type<any> | undefined;
    if (controller instanceof MediaAreaController)
      componentType = CardRegistryService.getComponent(ControlType.MediaArea);
    else if (controller instanceof LightingAreaController)
      componentType = CardRegistryService.getComponent(ControlType.Lighting);
    else
      componentType = CardRegistryService.getComponent(controller.controlType);
    
    if (componentType === undefined) return;
    this.pageConfigs.push({
      visible: true,
      component: componentType,
      inputs: {
        controller: controller
      }
    });
  }
}
