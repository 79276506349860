import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConnectionStatus} from 'src/app/services/communications/connection.status';
import {Subscription} from "rxjs";
import {CommunicationsService} from "../../services/communications/communications.service";
import {PageService} from "../../services/interface/page.service";
import {DatabaseService} from "../../services/storage/database.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ConnectionValid} from "../../services/communications/connection.valid";
import {LoginState} from "./login.state";
import {LoginResponse} from "./login.response";
import {ConfigurationService} from "../../services/configuration/configuration.service";
import {MotionSlide} from "../../services/interface/motionSlide";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('slideUpDown', [
      state('up', style({
        bottom: '20%',
        opacity: 1
      })),
      state('down', style({
        bottom: '-100%',
        opacity: 0
      })),
      transition('up <=> down', [
        animate('0.3s ease-in-out')
      ])
    ])
  ]
})
export class LoginComponent implements OnInit, OnDestroy {
  ConnectionStatus = ConnectionStatus;
  lockAllow: boolean = false;
  lockScreenVisibility: boolean = false;
  lockedLoginForm: boolean = false;
  message: string = '';
  loginState: LoginState = LoginState.LoggedOut;

  private hostname: HTMLInputElement | undefined;

  @ViewChild('hostname')
  set _hostname(element: ElementRef) {
    if (element) this.hostname = element.nativeElement;
  }

  private username: HTMLInputElement | undefined;

  @ViewChild('username')
  set _username(element: ElementRef) {
    if (element) this.username = element.nativeElement;
  }

  private password: HTMLInputElement | undefined;

  @ViewChild('password')
  set _password(element: ElementRef) {
    if (element) this.password = element.nativeElement;
  }

  public useWss: boolean = false;

  private _subscriptions: Subscription[] = [];

  constructor(protected communicationsService: CommunicationsService, protected databaseService: DatabaseService, protected pageService: PageService, protected configurationService: ConfigurationService) {
    this._subscriptions.push(communicationsService.loginResponse.subscribe((result: LoginResponse): void => {
      this.message = result.message;
      if (!result.success) {
        this.loginState = LoginState.Error;
        this.communicationsService.status = ConnectionStatus.Disconnected;
        this.communicationsService.valid = ConnectionValid.False;
        return;
      }
      this.handleSuccessfulLogin();
    }));

    if (this.communicationsService.authenticated) {
      this.handleSuccessfulLogin();
    }

    this._subscriptions.push(communicationsService.connectionChanged.subscribe((state: ConnectionStatus): void => {
      if (state != ConnectionStatus.Disconnected) return;
      this.pageService.closeMenus();
      this.lockAllow = false;
      this.lockScreenVisibility = this.communicationsService.valid == ConnectionValid.False;
      this.lockedLoginForm = true;
      this.loginState = LoginState.LoggedOut;
    }));
  }

  public ngOnInit(): void {
    this.lockScreenVisibility = this.communicationsService.valid == ConnectionValid.False;
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  protected lockScreen(event: PointerEvent | MouseEvent | TouchEvent): void {
    if (!(event.target instanceof HTMLElement)) return;
    if (event.target.classList.length == 0) return;

    if (!(event.target.classList[0] == "login-component" ||
      event.target.classList[0] == "small-circle" ||
      event.target.classList[0] == "overlay" ||
      event.target.classList[0] == "fa-solid")
    ) return;

    if (this.loginState == LoginState.LoggedIn && this.lockScreenVisibility) {
      this.lockScreenVisibility = false;
      return;
    }
    if (!this.lockedLoginForm && this.lockScreenVisibility)
      this.lockedLoginForm = true;

    this.pageService.closeMenus();
    this.lockScreenVisibility = true;
  }

  protected keydown(event: KeyboardEvent): void {
    this.message = '';
    if (event.key == 'Enter') this.submit();
  }

  protected submit(): void {
    if (!(this.loginState == LoginState.LoggedOut || this.loginState == LoginState.Error))
      return;
    
    if (!this.validateFields())
      return;
    
    this.loginState = LoginState.Authenticating;
    if (this.communicationsService.status == ConnectionStatus.Connected) {
      this.communicationsService.login(this.username!.value, this.password!.value);
      return;
    }

    const hostname: string = this.hostname?.value ?? this.communicationsService.settings!.url!;
    if (!this.validateHostnameOrIp(hostname))
      return;

    this.communicationsService.connect(hostname, this.username!.value, this.password!.value, this.useWss, false);
  }

  protected login(): void {
    if (!(this.loginState == LoginState.LoggedOut || this.loginState == LoginState.Error) || !(this.communicationsService.status == ConnectionStatus.Disconnected || this.communicationsService.status == ConnectionStatus.Failure))
      return;
    
    this.loginState = LoginState.Connecting;
    
    const hostname: string = this.hostname?.value ?? this.communicationsService.settings!.url!;
    if (!this.validateHostnameOrIp(hostname))
      return;
    
    this.communicationsService.connect(hostname, this.communicationsService.settings?.username ?? 'crestron', '', false, false);
  }

  protected lock(): void {
    if (this.communicationsService.status != ConnectionStatus.Connected) return;
    this.loginState = LoginState.LoggedOut;
    this.communicationsService.logout();
    this.lockAllow = false;
  }

  private handleSuccessfulLogin(): void {
    this.loginState = LoginState.LoggedIn;

    setTimeout(() => {
      this.communicationsService.statusMessage = '';
      this.communicationsService.status = ConnectionStatus.Connected;
      this.communicationsService.valid = ConnectionValid.True;
      this.lockedLoginForm = false;
      this.lockScreenVisibility = false;
      
      this.lockAllow = true;
      if (this.configurationService.systems.length > 0)
        this.pageService.changePage(this.configurationService.systems[0].pageType, MotionSlide.Ignore);
    }, 1500);
  }

  private validateFields(): boolean {
    if ((this.hostname == undefined || this.hostname.value == '') && this.communicationsService.settings?.url == undefined) {
      // TODO: add error message that the hostname must be supplied
      return false;
    }
    if (this.username == undefined || this.username.value == '') {
      // TODO: add error message that the username must be supplied
      return false;
    }
    if (this.password == undefined || this.password.value == '') {
      // TODO: add error message that the password must be supplied
      return false;
    }

    return true;
  }

  private validateHostnameOrIp(input: string): boolean {
    // RegExp for IPv4
    const ipv4RegExp = new RegExp(
      '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.' +
      '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.' +
      '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.' +
      '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
    );

    // RegExp for Hostname (RFC 1034 and RFC 1123 compliant)
    // Allows hostnames that have 0 or more dots (local network hostnames)
    const hostnameRegExp = new RegExp(
      '^([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)(\\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)*$'
    );

    return ipv4RegExp.test(input) || hostnameRegExp.test(input);
  }
}
