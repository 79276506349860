import {ContractHandler} from "./contract.handler";
import {AreaController} from "./area.controller";
import {ISystem, SystemTypes} from "../support/isystem";
import {CommunicationsService} from "../communications/communications.service";
import {AreaRegistry} from "../registries/area.registry.service";
import {ControlType} from "../registries/control.type";
import {ControllerRegistry} from "../registries/controller.registry.service";
import {Controller} from "./controller";
import {logger} from "../support/logger";
import {ConfigurationService} from "./configuration.service";

export class Area extends ContractHandler {
  constructor(communicationsService: CommunicationsService, controlId: string) {
    super(communicationsService, controlId);
    this.pollAsync().then();
  }
  
  private areaControlIds: string[] = [];
  public areaContainer: boolean = false;
  public areas: Area[] = [];
  public parent: Area | undefined;

  public controllers: Map<ISystem, Controller> = new Map<ISystem, Controller>();
  public devices: Map<ISystem, Controller[]> = new Map<ISystem, Controller[]>();

  private static controllerType: Map<ControlType, ISystem> = new Map<ControlType, ISystem>([
    //[ControlType.Unknown, SystemTypes.Ignore],
    //[ControlType.AudioSwitcher, SystemTypes.Ignore],
    //[ControlType.VideoSwitcher, SystemTypes.Ignore],
    //[ControlType.AudioVideoSwitcher, SystemTypes.Ignore],
    //[ControlType.Display, SystemTypes.Ignore],
    //[ControlType.PreAmplifier, SystemTypes.Ignore],
    //[ControlType.Receiver, SystemTypes.Ignore],
    //[ControlType.Amplifier, SystemTypes.Ignore],
    [ControlType.AudioSource, SystemTypes.Media],
    [ControlType.VideoSource, SystemTypes.Media],
    [ControlType.AudioVideoSource, SystemTypes.Media],
    [ControlType.AudioVideoSourceMediaPlayer, SystemTypes.Media],
    [ControlType.AudioSourceMediaPlayer, SystemTypes.Media],
    [ControlType.VideoSourceMediaPlayer, SystemTypes.Media],
    [ControlType.Lighting, SystemTypes.Lighting],
    [ControlType.LightingScene, SystemTypes.Lighting],
    [ControlType.Shade, SystemTypes.Shades],
    [ControlType.ShadeScene, SystemTypes.Shades],
    //[ControlType.Area, SystemTypes.Ignore],
    [ControlType.Climate, SystemTypes.Climate],
    [ControlType.Camera, SystemTypes.Cameras],
    [ControlType.Power, SystemTypes.Power],
    [ControlType.Pool, SystemTypes.PoolSpa],
    //[ControlType.Support, SystemTypes.Ignore],
    [ControlType.PreAmpOutput, SystemTypes.Media],
    //[ControlType.AmplifiedOutput, SystemTypes.Ignore],
    //[ControlType.SwitcherOutput", SystemTypes.Ignore],
    //[ControlType.MediaArea, SystemTypes.Ignore],
    [ControlType.Outlet, SystemTypes.Power],
    //[ControlType.Intercom, SystemTypes.Ignore],
    [ControlType.DoorLock, SystemTypes.DoorLocks]
  ]);

  public parseContractResponse(response: any): void {
    this.name = response.name;
    if (response['interfaces'] !== undefined)
      this.interfaces.push(...response['interfaces']);

    if (response.controllers && typeof response.controllers === 'object') {
      for (const [controllerType, controllerIds] of Object.entries(response.controllers)) {
        if (!Array.isArray(controllerIds)) continue;

        const controlType = this.stringToEnum(ControlType, controllerType);
        if (controlType === undefined) continue;

        if (controlType == ControlType.Area)
        {
          this.areaControlIds.push(...controllerIds);
          this.areaContainer = true;
          continue;
        }
        
        const systemType = Area.controllerType.get(controlType);
        if (systemType === undefined) continue;

        const controller: Controller | undefined = this.controllers.get(systemType) || AreaRegistry.create(systemType, this.communicationsService, response, controllerIds);
        if (controller === undefined) {
          const controllers: Controller[] = this.devices.get(systemType) ?? [];
          controllerIds.forEach((controllerId: string) => {
            const controller: Controller | undefined = ControllerRegistry.getControllerById(controllerId);
            if (controller === undefined) return;
            controllers.push(controller);
          });
          this.devices.set(systemType, controllers);
          continue;
        }
        controller.name = this.name;
        if (controller instanceof AreaController)
          controller.addControlIds(controllerIds);
        
        //logger.debug(`controller is ${controllerType}`, this)
        
        controller.processApiContracts(response);
        this.controllers.set(systemType, controller);
      }
      
      this.controllers.forEach((controller: Controller) => {
        if (controller instanceof AreaController)
          controller.processControllerIds();
      });
    }
  }

  public clear(): void {
    this.controllers.forEach((controller: Controller) => {
      controller.dispose();
    })
    this.controllers.clear();
    this.devices.clear();
  }

  public setAreaParent(configurationService: ConfigurationService): void {
    for (let i = 0; i<this.areaControlIds.length; i++)
    {
      const controlId = this.areaControlIds[i];
      const area = configurationService.findAreaByControlId(controlId);
      if (area === undefined) continue;
      this.areas.push(area);
      area.parent = this;
    }
  }


  private stringToEnum<E extends object>(enumObj: E, str: string): E[keyof E] | undefined {
    if (str in enumObj) {
      return enumObj[str as keyof E];
    }
    return undefined; // or throw an error, based on your error handling strategy
  }
}
