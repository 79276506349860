import {Component} from '@angular/core';
import {ClimateController} from "../../../pages/climate/climate.controller";
import {CardBase} from "../card.base";

@Component({
  selector: 'app-climate-card',
  templateUrl: './climate-card.component.html',
  styleUrl: './climate-card.component.scss'
})
export class ClimateCardComponent extends CardBase<ClimateController>{

}