import {Controller} from "../../services/configuration/controller";
import {CommunicationsService} from "../../services/communications/communications.service";
import {OnOffState} from "../../services/support/on.off.state";
import {ControlType} from "../../services/registries/control.type";

export class LightingSceneController extends Controller {
  constructor(communicationsService: CommunicationsService, controlId: string, apiContracts: any, controlType: ControlType) {
    super(communicationsService, controlId, apiContracts, controlType);
    //logger.debug(`[ ${this.constructor.name} ] - [ ${apiContracts.name} ] has contracts of`, apiContracts);
  }

  public powerState: boolean = false;
  public toggle(state: OnOffState | boolean): void {
    
    let newState: boolean = false;
    if (typeof state === 'boolean')
      newState = state;
    else
      newState = state == OnOffState.On;
    this.communicationsService.sendControlMessage(this.controlId, "setPower", {action: newState ? 2 : 1});
  }

  /*#region "Contract Handlers"*/
  private setPowerContractHandler(contract: any): void {
  }
  /*#endregion*/

  /*#region "Status Update Handlers"*/
  private powerStateUpdate(params: any): void {
    this.powerState = params.state.value == 2;
  }
  /*#endregion*/
}
