import {Controller} from "../../services/configuration/controller";
import {CommunicationsService} from "../../services/communications/communications.service";
import {ControlOption} from "../../services/support/control.option";
import {logger} from "../../services/support/logger";
import {DoorLocksAction} from "./door-locks-action";
import {DoorLocksState} from "./door-locks-state";
import {OnOffState} from "../../services/support/on.off.state";
import {ControlType} from "../../services/registries/control.type";

export class DoorLocksController extends Controller {
  constructor(communicationsService: CommunicationsService, controlId: string, apiContracts: any, controlType: ControlType) {
    super(communicationsService, controlId, apiContracts, controlType);
    //logger.debug(`[ ${this.constructor.name} ] - [ ${apiContracts.name} ] has contracts of`, apiContracts);
  }

  public state: ControlOption = DoorLocksState.Unknown; //"setLock", "lockState"
  public batteryLevel: number | undefined;  //"batteryLevel"
  public batteryLevelIcon: string | undefined;
  public ledState: OnOffState | undefined; //"setLedState" "ledState"
  public autoReLockState: OnOffState | undefined; //"setAutoReLockState" "autoReLock"
  public autoReLockTime: string | undefined; //"setAutoReLockTime" "autoReLockTime"
  public oneTouchState: OnOffState | undefined; //"setOneTouchState" "oneTouch"
  public beepState: OnOffState | undefined; //"setBeepState" "beepState"
  public privacyState: OnOffState | undefined; //"setPrivacyState" "privacyState"
  

  public availableLockActions: ControlOption[] | undefined; 
  
  public setLockState(action: ControlOption): void {
    this.communicationsService.sendControlMessage(this.controlId, "setLock", {action: action});
  }

  public setOnOffState(method: string, state: OnOffState | boolean): void {
    let action: {};
    if (typeof state === 'boolean')
      action = { value: state ? 2 : 1 };
    else
      action = { value: state == OnOffState.On ? 2 : 1 };

    this.communicationsService.sendControlMessage(this.controlId, method, {action: action});
  }

  /*#region "Contract Handlers"*/
  private setLockContractHandler(contract: any): void {
    this.availableLockActions = this.mapLockActions(contract.availableOptions);
  }

  private setLedStateContractHandler(contract: any): void {
  }

  private setAutoReLockStateContractHandler(contract: any): void {
  }

  private setAutoReLockTimeContractHandler(contract: any): void {
  }

  private setOneTouchStateContractHandler(contract: any): void {
  }

  private setBeepStateContractHandler(contract: any): void {
  }

  private setPrivacyStateContractHandler(contract: any): void {
  }
  /*#endregion*/

  /*#region "Status Update Handlers"*/
  private lockStateUpdate(params: any): void {
    if (this.availableLockActions === undefined) return;
    const index = this.availableLockActions.findIndex(action => action.value === params.state.value);
    if (index === -1) {
      logger.error(`[ ${this.constructor.name} ] We received a lock state update with a value not in the available options: ${params.state.label}:${params.state.value}`);
      return;
    }
    this.state = this.availableLockActions[index];
  }
  
  private batteryLevelUpdate(params: any): void {
    this.batteryLevel = Math.round((params.level * 100) / 65535);
    switch (true) {
      case (this.batteryLevel > 0 && this.batteryLevel < 20):
        this.batteryLevelIcon = 'fa-regular fa-battery-low';
        break;
      case (this.batteryLevel >= 20 && this.batteryLevel < 40):
        this.batteryLevelIcon = 'fa-regular fa-battery-quarter';
        break;
      case (this.batteryLevel >= 40 && this.batteryLevel < 60):
        this.batteryLevelIcon = 'fa-regular fa-battery-half';
        break;
      case (this.batteryLevel >= 60 && this.batteryLevel < 80):
        this.batteryLevelIcon = 'fa-regular fa-battery-three-quarters';
        break;
      case (this.batteryLevel >= 80):
        this.batteryLevelIcon = 'fa-regular fa-battery-full';
        break;
      default:
        this.batteryLevelIcon = 'fa-regular fa-battery-exclamation';
        break;
    }
    // <i class="fa-regular fa-battery-full"></i>
  }

  private ledStateUpdate(params: any): void {
    this.ledState = params.state.value == 1 ? OnOffState.On : OnOffState.Off;
  }
  private autoReLockUpdate(params: any): void {
    this.autoReLockState = params.state.value == 1 ? OnOffState.On : OnOffState.Off;
    this.autoReLockTime = params.time;
  }
  private oneTouchUpdate(params: any): void {
    this.oneTouchState = params.state.value == 1 ? OnOffState.On : OnOffState.Off;
  }
  private beepStateUpdate(params: any): void {
    this.beepState = params.state.value == 1 ? OnOffState.On : OnOffState.Off;
  }
  private privacyStateUpdate(params: any): void {
    this.privacyState = params.state.value == 1 ? OnOffState.On : OnOffState.Off;
  }
  /*#endregion*/

  private mapLockActions(contractOptions: ControlOption[]): ControlOption[] | undefined {
    if (!Array.isArray(contractOptions)) return undefined;

    const lockOptions = DoorLocksAction.toControlOptionArray();

    const mappedAndSortedOptions = contractOptions.map((option, index): ControlOption => {
      const DoorLocksAction = lockOptions.find(lockOption => lockOption.value === option.value);

      return DoorLocksAction ? { ...DoorLocksAction, index } : { ...option, index };
    }).sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));  // Sorting step

    return mappedAndSortedOptions;
  }
}
