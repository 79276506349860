import {Controller} from "../../services/configuration/controller";
import {CommunicationsService} from "../../services/communications/communications.service";
import {ControlOption} from "../../services/support/control.option";
import {StandardActions} from "./standard.actions";
import {AreaRegistry} from "../../services/registries/area.registry.service";
import {AreaController} from "../../services/configuration/area.controller";
import {ControlMessage} from "../../services/communications/messaging/controlMessage";
import {ControlResult} from "../../services/communications/messaging/controlResult";
import {helpers} from "../../services/support/helpers";
import {ServiceManager} from "../../services/serviceManager/service-manager.service";
import {ConfigurationService} from "../../services/configuration/configuration.service";
import {ControlType} from "../../services/registries/control.type";

export enum SourceType {
  Undefined,
  AudioSource,
  VideoSource,
  AudioVideoSource
}

export class MediaSourceController extends Controller {
  constructor(communicationsService: CommunicationsService, controlId: string, apiContracts: any, controlType: ControlType) {
    super(communicationsService, controlId, apiContracts, controlType);

    switch (apiContracts.controlType) {
      case "AudioVideoSource":
      case "AudioVideoSourceMediaPlayer":
        this.sourceType = SourceType.AudioVideoSource;
        break;
      case "VideoSource":
      case "VideoSourceMediaPlayer":
        this.sourceType = SourceType.VideoSource;
        break;
      case "AudioSource":
      case "AudioSourceMediaPlayer":
        this.sourceType = SourceType.AudioSource;
        break;
      default:
        this.sourceType = SourceType.Undefined;
        break;
    }

    const configurationService = ServiceManager.getService('configurationService');
    if (!(configurationService instanceof ConfigurationService)) return;
    configurationService.systemReady.subscribe(() => {
      this.communicationsService.sendMessageAndSubscribe(
        new ControlMessage(ControlMessage.IncrementId(), this.controlId, "getInUse", null), true, (response: ControlResult): void => {
          const result = JSON.parse(response.result);
          if (!Array.isArray(result)) return;
          this.processInUseBy(result)
        });
    });
  }

  public readonly sourceType: SourceType;
  public inUseBy: Controller[] = [];

  public transportActions: ControlOption[] | undefined;
  public navigationActions: ControlOption[] | undefined;
  public keypadActions: ControlOption[] | undefined;
  public miscActions: ControlOption[] | undefined;
  public colorButtonActions: ControlOption[] | undefined;
  public pageActions: ControlOption[] | undefined;
  public channelActions: ControlOption[] | undefined;

  private static allNavigationActions: ControlOption[] = [
    StandardActions.Up,
    StandardActions.Down,
    StandardActions.Left,
    StandardActions.Right,
    StandardActions.Select,
  ];

  private static allColorActions: ControlOption[] = [
    StandardActions.Red,
    StandardActions.Green,
    StandardActions.Yellow,
    StandardActions.Blue,
  ];

  private static allPageActions: ControlOption[] = [
    StandardActions.PageUp,
    StandardActions.PageDown,
  ];

  private static allChannelActions: ControlOption[] = [
    StandardActions.ChannelNext,
    StandardActions.ChannelPrevious,
    StandardActions.Last,
  ];

  public useFavorites: boolean = false;
  
  private processInUseBy(controlIds: string[]): void {
    const allControllers: AreaController[] = [];
    for (let i = 0; i < controlIds.length; i++) {
      const controllers = AreaRegistry.getControllersById(controlIds[i]);
      if (controllers === undefined || controllers.length === 0) continue;
      allControllers.push(controllers[0]);
    }
    this.inUseBy = allControllers.sort((a: AreaController, b: AreaController) => helpers.sort(a, b, 'name'));
  }

  /*#region "API Handlers"*/
  public miscClick(i: number, event: PointerEvent): void {
    const buttonState = event.buttons > 0;
    const action = this.miscActions![i];
    this.communicationsService.sendControlMessage(this.controlId, "navigate", {
      action: action,
      buttonState: buttonState
    });
  }

  public channelClick(i: number, event: PointerEvent): void {
    const buttonState = event.buttons > 0;
    const action = this.channelActions![i];
    this.communicationsService.sendControlMessage(this.controlId, "navigate", {
      action: action,
      buttonState: buttonState
    });
  }

  public pageClick(i: number, event: PointerEvent): void {
    const buttonState = event.buttons > 0;
    const action = this.pageActions![i];
    this.communicationsService.sendControlMessage(this.controlId, "navigate", {
      action: action,
      buttonState: buttonState
    });
  }

  public navigationClick(i: number, event: PointerEvent): void {
    const buttonState = event.buttons > 0;
    const action = this.navigationActions![i];
    this.communicationsService.sendControlMessage(this.controlId, "navigate", {
      action: action,
      buttonState: buttonState
    });
  }

  public keypadClick(i: number, event: PointerEvent): void {
    const buttonState = event.buttons > 0;
    const action = this.keypadActions![i];
    this.communicationsService.sendControlMessage(this.controlId, "keypad", {action: action, buttonState: buttonState});
  }

  public transportClick(i: number, event: PointerEvent): void {
    const buttonState = event.buttons > 0;
    const action = this.transportActions![i];
    this.communicationsService.sendControlMessage(this.controlId, "transport", {
      action: action,
      buttonState: buttonState
    });
  }

  /*#endregion*/

  /*#region "Contract Handlers"*/
  private setPowerContractHandler(contract: any): void {
    //logger.debug(`[ ${this.constructor.name} ] - [ ${this.name} ] power handler sees a contract of`, contract);
  }

  private favoritesContractHandler(contract: any): void {
    //logger.debug(`[ ${this.constructor.name} ] - [ ${this.name} ] favorites handler sees a contract of`, contract);
    this.useFavorites = true;
  }

  private navigateContractHandler(contract: any): void {
    //logger.debug(`[ ${this.constructor.name} ] - [ ${this.name} ] navigation handler sees a contract of`, contract);
    const allOptions = this.mapOptions(contract.availableOptions);

    const navigationActions = allOptions?.filter((option: ControlOption) =>
      MediaSourceController.allNavigationActions.some(navAction => navAction.value === option.value)
    );
    const colorButtonActions = allOptions?.filter((option: ControlOption) =>
      MediaSourceController.allColorActions.some(colorAction => colorAction.value === option.value)
    );
    const pageActions = allOptions?.filter((option: ControlOption) =>
      MediaSourceController.allPageActions.some(pageAction => pageAction.value === option.value)
    );
    const channelActions = allOptions?.filter((option: ControlOption) =>
      MediaSourceController.allChannelActions.some(channelAction => channelAction.value === option.value)
    );
    const miscActions = allOptions?.filter((option: ControlOption) =>
      !MediaSourceController.allNavigationActions.some(action => action.value === option.value) &&
      !MediaSourceController.allColorActions.some(action => action.value === option.value) &&
      !MediaSourceController.allPageActions.some(action => action.value === option.value) &&
      !MediaSourceController.allChannelActions.some(action => action.value === option.value)
    );

    this.navigationActions = navigationActions?.length ?? 0 > 0 ? navigationActions : undefined;
    this.colorButtonActions = colorButtonActions?.length ?? 0 > 0 ? colorButtonActions : undefined;
    this.miscActions = miscActions?.length ?? 0 > 0 ? miscActions : undefined;
    this.channelActions = channelActions?.length ?? 0 > 0 ? channelActions : undefined;
    this.pageActions = pageActions?.length ?? 0 > 0 ? pageActions : undefined;
    //this.miscActions = StandardActions.toControlOptionArray();
  }

  private transportContractHandler(contract: any): void {
    //logger.debug(`[ ${this.constructor.name} ] - [ ${this.name} ] transport handler sees a contract of`, contract);
    this.transportActions = this.mapOptions(contract.availableOptions);
  }

  private keypadContractHandler(contract: any): void {
    //logger.debug(`[ ${this.constructor.name} ] - [ ${this.name} ] keypad handler sees a contract of`, contract);
    this.keypadActions = this.mapOptions(contract.availableOptions);
  }

  /*#endregion*/

  /*#region "Status Update Handlers"*/
  private inUseUpdate(params: any): void {
    if (!Array.isArray(params.inUseBy)) return;
    this.processInUseBy(params.inUseBy);
  }

  /*#endregion*/

  private mapOptions(contractOptions: ControlOption[]): ControlOption[] | undefined {
    if (!Array.isArray(contractOptions)) return undefined;

    const standardActionOptions = StandardActions.toControlOptionArray();

    const mappedAndSortedOptions = contractOptions.map((option, index): ControlOption => {
      const standardAction = standardActionOptions.find(sa => sa.value === option.value);

      return standardAction ? {...standardAction, index} : {...option, index};
    }).sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));  // Sorting step

    return mappedAndSortedOptions;
  }
}
