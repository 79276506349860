import {Controller} from "../../services/configuration/controller";
import {CommunicationsService} from "../../services/communications/communications.service";
import {ClimateSetPoint} from "../climate/climate.setpoint";
import {logger} from "../../services/support/logger";
import {PoolSpaCircuitController} from "./pool-spa.circuit.controller";
import {ControllerRegistry} from "../../services/registries/controller.registry.service";
import {System} from "../../services/configuration/system";
import {ControlType} from "../../services/registries/control.type";

export class PoolSpaBodyController extends Controller {
    constructor(communicationsService: CommunicationsService, controlId: string, apiContracts: any, controlType: ControlType) {
        super(communicationsService, controlId, apiContracts, controlType);
        //logger.debug(`[ ${this.constructor.name} ] - [ ${apiContracts.name} ] has contracts of`, apiContracts);
        
        if (!Array.isArray(apiContracts.controllers['PoolCircuit'])) return;
        apiContracts.controllers['PoolCircuit'].forEach(async (controllerId: string) => {
            let controller: Controller | undefined = ControllerRegistry.getControllerById(controllerId);
            if (controller === undefined) controller = await System.CreateControllerAsync(communicationsService, controllerId);
            if (controller instanceof PoolSpaCircuitController && !this.circuits.includes(controller))
                this.circuits.push(controller);
        });
    }

    public temperature: { label: string, value: number } | undefined;
    public setPoints: ClimateSetPoint[] | undefined;
    public circuits: PoolSpaCircuitController[] = [];
    
    public override async pollAsync(): Promise<void> {
        await super.pollAsync();
        await Promise.all(this.circuits.map(circuit => circuit.pollAsync()));
    }

    public override unsubscribeFromStatusMessages(): void {
        super.unsubscribeFromStatusMessages();
        this.circuits.forEach(circuit => circuit.unsubscribeFromStatusMessages());
    }

    /*#region "Contract Handlers"*/
    private setPointIncreaseContractHandler(contract: any): void {
        for (const key in contract.availableOptions) {
            if (contract.availableOptions.hasOwnProperty(key)) {
                if (this.setPoints === undefined) this.setPoints = [];
                const setPointData = contract.availableOptions[key];
                const setPoint = new ClimateSetPoint(this.communicationsService, this.controlId, setPointData.mode, setPointData.value, setPointData.minimum, setPointData.maximum);
                this.setPoints.push(setPoint);
            }
        }
    }
    /*#endregion*/


    /*#region "Status Update Handlers"*/
    private setPointUpdate(params: any): void {
        if (this.setPoints === undefined || params.setPoint === undefined) return;        
        const setPoint: ClimateSetPoint | undefined = this.setPoints.find(setPoint => setPoint.mode.value == params.setPoint.mode.value);
        if (setPoint === undefined) {
            logger.error(`[ ${this.constructor.name} ] We received a set point update with a value not in the available options`);
            return;
        }
        setPoint.update(params.setPoint);
    }

    private temperatureUpdate(params: any): void {
        if (params.type.value !== 9) return;

        this.temperature = params.temperature;
    }
    /*#endregion*/
}