import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {PageService} from "../../services/interface/page.service";
import {PageSystem} from "../pageSystem";
import {CommunicationsService} from "../../services/communications/communications.service";
import {ConfigurationService} from "../../services/configuration/configuration.service";
import {SystemTypes} from "../../services/support/isystem";
import {ControllerRegistry} from "../../services/registries/controller.registry.service";
import {ControlType} from "../../services/registries/control.type";
import {PageConfig, PaginationComponent} from "../../components/pagination/pagination.component";

import {PoolSpaCircuitController} from "./pool-spa.circuit.controller";
import {PoolSpaController} from "./pool-spa.controller";
import {PoolSpaBodyController} from "./pool-spa.body.controller";
import {AuxListComponent} from "./aux-list/aux-list.component";
import {TempControlsComponent} from "./temp-controls/temp-controls.component";

@Component({
  selector: 'app-pool-spa',
  templateUrl: './pool-spa.component.html',
  styleUrls: ['./pool-spa.component.scss']
})
export class PoolSpaComponent extends PageSystem<PoolSpaController> implements AfterViewInit, OnDestroy {
  constructor(communicationsService: CommunicationsService, configurationService: ConfigurationService, pageService: PageService) {
    super(communicationsService, configurationService, pageService, SystemTypes.PoolSpa);
  }
  
  protected activeBody: PoolSpaBodyController | undefined;
  protected activeCircuits: PoolSpaCircuitController[] = [];
  protected poolPageConfigs: PageConfig[] = [
      {
        visible: true,
        component: TempControlsComponent,
        inputs: {
          activeController: undefined
        }
      },
    {
      visible: true,
      component: AuxListComponent,
      inputs: {
        activeCircuits: undefined
      }
    }
  ];
  
  protected override populateControllers(): PoolSpaController[] {
    return ControllerRegistry.getControllers<PoolSpaController>([ControlType.Pool]);
  }

  protected controlNavigation(index: number, body: PoolSpaBodyController | undefined): void {
    this.navigationIndex = index;
    this.activeBody = body;
    this.updateCircuitControls();
  }
  
  public override processNewController(): void {
    this.activeBody = this.activeController?.bodies.length ?? 0 > 0 ? this.activeController?.bodies[0] : undefined;
    this.updateCircuitControls();
  }
  
  private updateCircuitControls(): void {
    if (this.activeController === undefined) return;
    
    this.activeCircuits = this.activeBody == undefined ? this.activeController.circuits : this.activeBody.circuits;
    
    this.poolPageConfigs = [
      {
        visible: this.activeBody !== undefined,
        component: TempControlsComponent,
        inputs: {
          activeController: this.activeBody
        }
      },
      {
        visible: true,
        component: AuxListComponent,
        inputs: {
          activeCircuits: this.activeCircuits
        }
      }
    ];
  }
  
  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(): void {
    this.destroy();
  }
}
