import {Component, Input} from '@angular/core';
import {MediaSourceController} from "../media.source.controller";

@Component({
  selector: 'app-media-favorites',
  templateUrl: './media-favorites.component.html',
  styleUrls: ['./media-favorites.component.scss']
})
export class MediaFavoritesComponent {
  @Input() activeController: MediaSourceController | undefined;
}
