<section class="main-container" (click)="containerClick($event)">
  <section class="header">
    <section class="header-menu">
      <button class="menu" (click)="menuVisible = !menuVisible">
        <i class="fa-solid fa-bars"></i>
      </button>
    </section>
    <section class="header-time">
      <h2 class="clock-time">{{ clock.time }}</h2>
      <h3 class="clock-date">
        {{ clock.day }} {{ clock.monthShort }} {{ clock.year }}
      </h3>
    </section>
    <section class="header-info">
      <div class="header-info-slide-in"></div>
    </section>
  </section>
  <section class="systems-bar"
           [ngClass]="{ 'slide-in': menuVisible, 'full-width': communicationsService.settings?.authType == 'default'}"
           [ngStyle]="{ '--active-system-id': activeIndex}">
    <ng-container *ngFor="let system of configurationService.systems; let i = index">
      <button (click)="systemClick(system.pageType, i)" *ngIf="!system.hidden">
        <i class="fa-solid fa-circle-exclamation system-status" *ngIf="!system.online"></i>
        <i [ngClass]="system.iconClass"></i>
        <span [ngClass]="{ 'slide-in-up': i == activeIndex, 'slide-out-down': i != activeIndex }">
          {{ system.label }}
        </span>
      </button>
    </ng-container>
  </section>
  <section class="systems-page">
    <app-dashboard *ngIf="pageService.activePages.includes(PageType.Dashboard)"></app-dashboard>
    <app-media *ngIf="pageService.activePages.includes(PageType.Media)"></app-media>
    <app-cameras *ngIf="pageService.activePages.includes(PageType.Cameras)"></app-cameras>
    <app-climate *ngIf="pageService.activePages.includes(PageType.Climate)"></app-climate>
    <app-intercom *ngIf="pageService.activePages.includes(PageType.Intercom)" [displayMode]="'full'"></app-intercom>
    <app-lighting *ngIf="pageService.activePages.includes(PageType.Lighting)"></app-lighting>
    <app-pool-spa *ngIf="pageService.activePages.includes(PageType.PoolSpa)"></app-pool-spa>
    <!--        <app-security *ngIf="pageService.activePages.includes(PageType.Security)"></app-security>-->
    <!--        <app-settings *ngIf="pageService.activePages.includes(PageType.Settings)"></app-settings>-->
    <!--        <app-shades *ngIf="pageService.activePages.includes(PageType.Shades)"></app-shades>-->
    <app-door-locks *ngIf="pageService.activePages.includes(PageType.DoorLocks)"></app-door-locks>
  </section>
  <section class="footer">
    <app-player-view [source]="pageService.getActiveSourceAsMediaCrpcPlayerController()"></app-player-view>
    <app-volume-control *ngIf="pageService.activeSource"></app-volume-control>
    <app-power-control *ngIf="pageService.activeSource"></app-power-control>
  </section>
</section>

<section class="popups">
  <app-intercom *ngIf="pageService.activePopups.includes(PageType.Intercom)" [displayMode]="'popup'"></app-intercom>
</section>


<!--<section class="debug">-->
<!--    <div *ngFor="let message of this.loggingService.logs">{{ message }}<BR /></div>-->
<!--</section>-->
