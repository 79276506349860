<section class="card">
  <h5 class="card-type">MEDIA</h5>

  <section class="source-selection" *ngIf="controller">
    <button class="source" *ngFor="let source of controller.sources; let i = index"
            [ngClass]="{'active': controller!.activeSources.includes(source)}"
            (click)="selectSource(source, i)">
      <i class="icon" [ngClass]="source.icon"></i>
      <span class="label">{{ source.name }}</span>

      <span class="used-by-label" *ngIf="source.inUseBy.length > 0"
            [ngClass]="{'active-label': controller!.activeSources.includes(source)}">IN USE BY:</span>
    </button>
  </section>
</section>
