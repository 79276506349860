import {
  Component,
  ElementRef,
  EventEmitter, Inject,
  Input,
  Output, PLATFORM_ID,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {CommunicationsService} from "../../services/communications/communications.service";
import {PageService} from "../../services/interface/page.service";
import {Controller} from "../../services/configuration/controller";
import {isPlatformBrowser} from "@angular/common";
import {ConfigurationService} from "../../services/configuration/configuration.service";
import {Area} from "../../services/configuration/area";
import {logger} from "../../services/support/logger";

export interface ControllerChangeEventArgs {
  controller: Controller;
  index: number;
}

@Component({
  selector: 'app-area-list',
  templateUrl: './area-list.component.html',
  styleUrls: ['./area-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AreaListComponent {
  constructor(protected communicationsService: CommunicationsService, protected configurationService: ConfigurationService, protected pageService: PageService, @Inject(PLATFORM_ID) private platformId: Object) {
  }

  @Input() header: string = '';

  private _activeController: Controller | undefined;
  @Input()
  set activeController(controller: Controller | undefined) {
    this._activeController = controller;
    if (!this.controllers || !controller) return;
    this.activeIndex = this.controllers.indexOf(controller);

    if (controller && this.controllers) {
      const index = this.controllers.indexOf(controller);
      this.scrollToControllerByIndex(index);
    }
  }

  get activeController() {
    return this._activeController;
  }

  protected groupedControllers: { [key: string]: Controller[] } | undefined;
  protected expandedGroups: { [key: string]: boolean } = {};
  private _controllers: Controller[] = [];
  @Input()
  set controllers(items: Controller[] | undefined) {
    this._controllers = items ?? [];
    this.groupControllersByParent();
  }

  get controllers() {
    return this._controllers;
  }

  @Output() activeControllerChange: EventEmitter<ControllerChangeEventArgs> = new EventEmitter<ControllerChangeEventArgs>();

  @ViewChildren('item') items: QueryList<ElementRef> | undefined;

  protected activeIndex: number = 0;
  protected headerIndex: number = 0;
  protected menuVisibility: boolean = false;
  protected message: string = '';

  protected lockScreen(event: PointerEvent | MouseEvent | TouchEvent): void {
    if (!(event.target instanceof HTMLElement)) {
      this.menuVisibility = false
      return;
    }
    if (event.target.classList.length == 0) {
      this.menuVisibility = false
      return;
    }

    if (!(event.target.classList[0] == "area-list-component" ||
      event.target.classList[0] == "small-circle" ||
      event.target.classList[0] == "overlay" ||
      event.target.classList[0] == "fa-light" ||
      event.target.classList[0] == "controller-label")
    ) {
      this.menuVisibility = false
      return;
    }

    if (this.menuVisibility) {
      this.menuVisibility = false;
      return;
    }

    this.pageService.closeMenus();
    this.menuVisibility = true;
  }

  protected selectGroupedController(controller: Controller, groupName: string): void {
    if (this.controllers === undefined || this.groupedControllers === undefined) return;
    const index: number = this.controllers.indexOf(controller);
    this.headerIndex = Object.keys(this.groupedControllers).indexOf(groupName);
    this.selectController(controller,  index);
  }

  protected selectController(controller: Controller, index: number): void {
    if (index < 0) return;
    this.menuVisibility = false;
    this.activeControllerChange.emit({controller: controller, index: index});
  }

  private scrollToControllerByIndex(index: number): void {
    if (!isPlatformBrowser(this.platformId)) return; // Check if it's browser platform
    
    const elementId: string = `item-${index}`;
    const element: HTMLElement | null = document.getElementById(elementId);
    if (element == null) return;
    element.scrollIntoView({behavior: 'smooth', block: 'nearest'});
  }

  private groupControllersByParent(): void {
    const groupedControllers = this._controllers.reduce((acc, controller) => {
      const area: Area = this.configurationService.areas[controller.name!];
      if (area === undefined) return acc;
      const parentKey = area.parent?.name || 'Ungrouped';
      if (!acc[parentKey]) {
        acc[parentKey] = [];
        this.expandedGroups[parentKey] = true;
      }
      acc[parentKey].push(controller);
      return acc;
    }, {} as { [key: string]: Controller[] });
    if (Object.keys(groupedControllers).length > 0)
      this.groupedControllers = groupedControllers;
  }

  protected toggleGroupVisibility(groupKey: string): void {
    this.expandedGroups[groupKey] = !this.expandedGroups[groupKey];
  }

  protected isGroupVisible(groupKey: string): boolean {
    return this.expandedGroups[groupKey];
  }
  
  protected objectKeys(groupedControllers: { [p: string]: Controller[] }): string[] {
    return Object.keys(groupedControllers);
  }

  protected isActive(controller: Controller): boolean {
    return this.activeController === controller;
  }

  getAreaListItemClasses(controller: Controller): string {
    if (this.controllers === undefined || this.controllers.length == 0) return '';
    let classes: string = this.activeController == controller ? 'active' : '';
    //classes += controller == this.controllers[0] ? ' marker' : '';
    
    return classes;
  }
}