import {Injectable, Type} from '@angular/core';
import {ControlType} from "./control.type";

@Injectable({
  providedIn: 'root'
})
export class CardRegistryService {
  private static registry: Map<ControlType, Type<any>> = new Map<ControlType, Type<any>>();

  public static register(componentType: ControlType, componentClass: Type<any>): void {
    if (this.registry.has(componentType)) {
      console.warn(`Component type ${componentType} is already registered.`);
    } else {
      this.registry.set(componentType, componentClass);
    }
  }

  public static getComponent(componentType: ControlType): Type<any> | undefined {
    return this.registry.get(componentType);
  }
}