<section class="navigation" *ngIf="activeController">
  <section class="page-controls">
    <label *ngIf="activeController.pageActions">PAGE</label>
    <button *ngFor="let pageAction of activeController.pageActions; let i = index"
            (pointerdown)="activeController.pageClick(i, $event)"
            (pointerup)="activeController.pageClick(i, $event)">
      <i [ngClass]="pageAction.iconClass" *ngIf="pageAction.iconClass !== undefined"></i>
      <span
        *ngIf="pageAction.iconClass === undefined">{{ pageAction.shortLabel?.toUpperCase() ?? pageAction.label.toUpperCase() }}</span>
    </button>
  </section>
  <section class="udlr">
    <button *ngFor="let navigationAction of activeController.navigationActions; let i = index"
            (pointerdown)="activeController.navigationClick(i, $event)"
            (pointerup)="activeController.navigationClick(i, $event)"
            [ngStyle]="{'grid-area': navigationAction.label.toLowerCase()}">
      <i [ngClass]="navigationAction.iconClass" *ngIf="navigationAction.iconClass !== undefined"></i>
      <span
        *ngIf="navigationAction.iconClass === undefined">{{ navigationAction.shortLabel?.toUpperCase() ?? navigationAction.label.toUpperCase() }}</span>
    </button>
  </section>
  <section class="channel-controls">
    <label *ngIf="activeController.channelActions">CHANNEL</label>
    <button *ngFor="let channelAction of activeController.channelActions; let i = index"
            (pointerdown)="activeController.channelClick(i, $event)"
            (pointerup)="activeController.channelClick(i, $event)">
      <i [ngClass]="channelAction.iconClass" *ngIf="channelAction.iconClass !== undefined"></i>
      <span
        *ngIf="channelAction.iconClass === undefined">{{ channelAction.shortLabel?.toUpperCase() ?? channelAction.label.toUpperCase() }}</span>
    </button>
  </section>
</section>