<section class="player-controls" *ngIf="source && source.player">
  <img class="now-playing-image" [ngSrc]="source.player.albumArtUrl" *ngIf="source.player.albumArtUrl" width="24" height="24"/>
  <div class="info-stack">
    <div class="now-playing-badge-info">
      <img [ngSrc]="source.player.playerIconURL" *ngIf="source.player.playerIconURL" width="96" height="96"/>
      <app-marquee [text]="source.player.stationName" *ngIf="source.player.stationName"></app-marquee>
    </div>

    <app-marquee [text]="source.player.album" *ngIf="source.player.album"></app-marquee>
    <app-marquee [text]="source.player.artist + ' - ' + source.player.track" *ngIf="source.player.artist && source.player.track"></app-marquee>
    <div class="info-progress" *ngIf="source.player.progressBar">
      <span>{{ toTimeString(source.player.elapsedSec) }}</span>
      <app-progress-bar *ngIf="source.player.progressBar && source.player.elapsedSec && source.player.trackSec" [min]="0" [max]="source.player.trackSec" [progress]="source.player.elapsedSec"></app-progress-bar>
      <span>{{ toTimeString(source.player.trackSec) }}</span>
    </div>
  </div>
  <div class="thumbs-stars" *ngIf="!mini">
    <button class="transports-thumbs-down" *ngIf="source.player.transports.get('ThumbsDown')" (click)="source.player.mediaTransportClick('ThumbsDown')">
      <i class="fas fa-thumbs-down" [ngClass]="{ glow: false }"></i>
    </button>
    <button class="transports-thumbs-up" *ngIf="source.player.transports.get('ThumbsUp')" (click)="source.player.mediaTransportClick('ThumbsUp')">
      <i class="fas fa-thumbs-up" [ngClass]="{ glow: false }"></i>
    </button>
  </div>
  <div class="transports" *ngIf="!mini">
    <button class="transports-shuffle" *ngIf="source.player.transports.get('Shuffle')" [ngClass]="{ glow: source.player.shuffleState > 0 }" (click)="source.player.mediaTransportClick('Shuffle')">
      <i class="fas fa-random"></i>
    </button>
    <button class="transports-skip-prev" *ngIf="source.player.transports.get('PreviousTrack')" (click)="source.player.mediaTransportClick('PreviousTrack')">
      <i class="fas fa-fast-backward"></i>
    </button>
    <button class="transports-play-pause" *ngIf="source.player.transports.get('Play') && (source.player.playerState == 'paused' || source.player.playerState == 'stopped')" (click)="source.player.mediaTransportClick('Play')">
      <i class="far fa-play"></i>
    </button>
    <button class="transports-play-pause" *ngIf="source.player.transports.get('Pause') && source.player.playerState == 'playing'" (click)="source.player.mediaTransportClick('Pause')">
      <i class="far fa-pause"></i>
    </button>

    <button class="transports-skip-next" *ngIf="source.player.transports.get('NextTrack')" (click)="source.player.mediaTransportClick('NextTrack')">
      <i class="fas fa-fast-forward"></i>
    </button>
    <button class="transports-repeat" *ngIf="source.player.transports.get('Repeat')" [ngClass]="{ glow: source.player.repeatState > 0 }" (click)="source.player.mediaTransportClick('Repeat')">
      <i class="fas fa-redo-alt"></i>
    </button>
  </div>
</section>