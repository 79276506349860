import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-slider-mini',
  templateUrl: './slider-mini.component.html',
  styleUrl: './slider-mini.component.scss'
})
export class SliderMiniComponent implements OnInit, AfterViewInit {

  private _level: number = 0;
  @Input()
  set level(value: number) {
    if (this.userInteracting) return;
    this._level = value;
    this.scaleLevel(value);
    this.updateRender();
  }

  get level(): number {
    return this._level;
  }

  @Input() settable: boolean = true;
  @Input() minLevel: number = 0;
  @Input() maxLevel: number = 65535;
  @Output() levelChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('fill') fill: ElementRef | undefined;
  @ViewChild('bg') bg: ElementRef | undefined;
  @ViewChild('bubble') bubble: ElementRef | undefined;
  @ViewChild('label') label: ElementRef | undefined;
  @ViewChild('container') componentContainer: ElementRef | undefined;

  private bubbleSize: number = 26;
  private bubbleBuffer: number = (this.bubbleSize / 2) + 4;
  private userInteracting: boolean = false;

  levelScaled: number = 0;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.scaleLevel(this.level);
    this.updateRender();
  }

  protected updateSlider(event: Event): void {
    const level = parseInt((event.target as HTMLInputElement).value);
    this.scaleLevel(level);
    this.updateRender();
    this.levelChange.emit(level);
  }

  protected sliderPointerEvent(event: PointerEvent): void {
    this.userInteracting = event.buttons > 0;
    if (!this.userInteracting) return;

    //event.stopPropagation();
  }

  protected sliderChangeEvent(_: Event): void {
    this.userInteracting = false;
  }

  private updateRender(): void {
    if (!this.fill || !this.bg || !this.bubble || !this.componentContainer) return;

    this.renderer.setStyle(this.fill.nativeElement, 'width', `calc(${this.levelScaled}% - ${this.bubbleBuffer}px)`);
    this.renderer.setStyle(this.bg.nativeElement, 'left', `calc(${this.levelScaled}% + ${this.bubbleBuffer}px)`);
    this.renderer.setStyle(this.bubble.nativeElement, 'left', `${this.levelScaled}%`);
  }

  private scaleLevel(level: number): void {
    if (this.maxLevel === this.minLevel) {
      this.levelScaled = this.minLevel;  // To avoid division by zero
    } else {
      const scaledValue = ((level - this.minLevel) * 100) / (this.maxLevel - this.minLevel);
      this.levelScaled = Math.round(scaledValue);  // Round to the nearest integer
    }
  }
}
