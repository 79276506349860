import {Component, Input} from '@angular/core';
import {LightingSceneController} from "../lighting.scene.controller";

@Component({
  selector: 'app-scene-list',
  templateUrl: './scene-list.component.html',
  styleUrl: './scene-list.component.scss'
})
export class SceneListComponent {
  @Input() scenes: LightingSceneController[] | undefined;
}
