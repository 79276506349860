import {CommunicationsService} from "../../services/communications/communications.service";
import {ControllerRegistry} from "../../services/registries/controller.registry.service";
import {MediaSourceController, SourceType} from "./media.source.controller";
import {Controller} from "../../services/configuration/controller";
import {AreaController} from "../../services/configuration/area.controller";
import {ControlType} from "../../services/registries/control.type";
import {EventEmitter} from "@angular/core";
import {PageService} from "../../services/interface/page.service";
import {ServiceManager} from "../../services/serviceManager/service-manager.service";
import {PageType} from "../../services/interface/pageType";

export class MediaAreaController extends AreaController {
  constructor(communicationsService: CommunicationsService, controlId: string, controllerIds: string[], controlType: ControlType) {
    super(communicationsService, controlId, controlType);
    this.pageService = ServiceManager.getService("pageService") as PageService;
  }

  public readonly sources: MediaSourceController[] = [];
  private readonly pageService: PageService;

  public activeSources: MediaSourceController[] = [];
  public activeSourcesChangedEvent: EventEmitter<void> = new EventEmitter<void>();

  public volume: { level: number, label: string } = {level: 0, label: "%"};
  public muted: boolean = false;
  public audioSource: MediaSourceController | undefined;
  public videoSource: MediaSourceController | undefined;
  public sleepTimerEnabled: boolean = false;
  public sleepTimerActive: boolean = false;
  public sleepTimerDisplay: string | undefined;
  

  public avSettings: any[] = [];

  public override processControllerIds(): void {
    this.sleepTimerEnabled = this.interfaces.includes('ISleepTimer');
    this.controllerIds.forEach((controllerId: string): void => {
      if (controllerId == this.controlId) return;
      const controller: Controller | undefined = ControllerRegistry.getControllerById(controllerId);
      if (controller instanceof MediaSourceController && !this.sources.includes(controller))
        this.sources.push(controller)
    });
  }

  public setVolume(percent: number): void {
    this.communicationsService.sendControlMessage(this.controlId, "setVolume", {percent: percent});
  }

  public volumeUp(state: boolean): void {
    this.communicationsService.sendControlMessage(this.controlId, "volumeUp", {buttonState: state});
  }

  public volumeDown(state: boolean): void {
    this.communicationsService.sendControlMessage(this.controlId, "volumeDown", {buttonState: state});
  }

  public volumeMuteToggle(): void {
    this.communicationsService.sendControlMessage(this.controlId, "setMuteState", {action: 'toggle'});
  }

  private volumeUpdate(params: any): void {
    this.volume = {level: params.level, label: params.label};
  }

  private muteStateUpdate(params: any): void {
    this.muted = params.state.value == 2;
  }

  private activeSourcesUpdate(params: any): void {
    if (params.audioSourceId !== undefined) {
      const audioController = ControllerRegistry.getControllerById(params.audioSourceId);

      if (audioController != this.audioSource) {
        this.findAndRemoveSource(this.audioSource);
      }

      if (audioController instanceof MediaSourceController) {
        this.audioSource = audioController;
        if (!this.activeSources.includes(audioController)) {
          this.activeSources.push(audioController);
          audioController.pollAsync().then();
        }
      }
    } else {
      this.findAndRemoveSource(this.audioSource);
      this.audioSource = undefined;
    }

    if (params.videoSourceId !== undefined) {
      const videoController = ControllerRegistry.getControllerById(params.videoSourceId);

      if (videoController != this.videoSource) {
        this.findAndRemoveSource(this.videoSource);
      }

      if (videoController instanceof MediaSourceController) {
        this.videoSource = videoController;
        if (!this.activeSources.includes(videoController)) {
          this.activeSources.push(videoController);
          videoController.pollAsync().then();
        }
      }
    } else {
      this.findAndRemoveSource(this.videoSource);
      this.videoSource = undefined;
    }
    if (this.pageService?.activeMediaArea == this && !this.pageService?.activePages.includes(PageType.Media)) {
      this.pageService.activeSource = this.activeSources.length > 0 ? this.activeSources[0] : undefined;
    }

    this.activeSourcesChangedEvent.emit();
  }
  
  private sleepTimerUpdate(params: any): void {
    this.sleepTimerActive = params.isActive;
    this.sleepTimerDisplay = params.timeRemaining;
  }

  private selectSourceContractHandler(contract: any): void {
  }

  private setMuteStateContractHandler(contract: any): void {
  }

  private volumeUpContractHandler(contract: any): void {
  }

  private volumeDownContractHandler(contract: any): void {
  }

  public selectSource(source: MediaSourceController | undefined) {
    let params: {} = {};
    if (source === undefined) {
      this.communicationsService.sendControlMessage(this.controlId, "selectSource", {
        audioSourceId: null,
        videoSourceId: null
      });
      return;
    }

    if (source.sourceType == SourceType.AudioSource)
      params = {audioSourceId: source.controlId, videoSourceId: this.videoSource?.controlId}
    else if (source.sourceType == SourceType.VideoSource)
      params = {audioSourceId: this.audioSource?.controlId, videoSourceId: source.controlId}
    else if (source.sourceType == SourceType.AudioVideoSource)
      params = {audioSourceId: source.controlId, videoSourceId: source.controlId};
    this.communicationsService.sendControlMessage(this.controlId, "selectSource", params);
  }
  
  public setSleepTimer(time: number | string) : void {
    if (typeof time === 'number')
      time = this.convertSecondsToTimeSpan(time);
    
    let params: {} = {time: time};
    this.communicationsService.sendControlMessage(this.controlId, "setSleepTime", params);
  }

  public override unsubscribeFromStatusMessages(): void {
    if (this.pageService.activeMediaArea == this) return;
    super.unsubscribeFromStatusMessages();
    this.sources.forEach(source => source.unsubscribeFromStatusMessages());
  }

  private findAndRemoveSource(source: MediaSourceController | undefined): void {
    if (source === undefined) return;
    const index: number = this.activeSources.indexOf(source);
    if (index > -1) {
      this.activeSources.splice(index, 1);
    }
  }
  
  private convertSecondsToTimeSpan(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num: number): string => num.toString().padStart(2, '0');

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  }
}