import {Component, OnDestroy} from '@angular/core';
import {PageService} from "../../services/interface/page.service";
import {PageSystem} from "../pageSystem";
import {CommunicationsService} from "../../services/communications/communications.service";
import {ConfigurationService} from "../../services/configuration/configuration.service";
import {SystemTypes} from "../../services/support/isystem";
import {PageConfig} from "../../components/pagination/pagination.component";
import {DoorLocksAreaController} from "./door-locks.area.controller";
import {PageType} from "../../services/interface/pageType";
import {DoorLocksController} from "./door-locks.controller";
import {fadeAnimation} from "../../services/support/animations";


@Component({
  selector: 'app-door-locks',
  templateUrl: './door-locks.component.html',
  styleUrls: ['./door-locks.component.scss'],
  animations: [
    fadeAnimation
  ]
})
export class DoorLocksComponent extends PageSystem<DoorLocksAreaController> implements OnDestroy {
  constructor(communicationsService: CommunicationsService, configurationService: ConfigurationService, pageService: PageService) {
    super(communicationsService, configurationService, pageService, SystemTypes.DoorLocks);
  }
  
  protected activeDoorLockController: DoorLocksController | undefined;
  
  ngOnDestroy(): void {
    this.destroy();
    this.pageService.removePopup(PageType.DoorLocksOptions);
  }

  protected readonly PageType = PageType;

  displayLockOptions(load: DoorLocksController): void {
    this.activeDoorLockController = load;
    this.pageService.addPopup(PageType.DoorLocksOptions);
  }
}
