import { Injectable } from '@angular/core';
import {CommunicationsService} from "../communications/communications.service";
import {Controller} from "../configuration/controller";
import {ControlType} from "./control.type";
import {logger} from "../support/logger";
import {keys} from "lodash";

type ControllerConstructor = new (communicationsService: CommunicationsService, controlId: string, apiContracts: object, controlType: ControlType) => Controller;

@Injectable({
  providedIn: 'root'
})
export class ControllerRegistry {

  constructor() {
  }

  public static controllers: Map<ControlType, Controller[]> = new Map<ControlType, Controller[]>();
  private static controllersById: Map<string, Controller> = new Map<string, Controller>();
  private static registry: Map<ControlType, ControllerConstructor> = new Map<ControlType, ControllerConstructor>();

  static getControllerById(controlId: string): Controller | undefined {
    return ControllerRegistry.controllersById.get(controlId);
  }

  static register(controlType: ControlType, constructor: ControllerConstructor): void {
    ControllerRegistry.registry.set(controlType, constructor);
  }

  static clear(): void {
    ControllerRegistry.controllers.clear();
    ControllerRegistry.controllersById.clear();
  }

  static create(controlType: ControlType, communicationsService: CommunicationsService, controlId: string, controlResult: object): Controller | undefined {
    const constructor: ControllerConstructor | undefined = ControllerRegistry.registry.get(controlType);
    if (constructor) {
      // Call the controller type constructor
      const controller: Controller = new constructor(communicationsService, controlId, controlResult, controlType);

      // Get the existing array of controllers for the given ControlType, or initialize it if it doesn't exist
      const controlTypeControllers: Controller[] = ControllerRegistry.controllers.get(controlType) || [];

      // Push the new controller to the array
      controlTypeControllers.push(controller);
      
      // Update the controller id map with the new array of controllers
      ControllerRegistry.controllersById.set(controller.controlId, controller);

      // Update the map with the new array of controllers
      ControllerRegistry.controllers.set(controlType, controlTypeControllers);

      return controller;
    }
    return undefined;
  }

  public static getControllers<TController extends Controller>(controlTypes: ControlType[]): TController[] {
    let allControllers: TController[] = [];

    controlTypes.forEach((controlType: ControlType) => {
      const controllersOfType: TController[] | undefined = ControllerRegistry.controllers.get(controlType) as TController[];
      if (controllersOfType) {
        allControllers = allControllers.concat(controllersOfType);
      }
    });

    return allControllers;
  }
}