import {ControlOption} from "../../services/support/control.option";

export class DoorLocksAction {
  static readonly None: ControlOption = { value: 0, label: "None" };
  static readonly Lock: ControlOption = { value: 1, label: "Lock", iconClass: 'fa-regular fa-lock' };
  static readonly Unlock: ControlOption = { value: 2, label: "Unlock", iconClass: 'fa-regular fa-lock-open' };
  static readonly Latch: ControlOption = { value: 3, label: "Latch", iconClass: 'fa-solid fa-door-open' };
  static readonly Unlatch: ControlOption = { value: 4, label: "Unlatch", iconClass: 'fa-solid fa-door-open' };
  static readonly MomentaryOpen: ControlOption = { value: 5, label: "Momentary Open", iconClass: 'fa-solid fa-door-open' };
  static readonly HoldOpen: ControlOption = { value: 6, label: "Hold Open", iconClass: 'fa-solid fa-door-open' };
  static readonly Close: ControlOption = { value: 7, label: "Close", iconClass: 'fa-solid fa-door-closed' };

  //static readonly Zero: ControlOption = { value: 0, label: "0", iconClass: 'fa-regular fa-0', sortOrder: 10 };
  static toControlOptionArray(): ControlOption[] {
    return Object.values(DoorLocksAction);
  }
}
