import isEqual from 'lodash/isEqual';

export class ControlMessage {
  public messageId: number;
  public controlId: string | undefined;
  public method: string | undefined;
  public params: any;

  constructor(messageId: number, controlId: string | undefined, method: string, params: any) {
    this.messageId = messageId;
    this.controlId = controlId;
    this.method = method;
    this.params = params;
  }

  static parseControlMessage(data: string): ControlMessage | string {
    try {
      const parsed = JSON.parse(data);
      if (typeof parsed !== 'object' || parsed === null) {
        return 'SyntaxError: Parsed data should be an object';
      }
      if (parsed.messageId === undefined || parsed.messageId === null) {
        return 'SyntaxError: Missing id parameter';
      }
      if (typeof parsed.method !== 'string') {
        return 'SyntaxError: Missing or invalid method parameter';
      }
      return new ControlMessage(parsed.messageId, parsed.controlId, parsed.method, parsed.params);
    } catch (err: any) {
      return `Error: ${err.toString()}`;
    }
  }

  public toJSON(): string {
    const obj = {
      messageId: this.messageId,
      controlId: this.controlId,
      method: this.method,
      params: this.params,
    };
    return JSON.stringify(obj);
  }


  private static id: number = 1;

  public static IncrementId(): number {
    this.id++;
    if (this.id >= 65535)
      this.id = 1;

    return this.id;
  }

  public equals(rpcMethod: ControlMessage): boolean {
    return this.method === rpcMethod.method && isEqual(this.params, rpcMethod.params);
  }
}
