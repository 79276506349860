import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {jsonRpcTx} from "./jsonRpcTx";
import {logger} from "../support/logger";
import {DatabaseService} from "../storage/database.service";
import {firstValueFrom, Subject} from "rxjs";
import {jsonRpcRx} from "./jsonRpcRx";
import {crpcObject} from "./crpc.object";
import {crpcPlayerData} from "./crpc.player.data";
import {CrpcBrowserData} from "./crpc.browser.data";
import {MediaCrpcPlayerController} from "../../pages/media/media-player-crpc/media.crpc.player.controller";

@Injectable({
  providedIn: 'root',
})
export class CrpcService implements OnDestroy {
  constructor(private databaseService: DatabaseService) {
    this.setupMediaPlayerAsync().then();
  }

  public activeController: MediaCrpcPlayerController | undefined;

  public output: EventEmitter<string> = new EventEmitter<string>();
  public objectsUpdate: EventEmitter<crpcObject[]> = new EventEmitter<crpcObject[]>();
  public uuid: string | undefined;

  get handle(): string {
    if (this.crpcVersion == "1.0") {
      return `sg:${this.uuid}`;
    }
    return 'sg';
  }

  private crpcVersion: string = "1.0";
  private id: number = 1000;
  private subjects: { [key: number]: Subject<jsonRpcRx> } = {};
  private eventSubjects: { [key: string]: Subject<jsonRpcRx> } = {};

  public playerInstance: crpcPlayerData | undefined;
  public browserInstance: CrpcBrowserData | undefined;

  public crpcMessageHandler(message: string): void {
    const crpcPacket = JSON.parse(message);
    if (crpcPacket.params?._handle && crpcPacket.params._handle !== this.handle)
      return;
    if (!this.subjects[crpcPacket.id] && crpcPacket.method && crpcPacket.method.includes('.Event')) {
      this.eventUpdateHandler(crpcPacket);
      return;
    }

    this.subjects[crpcPacket.id]?.next(crpcPacket);
    if (crpcPacket.id !== 1) this.unsubscribe(crpcPacket.id);
  }

  public async sendAsync(method: string, params: object | undefined, replyHandler: (json: jsonRpcRx) => void): Promise<jsonRpcRx> {
    const jrpc: jsonRpcTx = new jsonRpcTx(this.getNextId(), method, params);
    const data: string = JSON.stringify(jrpc);
    this.output.emit(data);

    return firstValueFrom(this.subscribe(jrpc.id, replyHandler));
  }

  public addEventHandler(eventName: string, eventHandler: (event: jsonRpcRx) => void): void {
    this.removeEventHandler(eventName);
    this.eventSubjects[eventName] = new Subject<jsonRpcRx>();
    this.eventSubjects[eventName].subscribe(eventHandler);
  }

  public removeEventHandler(eventName: string): void {
    if (this.eventSubjects[eventName]) {
      this.eventSubjects[eventName].complete();
      this.eventSubjects[eventName].unsubscribe();
      delete this.eventSubjects[eventName];
    }
  }

  public ngOnDestroy(): void {
    //TODO: add some clean up
  }

  private async setupMediaPlayerAsync(): Promise<void> {
    const result: { [p: string]: any } = await this.databaseService.getByKeysAsync('mediaPlayer', ['uuid']);
    let uuid: string;
    if (result['uuid'] === undefined || result['uuid'] === null) {
      uuid = this.generateUuid();
      await this.databaseService.addAllAsync('mediaPlayer', ['uuid'], [uuid]);
    } else uuid = result['uuid'];
    this.uuid = uuid;
  }

  public async registerAsync(controller: MediaCrpcPlayerController): Promise<void> {
    if (this.activeController == controller && this.playerInstance !== undefined) {
      if (this.browserInstance?.listItems.length == 0 ?? true)
        await this.browserInstance?.initAsync();

      await this.playerInstance.getNowPlayingAsync();
      return;
    }
           
    await this.playerInstance?.destroyAsync();
    await this.browserInstance?.destroyAsync();

    this.playerInstance = undefined;
    this.browserInstance = undefined;

    if (this.activeController !== undefined && this.activeController !== controller) {
      this.activeController.deregister();
    }
    this.activeController = controller;

    await this.sendAsync(
      'Crpc.Register',
      {
        ver: '1.0',
        uuid: this.uuid,
        maxPacketSize: 65535,
        type: 'symbol/json-rpc',
        encoding: 'UTF-8',
        format: 'JSON',
        name: 'Definition MediaPlayer=1.00.00.00_api=1',
      },
      this.registerReplyHandler.bind(this)
    );

    await this.sendAsync(
      'Crpc.GetObjects',
      undefined,
      this.getObjectsReplyHandler.bind(this)
    );

    await this.sendAsync(
      'Crpc.RegisterEvent',
      {
        ev: 'ObjectDirectoryChanged',
        handle: this.handle,
      },
      this.objectDirectoryChangedReplyHandler.bind(this)
    );
  }

  public toCamelCase(str: string = ''): string {
    return str
      .replace(/^\w|\[A-Z]|\b\w/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  }

  private eventUpdateHandler(event: jsonRpcRx): void {
    let eventName: string | undefined = event.params?.ev;
    if ((event.params?.handle != this.handle && !event.method?.includes("MediaPlayer"))|| !eventName || !this.playerInstance || !this.browserInstance)
      return;
    if (event.method == `${this.playerInstance.name}.Event`)
      eventName = `${this.playerInstance.name}.${eventName}`;
    else if (event.method == `${this.browserInstance.name}.Event`)
      eventName = `${this.browserInstance.name}.${eventName}`;
    else return;

    if (!this.eventSubjects[eventName]) return;
    this.eventSubjects[eventName].next(event);
  }

  private registerReplyHandler(reply: jsonRpcRx): void {
    this.crpcVersion = reply.result.ver;
    if (reply.error != null) {
      logger.error("[ CRPC Service ] Register reply error", reply.error);
      return;
    }
  }

  private getObjectsReplyHandler(reply: jsonRpcRx): void {
    if (!reply.result?.objects?.object) return;
    reply.result.objects.object.forEach((object: any) => {
      if (object.instanceName == undefined)
        object.instanceName = object.instancename;
      delete object.instancename;
    });
    this.objectsUpdate.emit(reply.result.objects.object);
  }

  private objectDirectoryChangedReplyHandler(reply: jsonRpcRx): void {
    logger.debug('[ CRPC Service ] Object directory changed event handler reply of:', reply);
  }

  private subscribe(messageId: number, replyHandler: (response: jsonRpcRx) => void): Subject<jsonRpcRx> {
    if (!this.subjects[messageId]) {
      this.subjects[messageId] = new Subject<jsonRpcRx>();
    }
    this.subjects[messageId].subscribe(replyHandler);
    return this.subjects[messageId];
  }

  private unsubscribe(messageId: number): void {
    if (this.subjects[messageId]) {
      this.subjects[messageId].complete();
      this.subjects[messageId].unsubscribe();
      delete this.subjects[messageId];
    }
  }

  private getNextId(): number {
    const nextId = this.id;
    this.id = this.id >= 65535 ? 1000 : this.id + 1;
    return nextId;
  }

  private generateUuid(): string {
    const lut = [];
    for (let i = 0; i < 256; i++) {
      lut[i] = (i < 16 ? '0' : '') + i.toString(16);
    }
    const d0 = (Math.random() * 0xffffffff) | 0;
    const d1 = (Math.random() * 0xffffffff) | 0;
    const d2 = (Math.random() * 0xffffffff) | 0;
    const d3 = (Math.random() * 0xffffffff) | 0;
    return lut[d0 & 0xff] + lut[(d0 >> 8) & 0xff] + lut[(d0 >> 16) & 0xff] + lut[(d0 >> 24) & 0xff] + '-' + lut[d1 & 0xff] + lut[(d1 >> 8) & 0xff] + '-' + lut[((d1 >> 16) & 0x0f) | 0x40] + lut[(d1 >> 24) & 0xff] + '-' + lut[(d2 & 0x3f) | 0x80] + lut[(d2 >> 8) & 0xff] + '-' + lut[(d2 >> 16) & 0xff] + lut[(d2 >> 24) & 0xff] + lut[d3 & 0xff] + lut[(d3 >> 8) & 0xff] + lut[(d3 >> 16) & 0xff] + lut[(d3 >> 24) & 0xff];
  }
}
