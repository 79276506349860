import {Component, OnInit} from '@angular/core';
import {ClockService} from "../../services/time/clock.service";
import {PageService} from "../../services/interface/page.service";
import {MotionSlide} from "../../services/interface/motionSlide";
import {PageType} from 'src/app/services/interface/pageType';
import {ConfigurationService} from "../../services/configuration/configuration.service";
import {CommunicationsService} from "../../services/communications/communications.service";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  constructor(protected clock: ClockService, protected communicationsService: CommunicationsService, protected configurationService: ConfigurationService, protected pageService: PageService) {
    pageService.activePageChangeEvent.subscribe((activeIndex: number): void => {
      this.activeIndex = activeIndex;
    })
  }

  protected readonly CommunicationsService = CommunicationsService;
  protected readonly PageType = PageType;
  protected menuVisible: boolean = false;
  protected volume: number = 0;
  
  public ngOnInit(): void {
    this.activeIndex = this.pageService.getActiveSystemIndex();
    this.pageService.closeMenuEvent.subscribe((): void => {
      this.menuVisible = false;
    });
  }

  protected activeIndex: number = 0;

  protected systemClick(id: number, index: number): void {
    this.menuVisible = false;
    if (index == this.activeIndex) return;
    let motion = MotionSlide.Ignore;
    if (index > this.activeIndex) {
      motion = MotionSlide.SlideUp;
    } else {
      motion = MotionSlide.SlideDown;
    }
    this.activeIndex = index;
    this.pageService.changePage(id, motion);
  }

  protected containerClick(event: PointerEvent | MouseEvent | TouchEvent): void {
    if (!(event.target instanceof HTMLElement)) return;
    if (event.target.className == "fa-solid fa-bars" || event.target.className == "header-menu" || event.target.className == "menu") return;

    this.menuVisible = false;
  }
}
