export class ControlError {
    public messageId: number;
    public error: {
        code: number;
        message: string;
    };

    constructor(messageId: number, code: number, message: string) {
        this.messageId = messageId;
        this.error = {
            code: code,
            message: message,
        };
    }

    static parseControlMessage(data: string): ControlError | string {
        try {
            const parsed = JSON.parse(data);
            if (typeof parsed !== 'object' || parsed === null) {
                return 'SyntaxError: Parsed data should be an object';
            }
            if (parsed.messageId === undefined || parsed.messageId === null || typeof parsed.messageId !== 'number') {
                return 'SyntaxError: Missing or invalid message id parameter';
            }
            if (parsed.error === undefined || parsed.error === null || typeof parsed.error !== 'object') {
                return 'SyntaxError: Missing or invalid error object';
            }
            if (typeof parsed.error.code !== 'number' || typeof parsed.error.message !== 'string') {
                return 'SyntaxError: Missing or invalid fields in error object';
            }
            return new ControlError(parsed.id, parsed.error.code, parsed.error.message);
        } catch (err: unknown) {
            if (err instanceof Error) {
                return `Error: ${err.message}`;
            }
            return 'An unknown error occurred';
        }
    }

    public toJSON(): string {
        const obj = {
            messageId: this.messageId,
            error: this.error,
        };
        return JSON.stringify(obj);
    }
}
