<section class="card weather-card" *ngIf="controller">
  <h5 class="card-type">WEATHER</h5>
  <section class="weather-header">
    <div class="location" *ngIf="controller.weatherData.length > 0 && controller.weatherData[0].location">
      <i class="fa-regular fa-location-dot"></i>
      <span>{{ controller.weatherData[0].location }}</span>
    </div>
    <div class="current-weather" *ngIf="controller.weatherData.length > 0">
      <div class="temp-now">
        <span>Now</span>
        <div class="temperature">
          <span class="temp-value">{{ getAirTemperature(controller.weatherData[0]) }}°</span>
          <img *ngIf="controller.weatherData[0].iconUrl" [ngSrc]="controller.weatherData[0].iconUrl"
               alt="weather icon"
               class="current-weather-icon" width="48" height="48">
        </div>
        <span class="small-label">High: {{ getHighTemperature(controller.weatherData[0]) }}°</span>
        <span class="small-label">Low: {{ getLowTemperature(controller.weatherData[0]) }}°</span>
      </div>

      <div class="weather-desc">
        <span class="status">{{ controller.weatherData[0].skyStatus }}</span>
        <span class="small-label">Feels like {{ getFeelsLikeTemperature(controller.weatherData[0]) }}°</span>
      </div>
    </div>
  </section>

  <section class="hourly-forecast" *ngIf="controller.weatherData[0].hourlyWeather">
    <span class="label">Hourly Forecast</span>
    <cdk-virtual-scroll-viewport itemSize="72" orientation="horizontal" class="forecast-scroll" #forecastScroll>
      <div class="hourly-weather" *cdkVirtualFor="let weatherData of controller.weatherData[0].hourlyWeather">
        <span class="small-label">{{ getAirTemperature(weatherData) }}°</span>
        <img *ngIf="weatherData.iconUrl" [ngSrc]="weatherData.iconUrl"
             alt="weather icon"
             class="current-weather-icon" width="32" height="32">
        <span class="small-label time" *ngIf="weatherData.updatedAt">{{ getTimeLabel(weatherData.updatedAt) }}</span>
      </div>
    </cdk-virtual-scroll-viewport>
  </section>

  <section class="daily-forecast">
    <span class="label">{{ controller.weatherData.length }}-day forecast</span>
    <div class="daily-weather" *ngFor="let weatherData of controller.weatherData">
      <span *ngIf="weatherData.updatedAt">{{ getDayLabel(weatherData.updatedAt) }}</span>
      <img *ngIf="weatherData.iconUrl" [ngSrc]="weatherData.iconUrl"
           alt="weather icon"
           class="current-weather-icon" width="32" height="32">
      <span class="right-align">{{ getHighTemperature(weatherData) }}°/{{ getLowTemperature(weatherData) }}
        °</span>
    </div>
  </section>
</section>
