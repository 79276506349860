<div class="volume-control-container" *ngIf="mediaArea && mediaArea.volume">
  <button [ngClass]="{glow: mediaArea.muted}" (click)="mediaArea.volumeMuteToggle()" *ngIf="mediaArea.interfaces.includes('IMuteControl') && mediaArea.interfaces.includes('IDiscreteVolumeControl')">
    <i class="fa-solid fa-volume-xmark" *ngIf="!mediaArea.muted"></i>
    <i class="fa-solid fa-volume-slash fade-in-out" *ngIf="mediaArea.muted"></i>
  </button>
  <app-slider class="full-size" [level]="mediaArea.volume.level" (levelChange)="mediaArea.setVolume($event)" *ngIf="mediaArea.interfaces.includes('IDiscreteVolumeControl')"></app-slider>
  <app-slider-mini class="mini-size" [level]="mediaArea.volume.level" (levelChange)="mediaArea.setVolume($event)" *ngIf="mediaArea.interfaces.includes('IDiscreteVolumeControl')"></app-slider-mini>
  <div class="volume-buttons" *ngIf="!mediaArea.interfaces.includes('IDiscreteVolumeControl')">
    <button [ngClass]="{glow: mediaArea.muted}" (click)="mediaArea.volumeMuteToggle()" *ngIf="mediaArea.interfaces.includes('IMuteControl')">
      <i class="fa-solid fa-volume-xmark" *ngIf="!mediaArea.muted"></i>
      <i class="fa-solid fa-volume-slash" *ngIf="mediaArea.muted"></i>
    </button>
    
    <button (pointerdown)="mediaArea.volumeDown(true)" (pointerup)="mediaArea.volumeDown(false)" (pointercancel)="mediaArea.volumeDown(false)">
      <i class="fa-solid fa-volume-low"></i>
    </button>

    <button (pointerdown)="mediaArea.volumeUp(true)" (pointerup)="mediaArea.volumeUp(false)" (pointercancel)="mediaArea.volumeUp(false)">
      <i class="fa-solid fa-volume-high"></i>
    </button>
  </div>
</div>
