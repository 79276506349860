import {
  Component,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  AfterViewChecked,
  Renderer2,
  OnDestroy
} from '@angular/core';

@Component({
  selector: 'app-marquee',
  templateUrl: './marquee.component.html',
  styleUrl: './marquee.component.scss'
})
export class MarqueeComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  constructor(private renderer: Renderer2) {
    this.marqueeId = MarqueeComponent.idCounter++;
  }

  @ViewChild('scrollContainer') scrollContainer: ElementRef | undefined;
  @ViewChild('scrollContent') scrollContent: ElementRef | undefined;
    @Input() speed: number = 50; // default speed
  private _text: string = '';
  get text(): string {
    return this._text;
  }

  @Input() set text(value: string) {
    this._text = value;
    if (this.scrollContent == undefined) return;
    const content = this.scrollContent.nativeElement;
    if (content == undefined) return;
    content.style.animation = '';
  }

  private static idCounter: number = 0;
  private readonly marqueeId: number;
  private styleElement: HTMLStyleElement | undefined;
  private isOverflowing: boolean = false;

  ngAfterViewInit(): void {
    this.checkOverflow();
  }

  ngAfterViewChecked(): void {
    this.checkOverflow();
  }

  ngOnDestroy(): void {
    if (this.styleElement) {
      this.renderer.removeChild(document.head, this.styleElement);
    }
  }

  protected checkOverflow(): void {
    if (this.scrollContainer?.nativeElement == undefined || this.scrollContent?.nativeElement == undefined) return;
    const container = this.scrollContainer.nativeElement;
    const content = this.scrollContent.nativeElement;
    this.isOverflowing = content.scrollWidth > container.clientWidth;
    if (this.isOverflowing) {
      this.applyScrolling(container, content);
    } else {
      this.removeScrolling(content);
    }
  }

  private applyScrolling(container: HTMLElement, content: HTMLElement): void {
    if (content.style.animation != '') return;
    const scrollWidth: number = content.scrollWidth - container.clientWidth + 2;
    const animationDuration: number = scrollWidth / this.speed; // Adjust speed as necessary
    const pauseDuration: number = 2; // Pause duration in seconds

    const totalDuration: number = animationDuration + pauseDuration * 2;

    const keyframes: string = `
    @keyframes marquee-${this.marqueeId} {
      0% { transform: translateX(0); }
      20% { transform: translateX(0); }
      50% { transform: translateX(-${scrollWidth}px); }
      80% { transform: translateX(0); }
      100% { transform: translateX(0); }
    }
  `;
    if (this.styleElement) {
      this.renderer.removeChild(document.head, this.styleElement);
    }

    const element = this.renderer.createElement('style');
    element.type = 'text/css';
    element.innerHTML = keyframes;
    this.styleElement = element;

    this.renderer.appendChild(document.head, this.styleElement);

    content.style.animation = `marquee-${this.marqueeId} ${totalDuration}s infinite`;
  }

  private removeScrolling(content: HTMLElement): void {
    content.style.animation = '';
    if (this.styleElement) {
      this.renderer.removeChild(document.head, this.styleElement);
    }
  }
}