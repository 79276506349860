import {CommunicationsService} from "./communications.service";
import {ControlMessage} from "./messaging/controlMessage";
import {ConnectionStatus} from "./connection.status";

export class PressAndReleaseHandler {
  constructor(private communicationsService: CommunicationsService) {
    communicationsService.connectionChanged.subscribe((state: ConnectionStatus): void => {
      if (state == ConnectionStatus.Connected && !this.running)
        this.startInterval();

      if (state == ConnectionStatus.Disconnected && this.running)
        this.stopInterval();
    });
    if (communicationsService.status == ConnectionStatus.Connected)
      this.startInterval();
  }
  private pressMessages: Map<number | string, ControlMessage> = new Map();
  // @ts-ignore
  private repeatInterval?: NodeJS.Timeout;
  private running: boolean = false;

  public addMessage(message: ControlMessage): void {
    this.pressMessages.set(message.messageId, message);
    this.communicationsService.sendMessage(message);
  }

  public removeMessage(messageId : number | string) : void {
    const message = this.pressMessages.get(messageId);
    if (message) this.pressMessages.delete(messageId);
  }

  public clearAll(messageCallback: (message: ControlMessage) => void): void {
    this.pressMessages.forEach((message: ControlMessage) => {
      message.params.buttonState = false;
      messageCallback(message);
    });
    this.pressMessages.clear();
  }

  private startInterval() {
    this.running = true;
    const repeatTime: number = 200;
    this.repeatInterval = setInterval(() => {
      this.pressMessages.forEach((message: ControlMessage) => {
        try {
          this.communicationsService.sendMessage(message);
        } catch (error) {
          console.error("Failed to send message:", error);
        }
      });
    }, repeatTime);
  }

  private stopInterval(): void {
    if (this.repeatInterval) {
      clearInterval(this.repeatInterval);
      this.repeatInterval = undefined;
    }
    this.running = false;
  }
}
