import {PageType} from "../interface/pageType";
import {ControlType} from "../registries/control.type";
import {ISystem, SystemType} from "../support/isystem";
import {Controller} from "./controller";
import {CommunicationsService} from "../communications/communications.service";
import {ControlMessage} from "../communications/messaging/controlMessage";
import {ControlResult} from "../communications/messaging/controlResult";
import {logger} from "../support/logger";
import {ControllerRegistry} from "../registries/controller.registry.service";

export class System implements ISystem {
  constructor(private communicationsService: CommunicationsService, systemSettings: ISystem, controllerIds: string[]) {
    this.iconClass = systemSettings.iconClass;
    this.label = systemSettings.label;
    this.online = systemSettings.online;
    this.pageType = systemSettings.pageType;
    this.systemType = systemSettings.systemType;
    this.childControlTypes = systemSettings.childControlTypes;
    this.controllerIds = controllerIds;
    this.hidden = systemSettings.hidden;
  }

  public readonly iconClass: string;
  public readonly label: string;
  public online: boolean;
  public readonly pageType: PageType;
  public readonly systemType: SystemType;
  public readonly childControlTypes: ControlType[];
  public readonly hidden: boolean;
  public controllers: Controller[] = [];


  private readonly controllerIds: string[];
  public async createControllersAsync(): Promise<void> {
    for (const controlId of this.controllerIds) {
      const controller: Controller | undefined = await System.CreateControllerAsync(this.communicationsService, controlId);
      if (controller === undefined) return;
      this.controllers.push(controller);
    }
  }
  public static async CreateControllerAsync(communicationsService: CommunicationsService, controlId: string): Promise<Controller | undefined> {
    return new Promise((resolve, reject): void => {
      // 1) Get the api contracts for the controller, so we can construct it
      const apiContractsPromise = new Promise<any | undefined>((resolve, reject) => {
        communicationsService.sendMessageAndSubscribe(
          new ControlMessage(ControlMessage.IncrementId(), controlId, "getApiContracts", {}), true,
          (response: ControlResult) => {
            const responseObject = JSON.parse(response.result);
            if (responseObject.controlId != controlId) {
              logger.error("Non-matching control id");
              reject(new Error("Non-matching control id"));
              return;
            }
            resolve(responseObject);
          }
        );
      }).then((apiContractsResult): void => {
        if (apiContractsResult === undefined || apiContractsResult.controlType === undefined) {
          reject(new Error("Return did not contain an api contract"));
          return;
        }
        const controlType: ControlType | undefined = ControlType[apiContractsResult.controlType as keyof typeof ControlType];
        if (controlType === undefined)
        {
          reject(new Error("Was unable to determine control type"));
          return;
        }
        const controller: Controller | undefined = ControllerRegistry.create(controlType, communicationsService, controlId, apiContractsResult);
        if (controller === undefined) {
          reject(new Error(`${ControlType[controlType]} was unable to create controller for ${apiContractsResult.name}@${controlId} due to being unsupported at this time`));
          return;
        }

        resolve(controller);
      }).catch(error => {
        logger.error("Error creating controller: ", error);
        reject(error);
      });
    });
  }

  public dispose(): void {
    this.controllers.forEach((controller: Controller) => {
      controller.dispose();
    });
    this.controllers = [];
  }
}
