import {PageType} from "../interface/pageType";
import {ControlType} from "../registries/control.type";

export enum SystemType
{
  Area,
  Device,
  Custom = 2,
}
export interface ISystem {
    iconClass: string;
    label: string;
    pageType: PageType;
    online: boolean;
    systemType: SystemType;
    childControlTypes: ControlType[];
    hidden: boolean;
}

export class SystemTypes {
  static readonly Dashboard: ISystem = {
    iconClass: 'fa-solid fa-gauge-high',
    label: "Dashboard",
    pageType: PageType.Dashboard,
    online: true,
    systemType: SystemType.Area,
    childControlTypes: [],
    hidden: false
  };

  static readonly Media: ISystem = {
    iconClass: 'fa-solid fa-tv',
    label: 'Media',
    pageType: PageType.Media,
    online: true,
    systemType: SystemType.Area,
    childControlTypes: [
      ControlType.AudioSource,
      ControlType.VideoSource,
      ControlType.AudioVideoSource,
      ControlType.AudioSourceMediaPlayer,
      ControlType.VideoSourceMediaPlayer,
      ControlType.AudioVideoSourceMediaPlayer
    ],
    hidden: false
  };

  static readonly Climate: ISystem = {
    iconClass: 'fa-solid fa-temperature-list',
    label: 'Climate',
    pageType: PageType.Climate,
    online: true,
    systemType: SystemType.Device,
    childControlTypes: [
      ControlType.Climate
    ],
    hidden: false
  };

  static readonly Lighting: ISystem = {
    iconClass: 'fa-solid fa-lightbulb',
    label: 'Lights',
    pageType: PageType.Lighting,
    online: true,
    systemType: SystemType.Area,
    childControlTypes: [
      ControlType.Lighting,
      ControlType.LightingScene
    ],
    hidden: false
  };

  static readonly Shades: ISystem = {
    iconClass: 'fa-solid fa-shutters',
    label: 'Shades',
    pageType: PageType.Shades,
    online: true,
    systemType: SystemType.Area,
    childControlTypes: [
      ControlType.Shade,
      ControlType.ShadeScene
    ],
    hidden: false
  };

  static readonly PoolSpa: ISystem = {
    iconClass: 'fa-solid fa-person-swimming',
    label: 'Pool & Spa',
    pageType: PageType.PoolSpa,
    online: true,
    systemType: SystemType.Custom,
    childControlTypes: [
      ControlType.PoolBody,
      ControlType.PoolCircuit
    ],
    hidden: false
  };

  static readonly Cameras: ISystem = {
    iconClass: 'fa-solid fa-camera-cctv',
    label: 'Cameras',
    pageType: PageType.Cameras,
    online: true,
    systemType: SystemType.Device,
    childControlTypes: [
      ControlType.Camera
    ],
    hidden: false
  };

  static readonly DoorLocks: ISystem = {
    iconClass: 'fa-regular fa-lock',
    label: 'Door Locks',
    pageType: PageType.DoorLocks,
    online: true,
    systemType: SystemType.Area,
    childControlTypes: [
        ControlType.DoorLock
    ],
    hidden: false
  };
  
  static readonly FireFeatures: ISystem = {
    iconClass: 'fa-regular fa-fire',
    label: 'Fire/Heater',
    pageType: PageType.FireFeatures,
    online: true,
    systemType: SystemType.Area,
    childControlTypes: [],
    hidden: false
  };

  static readonly Power: ISystem = {
    iconClass: 'fa-duotone fa-outlet',
    label: 'Power',
    pageType: PageType.Outlets,
    online: true,
    systemType: SystemType.Device,
    childControlTypes: [
      ControlType.Outlet
    ],
    hidden: false
  };

  static readonly Intercom: ISystem = {
    iconClass: 'fa-brands fa-intercom',
    label: 'Intercom',
    pageType: PageType.Intercom,
    online: true,
    systemType: SystemType.Device,
    childControlTypes: [
      ControlType.Intercom
    ],
    hidden: false
  };

  static readonly Weather: ISystem = {
    iconClass: 'fa-regular fa-sun-cloud',
    label: 'Weather',
    pageType: PageType.None,
    online: true,
    systemType: SystemType.Device,
    childControlTypes: [
      ControlType.Weather
    ],
    hidden: true
  };
}
