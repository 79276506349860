import {CommunicationsService} from "../communications/communications.service";
import {ContractHandler} from "./contract.handler";
import {ControlType} from "../registries/control.type";

const iconLookUp: Record<string, string> = {
  'unknown': 'fa-solid fa-square-question',
  'apple': 'fa-brands fa-apple',
  'satellite': 'fa-regular fa-satellite-dish',
  'blu-ray': 'crneo crf-blu-ray',
  'roku': 'crneo crf-roku',
  'airplay': 'fa-regular fa-airplay',
  'game': 'crneo crf-game',
  'xbox': 'fa-brands fa-xbox',
  'cloudMusic': 'fa-regular fa-cloud-music',
}

export abstract class Controller extends ContractHandler { 
  protected constructor(communicationsService: CommunicationsService, controlId: string, apiContracts: any, controlType: ControlType) {
    super(communicationsService, controlId);
    this.controlType = controlType;
    if (apiContracts === undefined) return;
    this.name = apiContracts.name;
    this.description = apiContracts.description;
    const icon: string = apiContracts.icon ?? 'unknown';
    this.icon = iconLookUp[icon.toLowerCase()] || 'fa-solid fa-square-question';
    this.url = apiContracts.url;
    this.interfaces = apiContracts.interfaces;
    
    this.processApiContracts(apiContracts);
  }

  public readonly controlType: ControlType;
  public readonly icon: string | undefined;
  public readonly url: string | undefined;
}
