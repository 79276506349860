<section class="card">
  <h5 class="card-type">LIGHTING</h5>

  <div class="list" *ngIf="controller && controller.scenes.length > 0">
    <button *ngFor="let scene of controller.scenes" (click)="scene.toggle(!scene.powerState)"
            [ngClass]="{'active': scene.powerState}"> {{ scene.name }}
    </button>
  </div>

  <div class="list" *ngIf="controller && controller.scenes.length == 0">
    <button (click)="scene(3)" [ngClass]="{'active': activeScene == 3}">High</button>
    <button (click)="scene(2)" [ngClass]="{'active': activeScene == 2}">Medium</button>
    <button (click)="scene(1)" [ngClass]="{'active': activeScene == 1}">Low</button>
    <button (click)="scene(0)" [ngClass]="{'active': activeScene == 0}">Off</button>
  </div>
</section>
