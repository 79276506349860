import {CrpcService} from "./crpc.service";
import {jsonRpcRx} from "./jsonRpcRx";
import {Rating} from "../../pages/media/media-player-crpc/player-view/rating";
import {BusyState} from "./busy.state";
import {logger} from "../support/logger";

export class crpcPlayerData {
  constructor(private crpcService: CrpcService, public name: string) {
    this.crpcService.playerInstance = this;
    this.crpcService.addEventHandler(`${name}.BusyChanged`, this.busyStateEventHandler.bind(this));
    this.crpcService.addEventHandler(`${name}.StateChanged`, this.stateChangedEventHandler.bind(this));
    this.crpcService.addEventHandler(`${name}.StateChangedByBrowseContext`, this.stateChangedByBrowseContextEventHandler.bind(this));
    this.crpcService.addEventHandler(`${name}.StatusMsgChanged`, this.statusMsgChangedEventHandler.bind(this));
  }

  private seekTimeout: any;
  private properties: string[] = [];
  private initialEvents: string[] = ['BusyChanged', 'StateChanged', 'StateChangedByBrowseContext', 'StatusMsgChanged'];

  private iconLookup: Record<string, string> = {
    'ThumbsUp': 'fas fa-thumbs-up',
    'ThumbsDown': 'fas fa-thumbs-down',
    'Shuffle': 'fas fa-random',
    'Repeat': 'fas fa-redo-alt',
    'Play': 'far fa-circle fa-play-circle',
    'Pause': 'far fa-circle fa-pause-circle',
    'PreviousTrack': 'fas fa-fast-backward',
    'NextTrack': 'fas fa-fast-forward',
  };

  //TODO: write in processor fetching for artwork for remote connections
  public albumArtUrl: string = '';
  public playerIconURL: string = '';
  public station: string = '';
  public stationName: string = '';
  public composer: string = '';
  public album: string = '';
  public artist: string = '';
  public track: string = '';
  public progressBar: boolean = false;
  public elapsedSec: number | undefined;
  public trackSec: number | undefined;
  public transports: Map<string, { icon: string; action: string; }> = new Map<string, {
    icon: string;
    action: string;
  }>();
  public shuffleState: number = 0;
  public repeatState: number = 0;
  public playerState: string = '';
  public rewindSpeed: number = 0;
  public ffwdSpeed: number = 0;
  public instance: number = 0;
  public language: string = '';
  public mediaReady: boolean = false;
  public mediaType: any;
  public playerName: string = '';
  public providerName: string = '';
  public rating: Rating | undefined;
  public trackCnt: number = 0;
  public trackNum: number = 0;
  public version: number = 0;
  public busy: BusyState = {on: false, timeoutSec: 0};

  public async initAsync(): Promise<void> {
    await this.getNowPlayingAsync();

    await this.crpcService.sendAsync(
      `${this.name}.GetProperty`,
      {
        propName: 'PropertiesSupported',
        handle: this.crpcService.handle,
      },
      this.getPropertiesSupportedHandler.bind(this)
    );

    for (const eventName of this.initialEvents) {
      await this.crpcService.sendAsync(
        `${this.name}.RegisterEvent`,
        {
          ev: eventName,
          handle: this.crpcService.handle,
        },
        this.registerEventHandler.bind(this)
      );
    }
  }
  
  public async destroyAsync() : Promise<void> {
    this.crpcService.playerInstance = undefined;
    // for (const eventName of this.initialEvents) {
    //   await this.crpcService.sendAsync(
    //     `${this.name}.DeregisterEvent`,
    //     {
    //       ev: eventName,
    //       handle: this.crpcService.handle,
    //     },
    //     this.registerEventHandler.bind(this)
    //   );
    // }
    this.crpcService.removeEventHandler(`${this.name}.BusyChanged`);
    this.crpcService.removeEventHandler(`${this.name}.StateChanged`);
    this.crpcService.removeEventHandler(`${this.name}.StateChangedByBrowseContext`);
    this.crpcService.removeEventHandler(`${this.name}.StatusMsgChanged`);
  }
  
  public async getNowPlayingAsync(): Promise<void> {
    await this.crpcService.sendAsync(
      `${this.name}.GetProperty`,
      {
        propName: 'TextLines',
      },
      this.getTextLinesHandler.bind(this)
    );
  }

  private set actionsAvailable(value: string[]) {
    this.transports.clear();
    value.forEach((action: string): void => {
      if (this.iconLookup[action] == undefined) return;
      this.transports.set(action, {
        icon: this.iconLookup[action],
        action: action,
      });
    });
  }

  private set actionsSupported(value: string[]) {
    
  }

  public seek(event: Event) {
    if (this.seekTimeout != undefined) clearTimeout(this.seekTimeout);
    this.seekTimeout = setTimeout(() => {
      const val: number = Number((<HTMLInputElement>event.target).value);
      this.crpcService.sendAsync(
        `${this.name}.Seek`,
        {
          time: val,
        },
        this.registerEventHandler.bind(this)
      ).then();
      this.seekTimeout = undefined;
    }, 100);
  }

  public async mediaTransportClick(transportName: string): Promise<void> {
    await this.crpcService.sendAsync(`${this.name}.${transportName}`, undefined, this.registerEventHandler.bind(this));
  }

  private busyStateEventHandler(event: jsonRpcRx): void {
    if (event.params === undefined) return;
    this.busy = event.params.parameters;
  }

  private clearChangedEventHandler(event: jsonRpcRx): void {
    this.actionsAvailable = [];
    this.albumArtUrl = '';
    this.elapsedSec = undefined;
    this.trackSec = undefined;
    this.rewindSpeed = 0;
    this.ffwdSpeed = 0;
    this.mediaReady = false;
    this.mediaType = undefined;
    this.playerIconURL = '';
    this.playerName = '';
    this.playerState = '';
    this.progressBar = false;
    this.providerName = '';
    this.rating = undefined;
    this.repeatState = 0;
    this.shuffleState = 0;
    this.trackCnt = 0;
    this.trackNum = 0;
    this.version = 0;
    this.track = '';
    this.artist = '';
    this.album = '';
    this.stationName = '';
    this.composer = '';
    this.instance = 0;
    this.language = '';
    //this.title = '';
    this.stationName = '';
    this.albumArtUrl = '';
    this.busy = {on: false, timeoutSec: 0};
  }

  private stateChangedByBrowseContextEventHandler(event: jsonRpcRx): void {
    logger.debug('stateChangedByBrowseContextEventHandler: ', event);
  }

  private statusMsgChangedEventHandler(event: jsonRpcRx): void {
    logger.debug('statusMsgChangedEventHandler: ', event);
  }

  private registerEventHandler(reply: jsonRpcRx): void {
    this.properties.forEach((propName) => {
      this.crpcService.sendAsync(
        `${this.name}.GetProperty`,
        {
          propName: propName,
        },
        this.getPropertiesHandler.bind(this)
      );
    });
  }

  private stateChangedEventHandler(event: jsonRpcRx): void {
    if (event.error != undefined || event.params === undefined) return;
    const propName: string = Object.keys(event.params.parameters)[0];
    const camelPropName: string = this.crpcService.toCamelCase(propName);

    // @ts-ignore
    this[camelPropName] = event.params.parameters[propName];
  }

  private getPropertiesHandler(reply: jsonRpcRx): void {
    try {
      const propName: string = Object.keys(reply.result)[0];
      const camelPropName: string = this.crpcService.toCamelCase(propName);
      // @ts-ignore
      this[camelPropName] = reply.result[propName]; 
    }
    catch (error)
    {
      logger.error(error)
    }
  }

  private getPropertiesSupportedHandler(reply: jsonRpcRx): void {
    this.properties = reply.result.PropertiesSupported;
  }

  private getTextLinesHandler(reply: jsonRpcRx): void {
    if (reply.result?.TextLines === undefined) return;
    const textLines = reply.result.TextLines;
    this.station = textLines[0];
    this.track = textLines[1];
    this.artist = textLines[2];
    this.album = textLines[3];
  }
}