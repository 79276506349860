<section class="browser" *ngIf="source && source.browser">
  <div class="browser-header">
    <div class="browser-header-container">
      <button class="browser-back" (click)="source.browser.back()" *ngIf="source.browser!.level > 1">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
    </div>
    <span class="browser-header-title">{{ source.browser!.title }}</span>
    <div class="browser-header-container">
      <app-spinner *ngIf="source.browser!.busy?.on"></app-spinner>
    </div>
  </div>
  <div class="browser-list">
    <button class="browser-list-item" *ngFor="let item of source.browser!.listItems; let i = index"
            (click)="source.browser!.selectListItem(i)">
      <ng-container *ngIf="!imageLoaded && item.url !== ''">
        <ng-container *ngTemplateOutlet="fallbackSVG"></ng-container>
      </ng-container>
      <img [src]="item.url" *ngIf="item.url !== ''" (load)="logLoad($event)" (error)="logError($event)"
           [ngStyle]="{opacity: imageLoaded ? 1 : 0}"/>
      <!--      <img-->
      <!--        *ngIf="imageLoaded; else fallbackSVG"-->
      <!--        ngSrc="item.url"-->
      <!--        (load)="onImageLoad()"-->
      <!--        fill/>-->
      <ng-template #fallbackSVG>
        <svg width="64" height="64" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <circle cx="256" cy="256" r="248" stroke="var(--accent)" stroke-width="16" fill="none"/>
          <path
            d="M499.1 6.3c8.1 6 12.9 15.6 12.9 25.7v72V368c0 44.2-43 80-96 80s-96-35.8-96-80 43-80 96-80c11.2 0 22 1.6 32 4.6V147L192 223.8V432c0 44.2-43 80-96 80s-96-35.8-96-80 43-80 96-80c11.2 0 22 1.6 32 4.6V200 128c0-14.1 9.3-26.6 22.8-30.7l320-96c9.7-2.9 20.2-1.1 28.3 5z"
            fill="var(--accent)" transform="translate(84, 96) scale(0.6)" opacity="0.3"/>
        </svg>
      </ng-template>
      <span class="browser-list-item-title">{{ item.title }}</span>
      <span class="browser-list-item-subtitle">{{ item.subtitle }}</span>
      <!--      <button class="browser-list-item-action" (click)="source.browser!.selectItemAction($event, item)">-->
      <!--        <i [ngClass]="item.icon"></i>-->
      <!--      </button>-->
      <hr/>
    </button>
  </div>
  <div class="browser-footer">
    <button class="browser-action" *ngFor="let action of source.browser!.listActions; let i = index"
            (click)="source.browser!.selectListAction(i)">
      <i [ngClass]="action.icon"></i>
      <!-- <label class="browser-action-label">{{ action.label }}</label> -->
    </button>
  </div>
</section>