import {CommunicationsService} from "../services/communications/communications.service";
import {ISystem, SystemType} from "../services/support/isystem";
import {ControllerRegistry} from "../services/registries/controller.registry.service";
import {Controller} from "../services/configuration/controller";
import {ConfigurationService} from "../services/configuration/configuration.service";
import {PageService} from "../services/interface/page.service";
import {ControllerChangeEventArgs} from "../components/area-list/area-list.component";
import {MediaAreaController} from "./media/media.area.controller";
import {helpers} from "../services/support/helpers";

export abstract class PageSystem<TController extends Controller> {
  protected constructor(
    protected communicationsService: CommunicationsService,
    protected configurationService: ConfigurationService,
    protected pageService: PageService,
    protected system: ISystem) {

    const savedControllerId = pageService.getControllerForPageType(system.pageType);
    this.controllers = this.getControllers(system) ?? [];
    if (this.controllers.length === 0) return;

    const savedController: TController | undefined = this.controllers.find(controller => controller.controlId === savedControllerId);
    this.selectController({
      controller: savedController ?? this.controllers[0],
      index: savedController ? this.controllers.indexOf(savedController) : 0
    });
  }

  public id: string | undefined;
  protected controllers: TController[] = [];
  protected activeController: TController | undefined;
  protected activeControllerIndex: number = -1;

  protected navigationIndex: number = 0;

  protected getControllers(system: ISystem): TController[] {
    let controllers: TController[];

    switch (system.systemType) {
      case SystemType.Area:
        controllers = this.configurationService.getAreaControllers<TController>(system);
        break;
      case SystemType.Device:
        controllers = ControllerRegistry.getControllers<TController>(system.childControlTypes);
        break;
      case SystemType.Custom:
        controllers = this.populateControllers();
    }

    controllers.sort((a: TController, b: TController) => helpers.sort(a, b, 'name'));

    return controllers;
  }

  protected selectController(e: ControllerChangeEventArgs): void {
    if (this.activeControllerIndex == e.index) return;
    this.activeControllerIndex = e.index;
    if (this.activeController !== undefined) {
      this.removeOldController();
      this.activeController.unsubscribeFromStatusMessages();
    }


    this.controllers[this.activeControllerIndex].pollAsync().then(async () => {
      this.activeController = this.controllers[this.activeControllerIndex];

      if (this.activeController instanceof MediaAreaController)
        this.pageService.activeMediaArea = this.activeController;

      this.navigationIndex = 0;
      this.pageService.assignControllerForPageTypeAsync(this.system.pageType, this.activeController.controlId).then();
      if (this.system.systemType == SystemType.Area && this.activeController.name) {
        const area = this.configurationService.areas[this.activeController.name];
        if (area !== undefined) this.pageService.activeArea = area;
      }


      this.processNewController();
    });
  }

  protected populateControllers(): TController[] {
    return [];
  }

  protected removeOldController(): void {
  }

  protected processNewController(): void {
  }

  protected destroy(): void {
    if (this.activeController !== undefined)
      this.activeController.unsubscribeFromStatusMessages();
  }
}
