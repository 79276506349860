import {Controller} from "./controller";
import {CommunicationsService} from "../communications/communications.service";
import {ControlType} from "../registries/control.type";

export class AreaController extends Controller {

    protected constructor(communicationsService: CommunicationsService, controlId: string, controlType: ControlType) {
        super(communicationsService, controlId, undefined, controlType)
    }
    protected controllerIds: string[] = [];
    public addControlIds(controllerIds: string[]): void
    {
        this.controllerIds.push(...controllerIds);
    }
    
    public processControllerIds(): void {}
}