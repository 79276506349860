import {Component, Input} from '@angular/core';
import {PoolSpaBodyController} from "../pool-spa.body.controller";

@Component({
  selector: 'app-temp-controls',
  templateUrl: './temp-controls.component.html',
  styleUrls: ['./temp-controls.component.scss']
})
export class TempControlsComponent {
  @Input() activeController: PoolSpaBodyController | undefined;
}
