import {Component} from '@angular/core';
import {CardBase} from "../card.base";
import {LightingAreaController} from "../../../pages/lighting/lighting.area.controller";
import {logger} from "../../../services/support/logger";

@Component({
  selector: 'app-lighting-card',
  templateUrl: './lighting-card.component.html',
  styleUrl: './lighting-card.component.scss'
})
export class LightingCardComponent extends CardBase<LightingAreaController> {
  
  protected override controllerReady() {
    if (this.controller === undefined) return;
    if (this.controller.scenes.length > 0) return;
    
    
  }

  protected activeScene = -1;
  protected scene(id: number): void {
    if (this.controller === undefined) return;
    let level: number | undefined;
    switch (id)
    {
      case 0:
        level = 0;
        break;
      case 1:
        level = 65535 * .33;
        break;
      case 2:
        level = 65535 * .66;
        break;
      case 3:
        level = 65535;
        break;
    }
    if (level === undefined) return;
    
    for(let i= 0; i < this.controller.loads.length; i++)
    {
      const load = this.controller.loads[i];
      logger.debug(`setting load ${load.name} to ${level}`);
      load.setLevel(level);
    }
  }
}
