import {CommunicationsService} from "../communications/communications.service";
import {ControlResult} from "../communications/messaging/controlResult";
import {ControlMessage} from "../communications/messaging/controlMessage";
import {logger} from "../support/logger";


export abstract class ContractHandler {
    protected constructor(protected communicationsService: CommunicationsService, controlId: string) {
        this.controlId = controlId;
    }
    public readonly controlId: string;
    public name: string | undefined;
    public description: string | undefined;
    public interfaces: string[] = [];

    private readonly _statusMethods: string[] = [];
    public processApiContracts(response: any): void {
        //logger.debug(`[ ${this.constructor.name} ] Processing API Contracts`, apiContracts.apiContracts)
        if (response?.apiContracts?.controlMessages !== undefined) {
            response.apiContracts.controlMessages.forEach((controlContract: ControlResult) => {
                this.processControlMessageContract(controlContract);
            });
        }
        if (response?.apiContracts?.statusMessages !== undefined) {
            response.apiContracts.statusMessages.forEach((statusContract: ControlResult) => {
                this._statusMethods.push(statusContract.method);
                // this.processStatusMessageContract(statusContract);
            });
        }
    }

    // protected processStatusMessageContract(contract: ControlResult): void {
    // }

    protected processControlMessageContract(contract: ControlResult): void {
        const methodName: string = `${contract.method}ContractHandler`;
        if (typeof (this as any)[methodName] !== "function") {
            //logger.error(`[ ${this.constructor.name} ] Currently does not support the contract for ${contract.method}`)
            return;
        }
        (this as any)[methodName].apply(this, [contract]);
    }

    public processStatusEvent(message: any): void {
        if (message instanceof ControlMessage && message.method !== undefined) {
            if (!this._statusMethods.includes(message.method)) return;
            if (message instanceof ControlMessage) {
                const methodName: string = `${message.method}Update`;
                this.checkForMethod(methodName, message.params);
            }
            return;
        }
        const params = JSON.parse(message.result);
        const methodKey: string = Object.keys(params)[0];
        const methodName: string = `${methodKey}Update`;
        this.checkForMethod(methodName, params[methodKey]);
    }

    private checkForMethod(methodName: string, message: any): void {
        if (typeof (this as any)[methodName] !== "function") {
            //logger.error(`[ ${this.name} : ${this.constructor.name} ] Currently does not support the contract for ${methodName}`)
            return;
        }
        //logger.debug(`[ ${this.name} : ${this.constructor.name} ] calling update for method ${methodName}`);
        (this as any)[methodName].apply(this, [message]);
    }

    protected subscribeToStatusMessages(): void {
        if (this._statusMethods.length == 0) return;
        this.communicationsService.sendMessage(new ControlMessage(ControlMessage.IncrementId(), undefined, "subscribe", {
            controlId: this.controlId,
            methods: this._statusMethods
        }));
    }

    public unsubscribeFromStatusMessages(): void {
        this.communicationsService.sendMessage(new ControlMessage(ControlMessage.IncrementId(), undefined, "unsubscribe", {
            controlId: this.controlId,
            methods: this._statusMethods
        }));
    }

    public async pollAsync(): Promise<void> {
        const pollMessageId: number = ControlMessage.IncrementId();
        await new Promise((resolve, reject) => {
            this.communicationsService.sendMessageAndSubscribe(
                new ControlMessage(pollMessageId, this.controlId, "poll", {}), false, (response: ControlResult) => {
                    if (response.result === "done") {
                        this.communicationsService.removeSubscription(pollMessageId);
                        this.subscribeToStatusMessages()
                        resolve(undefined);
                        return;
                    }
                    this.processStatusEvent(response);
                }
            );
        });
    }

    public dispose(): void {
        this.unsubscribeFromStatusMessages();
    }
}