import {AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent implements OnInit, AfterViewInit {
  constructor(private renderer: Renderer2) { }
  @Input() min: number = 0;
  @Input() max: number = 100;

  private _progress: number = 0;
  get progress(): number {
    return this._progress;
  }

  @Input()
  set progress(value: number) {
    this._progress = value;
    this.scaleLevel(this.progress);
    this.updateRender();
  }

  @ViewChild('fill') fill: ElementRef | undefined;

  private progressScaled: number = 0;
  
  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.scaleLevel(this.progress);
    this.updateRender();
  }

  private updateRender(): void {
    if (!this.fill) return;

    this.renderer.setStyle(this.fill.nativeElement, 'width', `${this.progressScaled}%`);
  }
  
  private scaleLevel(level: number): void {
    if (this.max === this.min) {
      this.progressScaled = this.min;  // To avoid division by zero
    } else {
      const scaledValue = ((level - this.min) * 100) / (this.max - this.min);
      this.progressScaled = Math.round(scaledValue);  // Round to the nearest integer
    }
  }
}
