import {Component, Input} from '@angular/core';
import {PageService} from "../../services/interface/page.service";
import {MediaAreaController} from "../../pages/media/media.area.controller";

@Component({
  selector: 'app-volume-control',
  templateUrl: './volume-control.component.html',
  styleUrl: './volume-control.component.scss'
})
export class VolumeControlComponent {
  
  constructor(protected pageService: PageService) { }
  
  protected get mediaArea(): MediaAreaController | undefined {
    return this.pageService.activeMediaArea;
  }
}
