import {Controller} from "../../services/configuration/controller";
import {CommunicationsService} from "../../services/communications/communications.service";
import {logger} from "../../services/support/logger";
import {ControlOption} from "../../services/support/control.option";
import {ClimateSetPoint} from "./climate.setpoint";
import {ClimateModes} from "./climate.modes";
import {ControlType} from "../../services/registries/control.type";

export class ClimateController extends Controller {
  constructor(communicationsService: CommunicationsService, controlId: string, apiContracts: any, controlType: ControlType) {
    super(communicationsService, controlId, apiContracts, controlType);
    //logger.debug(`[ ${this.constructor.name} ] - [ ${apiContracts.name} ] has contracts of`, apiContracts);
  }

  public temperature: { label: string, value: number } | undefined;
  public mode: ControlOption | undefined;
  public fanMode: ControlOption | undefined;
  public floorMode: ControlOption | undefined;
  public humidityMode: ControlOption | undefined;

  public setPoints: ClimateSetPoint[] = [];

  public availableModes: ControlOption[] | undefined;
  public availableFanModes: ControlOption[] | undefined;
  public availableFloorModes: ControlOption[] | undefined;
  public availableHumidityModes: ControlOption[] | undefined;

  public availableSetPoints: Map<string, ClimateSetPoint> | undefined;

  public setMode(mode: ControlOption): void {
    this.communicationsService.sendControlMessage(this.controlId, "setMode", {mode: mode.value});
  }
  public setFloorMode(mode: ControlOption): void {
    this.communicationsService.sendControlMessage(this.controlId, "setFloorMode", {mode: mode.value});
  }
  public setHumidityMode(mode: ControlOption): void {
    this.communicationsService.sendControlMessage(this.controlId, "setHumidityMode", {mode: mode.value});
  }
  public setFanMode(mode: ControlOption): void {
    this.communicationsService.sendControlMessage(this.controlId, "setFanMode", {mode: mode.value});
  }

  /*#region "Contract Handlers"*/
  private setPointIncreaseContractHandler(contract: any): void {
    if (this.availableSetPoints == undefined)
      this.availableSetPoints = new Map<string, ClimateSetPoint>()
    for (const key in contract.availableOptions) {
      if (contract.availableOptions.hasOwnProperty(key)) {
        const setPointData = contract.availableOptions[key];
        const setPoint = new ClimateSetPoint(this.communicationsService, this.controlId, setPointData.mode, setPointData.value, setPointData.minimum, setPointData.maximum);
        this.availableSetPoints.set(JSON.stringify(setPoint.mode), setPoint);
      }
    }
  }

  private setPointDecreaseContractHandler(contract: any): void {

  }

  private setSetPointContractHandler(contract: any): void {

  }

  private setModeContractHandler(contract: any): void {
    this.availableModes = contract.availableOptions.map((option: ControlOption, index: number) => {
      // Check and modify the label if it's "AutoSingle" or "AutoDual"
      let modifiedLabel = option.label;
      if (option.label === "AutoSingle" || option.label === "AutoDual") {
        modifiedLabel = "Auto";
      }

      return {
        ...option,
        label: modifiedLabel, // Use the modified label
        index                 // Add the index property
      };
    });
  }

  private setHumidityModeContractHandler(contract: any): void {
    this.availableHumidityModes = contract.availableOptions.map((option: ControlOption, index: number) => {
      return {
        ...option,
        index                 // Add the index property
      };
    });
  }

  private setFanModeContractHandler(contract: any): void {
    this.availableFanModes = contract.availableOptions.map((option: ControlOption, index: number) => {
      return {
        ...option,
        index                 // Add the index property
      };
    });
  }

  private setFloorModeContractHandler(contract: any): void {
    this.availableFloorModes = contract.availableOptions.map((option: ControlOption, index: number) => {
      // Check and modify the label if it's "AutoSingle" or "AutoDual"
      let modifiedLabel = option.label;
      if (option.label === "AutoSingle" || option.label === "AutoDual") {
        modifiedLabel = "Auto";
      }

      return {
        ...option,
        label: modifiedLabel, // Use the modified label
        index                 // Add the index property
      };
    });
  }

  /*#endregion*/

  /*#region "Status Update Handlers"*/
  private temperatureUpdate(params: any): void {
    if (params.type.value !== 1) return;

    this.temperature = params.temperature;
  }

  private modeUpdate(params: any): void {
    if (this.availableModes === undefined) return;
    const index = this.availableModes.findIndex(mode => mode.value === params.mode.value);
    if (index === -1) {
      logger.error(`[ ${this.constructor.name} ] We received a mode update with a value not in the available options`);
      return;
    }
    this.mode = this.availableModes[index];

    this.setPoints = [];
    if (this.mode.value === ClimateModes.AutoDual.value)
    {
      this.availableSetPoints?.forEach((climateSetPoint: ClimateSetPoint,  key: string) => {
        if (climateSetPoint.mode.value == ClimateModes.Cool.value || climateSetPoint.mode.value == ClimateModes.Heat.value)
          this.setPoints.push(climateSetPoint);
      });
    } else {
      const setPointKey : string = JSON.stringify({ value: this.mode.value, label: this.mode.label });
      const setPoint: ClimateSetPoint | undefined = this.availableSetPoints?.get(setPointKey);
      if (setPoint !== undefined) this.setPoints.push(setPoint);
    }
  }


  private modeStatusUpdate(params: any): void {
    logger.debug(`[ ${this.constructor.name} ] Mode status is`, params.modeStatus.label);
  }

  private onlineStatusUpdate(params: any): void {
    logger.debug(`[ ${this.constructor.name} ] Online status is`, params.status.label);
  }

  private setPointUpdate(params: any): void {
    const setPoint = this.availableSetPoints?.get(JSON.stringify(params.setPoint.mode));
    if (setPoint === undefined) {
      logger.error(`[ ${this.constructor.name} ] We received a set point update with a value not in the available options`);
      return;
    }
    setPoint.update(params.setPoint);
  }

  private fanModeUpdate(params: any): void {
    if (this.availableFanModes === undefined) return;
    const index = this.availableFanModes.findIndex(mode => mode.value === params.fanMode.value);
    if (index === -1) {
      logger.error(`[ ${this.constructor.name} ] We received a fan mode update with a value not in the available options`);
      return;
    }
    this.fanMode = this.availableFanModes[index];
  }

  private fanStatusUpdate(params: any): void {
    logger.debug(`[ ${this.constructor.name} ] Fan status is`, params.fanStatus.label);
  }

  private floorModeUpdate(params: any): void {
    if (this.availableFloorModes === undefined) return;
    const index = this.availableFloorModes.findIndex(mode => mode.value === params.floorMode.value);
    if (index === -1) {
      logger.error(`[ ${this.constructor.name} ] We received a floor mode update with a value not in the available options`);
      return;
    }
    this.floorMode = this.availableFloorModes[index];

    this.setPoints = [];
    if (this.floorMode.value === ClimateModes.AutoDual.value)
    {
      this.availableSetPoints?.forEach((climateSetPoint: ClimateSetPoint,  key: string) => {
        if (climateSetPoint.mode.value == ClimateModes.Cool.value || climateSetPoint.mode.value == ClimateModes.Heat.value)
          this.setPoints.push(climateSetPoint);
      });
    } else {
      const setPointKey : string = JSON.stringify({ value: this.floorMode.value, label: this.floorMode.label });
      const setPoint: ClimateSetPoint | undefined = this.availableSetPoints?.get(setPointKey);
      if (setPoint !== undefined) this.setPoints.push(setPoint);
    }
  }
  /*#endregion*/
}
