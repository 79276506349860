import {ControlOption} from "../../services/support/control.option";

export type StandardActionsType = {
    [key: string]: ControlOption;
};

export class StandardActions {
    static readonly Zero: ControlOption = { value: 0, label: "0", iconClass: 'fa-regular fa-0', sortOrder: 10 };
    static readonly One: ControlOption = { value: 1, label: "1", iconClass: 'fa-regular fa-1', sortOrder: 0 };
    static readonly Two: ControlOption = { value: 2, label: "2", iconClass: 'fa-regular fa-2', sortOrder: 1 };
    static readonly Three: ControlOption = { value: 3, label: "3", iconClass: 'fa-regular fa-3', sortOrder: 2 };
    static readonly Four: ControlOption = { value: 4, label: "4", iconClass: 'fa-regular fa-4', sortOrder: 3 };
    static readonly Five: ControlOption = { value: 5, label: "5", iconClass: 'fa-regular fa-5', sortOrder: 4 };
    static readonly Six: ControlOption = { value: 6, label: "6", iconClass: 'fa-regular fa-6', sortOrder: 5 };
    static readonly Seven: ControlOption = { value: 7, label: "7", iconClass: 'fa-regular fa-7', sortOrder: 6 };
    static readonly Eight: ControlOption = { value: 8, label: "8", iconClass: 'fa-regular fa-8', sortOrder: 7 };
    static readonly Nine: ControlOption = { value: 9, label: "9", iconClass: 'fa-regular fa-9', sortOrder: 8 };
    static readonly Clear: ControlOption = { value: 10, label: "Clear", sortOrder: 9 };
    static readonly Enter: ControlOption = { value: 11, label: "Enter", sortOrder: 11 };
    static readonly Play: ControlOption = { value: 12, label: "Play", iconClass: 'fa-regular fa-play', sortOrder: 3 };
    static readonly Stop: ControlOption = { value: 13, label: "Stop", iconClass: 'fa-regular fa-stop', sortOrder: 4 };
    static readonly Pause: ControlOption = { value: 14, label: "Pause", iconClass: 'fa-regular fa-pause', sortOrder: 5 };
    static readonly Rewind: ControlOption = { value: 15, label: "Rewind", shortLabel: "Rew", iconClass: 'fa-regular fa-backward', sortOrder: 2 };
    static readonly FastForward: ControlOption = { value: 16, label: "Fast Forward", shortLabel: 'Ffwd', iconClass: 'fa-regular fa-forward', sortOrder: 8 };
    static readonly Previous: ControlOption = { value: 17, label: "Previous", shortLabel: 'Prev', iconClass: 'fa-regular fa-backward-fast' };
    static readonly Next: ControlOption = { value: 18, label: "Next", iconClass: 'fa-regular fa-forward-fast', sortOrder: 9 };
    static readonly ChannelPrevious: ControlOption = { value: 19, label: "Ch-", iconClass: 'fa-regular fa-chevron-down' };
    static readonly ChannelNext: ControlOption = { value: 20, label: "Ch+", iconClass: 'fa-regular fa-chevron-up' };
    static readonly Last: ControlOption = { value: 21, label: "Last" };
    static readonly Info: ControlOption = { value: 22, label: "Info" };
    static readonly Guide: ControlOption = { value: 23, label: "Guide" };
    static readonly Menu: ControlOption = { value: 24, label: "Menu" };
    static readonly TopMenu: ControlOption = { value: 25, label: "Top Menu" };
    static readonly PopupMenu: ControlOption = { value: 26, label: "Popup Menu" };
    static readonly Exit: ControlOption = { value: 27, label: "Exit", sortOrder: 9 };
    static readonly Dvr: ControlOption = { value: 28, label: "DVR" };
    static readonly Record: ControlOption = { value: 29, label: "Record", iconClass: 'fa-regular fa-circle', sortOrder: 7 };
    static readonly Up: ControlOption = { value: 30, label: "Up", iconClass: 'fa-regular fa-chevron-up', sortOrder: 0 };
    static readonly Down: ControlOption = { value: 31, label: "Down", iconClass: 'fa-regular fa-chevron-down', sortOrder: 13 };
    static readonly Left: ControlOption = { value: 32, label: "Left", iconClass: 'fa-regular fa-chevron-left', sortOrder: 1 };
    static readonly Right: ControlOption = { value: 33, label: "Right", iconClass: 'fa-regular fa-chevron-right', sortOrder: 12 };
    static readonly Select: ControlOption = { value: 34, label: "Select", sortOrder: 11 };
    static readonly PlayPause: ControlOption = { value: 35, label: "Play/Pause", iconClass: 'fa-regular fa-play-pause', sortOrder: 4 };
    static readonly Plus: ControlOption = { value: 36, label: "+" };
    static readonly VolumeUp: ControlOption = { value: 37, label: "Vol+", iconClass: 'fa-regular fa-volume-high' };
    static readonly VolumeDown: ControlOption = { value: 38, label: "Vol-", iconClass: 'fa-regular fa-volume-low' };
    static readonly VolumeMute: ControlOption = { value: 39, label: "Mute", iconClass: 'fa-regular fa-volume-slash' };
    static readonly VolumeMuteOn: ControlOption = { value: 40, label: "Mute On", iconClass: 'fa-regular fa-volume-slash' };
    static readonly VolumeMuteOff: ControlOption = { value: 41, label: "Mute Off", iconClass: 'fa-regular fa-volume' };
    static readonly Power: ControlOption = { value: 42, label: "Power", iconClass: 'fa-regular fa-power-off' };
    static readonly PowerOn: ControlOption = { value: 43, label: "Power On", iconClass: 'fa-regular fa-power-off' };
    static readonly PowerOff: ControlOption = { value: 44, label: "Power Off", iconClass: 'fa-duotone fa-power-off' };
    static readonly PageUp: ControlOption = { value: 45, label: "Page Up", iconClass: 'fa-regular fa-chevrons-up' };
    static readonly PageDown: ControlOption = { value: 46, label: "Page Down", iconClass: 'fa-regular fa-chevrons-down' };
    static readonly Back: ControlOption = { value: 47, label: "Back", iconClass: 'fa-regular fa-rotate-left' };
    static readonly Replay: ControlOption = { value: 48, label: "Replay", iconClass: 'fa-regular fa-rotate-left' };
    static readonly Shuffle: ControlOption = { value: 49, label: "Shuffle", iconClass: 'fa-regular fa-shuffle' };
    static readonly ShuffleOn: ControlOption = { value: 50, label: "Shuffle On", iconClass: 'fa-regular fa-shuffle' };
    static readonly ShuffleOff: ControlOption = { value: 51, label: "Shuffle Off", iconClass: 'fa-regular fa-shuffle' };
    static readonly Repeat: ControlOption = { value: 52, label: "Repeat", iconClass: 'fa-regular fa-repeat' };
    static readonly RepeatOne: ControlOption = { value: 53, label: "Repeat One", iconClass: 'fa-regular fa-repeat-1' };
    static readonly RepeatAll: ControlOption = { value: 54, label: "Repeat All", iconClass: 'fa-regular fa-repeat' };
    static readonly RepeatOff: ControlOption = { value: 55, label: "Repeat Off", iconClass: 'fa-regular fa-repeat' };
    static readonly List: ControlOption = { value: 56, label: "List", iconClass: 'fa-regular fa-list' };
    static readonly Return: ControlOption = { value: 57, label: "Return", iconClass: 'fa-regular fa-rotate-left' };
    static readonly Eject: ControlOption = { value: 57, label: "Eject", iconClass: 'fa-regular fa-eject' };
    static readonly Intermission: ControlOption = { value: 58, label: "Intermission" };
    static readonly Dash: ControlOption = { value: 59, label: "Dash", iconClass: 'fa-regular fa-dash', sortOrder: 9 };
    static readonly Red: ControlOption = { value: 59, label: "Red", iconClass: 'fa-solid fa-circle' };
    static readonly Green: ControlOption = { value: 59, label: "Green", iconClass: 'fa-solid fa-circle' };
    static readonly Yellow: ControlOption = { value: 59, label: "Yellow", iconClass: 'fa-solid fa-circle' };
    static readonly Blue: ControlOption = { value: 60, label: "Blue", iconClass: 'fa-solid fa-circle' };
    static readonly Advance: ControlOption = { value: 61, label: "Advance", iconClass: 'fa-regular fa-arrow-right-long-to-line', sortOrder:  10 };
    static readonly SkipBack: ControlOption = { value: 62, label: "Skip Back", iconClass: 'fa-regular fa-backward-fast', sortOrder: 1 };
    static readonly Active: ControlOption = { value: 63, label: "Active", iconClass: 'fa-brands fa-creative-commons-sampling' };
    static readonly Home: ControlOption = { value: 64, label: "Home", iconClass: 'fa-regular fa-house' };
    static readonly Search: ControlOption = { value: 65, label: "Search", iconClass: 'fa-regular fa-magnifying-glass' };
    static readonly Backspace: ControlOption = { value: 65, label: "Backspace", iconClass: 'fa-regular fa-delete-left' };
    static readonly Asterisk: ControlOption = { value: 66, label: "*" };
    static readonly Setup: ControlOption = { value: 67, label: "Setup Menu", iconClass: 'fa-regular fa-gear' };
    static readonly Audio: ControlOption = { value: 68, label: "Audio" };
    static readonly Subtitle: ControlOption = { value: 69, label: "Subtitle" };
    static readonly Angle: ControlOption = { value: 70, label: "Angle" };
    static readonly Zoom: ControlOption = { value: 71, label: "Zoom" };
    static readonly PiP: ControlOption = { value: 72, label: "Picture-In-Picture", shortLabel: 'PiP', iconClass: 'fa-regular fa-images' };
    static readonly Live: ControlOption = { value: 73, label: "Live", iconClass: 'fa-solid fa-forward-step', sortOrder: 11 };

    static toControlOptionArray(): ControlOption[] {
        return Object.values(StandardActions);
    }
}
