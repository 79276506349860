import {Component, Input} from '@angular/core';
import {CrpcPopupInfo} from "../../../../services/media/crpc.popup-info";
import {CrpcService} from "../../../../services/media/crpc.service";
import {jsonRpcRx} from "../../../../services/media/jsonRpcRx";
import {logger} from "../../../../services/support/logger";

@Component({
  selector: 'app-media-player-crpc-popup',
  templateUrl: './media-player-crpc-popup.component.html',
  styleUrl: './media-player-crpc-popup.component.scss'
})
export class MediaPlayerCrpcPopupComponent {

  constructor(protected crpcService: CrpcService) {
  }

  @Input() popupInfo: CrpcPopupInfo | undefined;

  protected async selectItemAsync(index: number): Promise<void> {
    if (this.popupInfo === undefined) return;
    await this.crpcService.sendAsync(
      `${this.popupInfo.browserInstanceName}.StatusMsgResponseMenu`,
      {
        localExit: this.popupInfo.localExit,
        state: 1,
        id: index + 1,
        userInput: this.popupInfo.initialUserInput,
      },
      this.popupButtonCallback.bind(this)
    );
  }

  protected inputChange(event: KeyboardEvent): void {
    if (this.popupInfo === undefined) return;
    if (event.key == 'Enter') {
      this.selectItemAsync(0).then();
    } else this.popupInfo.initialUserInput = (<HTMLInputElement>event.target).value;
  }

  private popupButtonCallback(reply: jsonRpcRx): void {
    if (reply.error != null) {
      logger.error('Popup Button Click ERROR!', reply.error);
    }
  }
}
