<section class="slider-component" #container>
  <div class="slider-container">
    <div class="slider-fill-left" #fill></div>
    <div class="slider-fill-right" #bg></div>
    <div class="slider-bubble" #bubble>
      <span class="slider-label" #label>{{ levelScaled }}
        <span class="percent">%</span>
      </span>
    </div>
    <div class="gradient-line" #angledLine></div>
    <div class="flat-line" #flatLine></div>
    <input type="range" class="slider" #slider [value]="level" [min]="minLevel" [max]="maxLevel"
           (input)="updateSlider($event)"
           (pointerdown)="sliderPointerEvent($event)"
           (pointerup)="sliderPointerEvent($event)"
           (change)="sliderChangeEvent($event)">
  </div>
</section>
