<section class="doorLocks-popup">
    <div class="overlay"></div>
    <section class="doorLocks-options">
        <section class="topBar">
            <h3>{{ lock.name }} Options</h3>
            <button (click)="close()">
                <i class="fas fa-times-circle"></i>
            </button>
        </section>
        <section class="content">
            <div class="option-item" *ngIf="lock.privacyState">
                <div class="load-title">
                    <h3>PRIVACY</h3>
                </div>
                <app-toggle [state]="lock.privacyState" (stateChange)="lock.setOnOffState('setPrivacyState', $event)"></app-toggle>
            </div>
            <div class="option-item" *ngIf="lock.ledState">
                <div class="load-title">
                    <h3>LED</h3>
                </div>
                <app-toggle [state]="lock.ledState" (stateChange)="lock.setOnOffState('setLedState', $event)"></app-toggle>
            </div>
            <div class="option-item" *ngIf="lock.oneTouchState">
                <div class="load-title">
                    <h3>ONE TOUCH</h3>
                </div>
                <app-toggle [state]="lock.oneTouchState" (stateChange)="lock.setOnOffState('setOneTouchState', $event)"></app-toggle>
            </div>
            <div class="option-item" *ngIf="lock.beepState">
                <div class="load-title">
                    <h3>BUTTON BEEP</h3>
                </div>
                <app-toggle [state]="lock.beepState" (stateChange)="lock.setOnOffState('setBeepState', $event)"></app-toggle>
            </div>
            <div class="option-item" *ngIf="lock.autoReLockState">
                <div class="load-title">
                    <h3>AUTO RE-LOCK</h3>
                </div>
<!--                <app-toggle [state]="lock.autoReLockState" (stateChange)="lock.setOnOffState('setPrivacyState', $event)"></app-toggle>-->
            </div>
        </section>
    </section>
</section>