<section class="load-list-container">
  <div class="load-list-item" *ngFor="let load of loads; let i = index">
    <div class="load-title">
      <h3>{{ load.name | uppercase }}</h3>
      <span class="load-description">{{ load.description }}</span>
    </div>
    <div class="load-control" *ngIf="load.interfaces.includes('IPowerControl')">
      <app-toggle [id]="i" [state]="load.powerState" (stateChange)="load.toggle($event)"></app-toggle>
    </div>
    <app-slider [level]="load.level" (levelChange)="load.setLevel($event)" [minLevel]="0" [maxLevel]="65535"
                *ngIf="load.interfaces.includes('ILevelSetControl')"></app-slider>
    <!--        <app-rgb *ngIf="load.interfaces.includes('IRgbControl')" [load]="load"></app-rgb>-->

    <section class="app-variable" *ngIf="load.interfaces.includes('IFanSpeedControl')">
      <div class="horizontal-tab-bar" [ngStyle]="{ '--active-tab-index': load.speed?.index}">
        <button [ngClass]="{'active': i === load.speed!.index}"
                *ngFor="let speed of load.availableSpeeds; let i = index"
                (click)="load.setSpeed(speed)">{{ speed.label }}
        </button>
      </div>
    </section>

    <section class="app-rgb"
             *ngIf="load.interfaces.includes('IRgbControl') || load.interfaces.includes('IRgbwControl')">
      <input class="my-color-picker"
             [colorPicker]="load.color"
             (colorPickerChange)="load.setRgbColor($event)"
             [style.background]="load.color"
             [cpAlphaChannel]="'disabled'"
             [cpOutputFormat]="'rgba'"
             [cpDisableInput]="true"
      />
    </section>
  </div>
</section>