<section #bg class="background" id="background" [ngClass]="{ display: show }">
  <div class="black"></div>

  <!--**********************
	  **** Time & Date *****
	  ********************** -->
  <div #time id="timeDateDiv" *ngIf="!preview" [ngStyle]="{ transform: translateTime }">
    <div class="date">
      <span class="month">{{ clock.monthLong }}, </span>
      <span class="day">{{ clock.day }}</span>
    </div>
    <span class="time">{{ clock.time }}</span>
    <span class="weekday">{{ clock.weekDay | uppercase }}</span>
    <app-player-view [source]="pageService.getActiveSourceAsMediaCrpcPlayerController()" [mini]="true"></app-player-view>
  </div>
  <button class="hidden" [ngClass]="{ dark: crestronService.lightSensorValue <= 10, light: crestronService.lightSensorValue > 10 }" (click)="closeSS($event)" *ngIf="!preview"></button>
</section>
