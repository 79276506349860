<section class="page area-list-page doorLocks" [ngClass]="pageService.animationClass">
    <app-area-list [controllers]="controllers" [header]="'DOOR LOCK AREAS'" [activeController]="activeController" (activeControllerChange)="selectController($event)"></app-area-list>
    <section class="area-list-page-controls doorLocks-main">
        <div class="load-list-container" *ngIf="activeController !== undefined && activeController!.loads.length > 0">
            <div class="load-list-item" *ngFor="let load of activeController!.loads; let i = index">
                <div class="load-title">
                    <h3>{{ load.name | uppercase }}</h3>
                    <span class="load-description">{{ load.description }}</span>
                </div>
                <div class="battery-info" *ngIf="load.batteryLevel">
                    <i [ngClass]="load.batteryLevelIcon"></i>
                    <span class="load-description">{{ load.batteryLevel }}%</span>
                </div>
                <div class="load-control" *ngIf="load.availableLockActions?.length ?? 0 > 0">
                    <div class="horizontal-tab-bar" [ngStyle]="{ '--active-tab-index': load.state.index}">
                        <button [ngClass]="{'active': i === load.state!.index}" *ngFor="let action of load.availableLockActions; let i = index" (click)="load.setLockState(action)"><i [ngClass]="action.iconClass"></i>{{ action.label }}</button>
                        <button *ngIf="load.autoReLockState || load.autoReLockTime || load.beepState || load.ledState || load.oneTouchState || load.privacyState" (click)="displayLockOptions(load)"><i class="fa-regular fa-gears"></i>OPTIONS</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <app-lock-options-popup *ngIf="pageService.activePopups.includes(PageType.DoorLocksOptions)" [lock]="activeDoorLockController!" @fadeAnimation></app-lock-options-popup>
</section>
