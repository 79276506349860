import {MediaSourceController} from "../media.source.controller";
import {CommunicationsService} from "../../../services/communications/communications.service";
import {Subscription} from "rxjs";
import {CrpcService} from "../../../services/media/crpc.service";
import {ServiceManager} from "../../../services/serviceManager/service-manager.service";
import {crpcObject} from "../../../services/media/crpc.object";
import {jsonRpcRx} from "../../../services/media/jsonRpcRx";
import {crpcPlayerData} from "../../../services/media/crpc.player.data";
import {CrpcBrowserData} from "../../../services/media/crpc.browser.data";
import {ControlType} from "../../../services/registries/control.type";

export class MediaCrpcPlayerController extends MediaSourceController {
  constructor(communicationsService: CommunicationsService, controlId: string, apiContracts: any, controlType: ControlType) {
    super(communicationsService, controlId, apiContracts, controlType);
    //placeholder in case we need to do anything here
    this.crpcService = ServiceManager.getService('crpcService') as CrpcService;
  }

  private readonly crpcService: CrpcService;
  private outputSubscription: Subscription | undefined;
  private objectsUpdateSubscription: Subscription | undefined;

  public player: crpcPlayerData | undefined;
  public browser: CrpcBrowserData | undefined;
  
  public override unsubscribeFromStatusMessages(): void {
    if (this.crpcService.activeController === this) return;
    super.unsubscribeFromStatusMessages();
  }

  /*#region "Update Handlers"*/
  private crpcFeedbackUpdate(params: any): void {
    //logger.debug(`[ ${this.constructor.name} - ${this.name} ] Crpc Message:`, params.CrpcMessage);
    this.crpcService.crpcMessageHandler(params.CrpcMessage);
  }

  /*#endregion*/


  private async crpcObjectsUpdateHandler(objects: crpcObject[]): Promise<void> {
    for (const obj of objects) {
      if (obj.interfaces.includes('IMediaPlayer')) {
        await this.crpcService.sendAsync(
          `${obj.instanceName}.GetMenu`,
          {
            uuid: this.crpcService.uuid,
          },
          this.getMenuReplyHandlerAsync.bind(this)
        );
        this.player = new crpcPlayerData(this.crpcService, obj.instanceName);
        await this.player.initAsync();
        break;
      }
    }
  }

  private relayMessageHandler(crpcPacket: string): void {
    //logger.error(`[ ${this.constructor.name} - ${this.name} ] Relaying with CRPC packet`, crpcPacket);
    this.communicationsService.sendControlMessage(this.controlId, "crpcControl", {crpcMessage: crpcPacket});
  }

  private async getMenuReplyHandlerAsync(reply: jsonRpcRx): Promise<void> {
    //logger.debug('get menu reply handler:', reply);
    if (reply.result?.instanceName === undefined) return;
    this.browser = new CrpcBrowserData(this.crpcService, reply.result.instanceName);
    await this.browser.initAsync();
  }

  /*#endregion*/
  public async registerAsync(): Promise<void> {
    if (this.outputSubscription === undefined) {
      this.outputSubscription = this.crpcService.output.subscribe(this.relayMessageHandler.bind(this));
      this.objectsUpdateSubscription = this.crpcService.objectsUpdate.subscribe(this.crpcObjectsUpdateHandler.bind(this));
    }

    //logger.error(`[ ${this.constructor.name} - ${this.name} ] Registering with CRPC service`);

    await this.crpcService.registerAsync(this);
  }

  public deregister(): void {
    //await this.crpcService.deregisterAsync();

    this.objectsUpdateSubscription?.unsubscribe();
    this.objectsUpdateSubscription = undefined;

    this.outputSubscription?.unsubscribe();
    this.outputSubscription = undefined;
  }
}