<section class="login-component" (click)="lockScreen($event)" [ngClass]="{'expanded': lockScreenVisibility, 'hidden': !lockScreenVisibility && communicationsService.settings?.authType == 'default'}">
  <img alt="background image couldn't be loaded" class="background-image">
  <div class="overlay"></div>
  <button class="small-circle" *ngIf="!lockScreenVisibility">
    <i class="fa-solid fa-lock-keyhole"></i>
  </button>

  <div class="locked-content" *ngIf="lockScreenVisibility">
    <button class="button-lock" (click)="lock()" *ngIf="lockAllow">LOCK</button>
    <div class="login-form" [@slideUpDown]="lockedLoginForm ? 'up' : 'down'"
         *ngIf="communicationsService.settings?.authType == 'default'">
        <div class="user-icon" *ngIf="communicationsService.settings?.url == undefined">
            <i class="fa-thin fa-user"></i>
        </div>

        <div class="inputbox" *ngIf="communicationsService.settings?.url == undefined">
            <!--                    <input #hostname type="text" required spellcheck="false" (keydown)="keydown($event)" value="192.168.68.201" />-->
            <input #hostname type="text" required spellcheck="false" (keydown)="keydown($event)"/>
            <i class="fa-solid fa-location-dot"></i>
            <label>Host Name / IP</label>
            <span class="underline"></span>
        </div>
        
      <button class="button-login" (click)="login()">LOGIN</button>
    </div>
    <div class="login-form" [@slideUpDown]="lockedLoginForm ? 'up' : 'down'"
         *ngIf="communicationsService.settings?.authType != 'default'">
      <div>
        <div class="user-icon">
          <i class="fa-thin fa-user"></i>
        </div>

        <div class="inputbox" *ngIf="communicationsService.settings?.url == undefined">
          <!--                    <input #hostname type="text" required spellcheck="false" (keydown)="keydown($event)" value="192.168.68.201" />-->
          <input #hostname type="text" required spellcheck="false" (keydown)="keydown($event)"/>
          <i class="fa-solid fa-location-dot"></i>
          <label>Host Name / IP</label>
          <span class="underline"></span>
        </div>
        <div class="inputbox">
          <!--                    <input #username type="text" required spellcheck="false" (keydown)="keydown($event)" value="Nathan" />-->
          <input #username type="text" required spellcheck="false" (keydown)="keydown($event)"
                 [value]="communicationsService.settings?.username ?? ''"/>
          <i class="fa-solid fa-user"></i>
          <label>Username</label>
          <span class="underline"></span>
        </div>
        <div class="inputbox">
          <!--                    <input #password type="password" required spellcheck="false" (keydown)="keydown($event)" value="test" />-->
          <input #password type="password" required spellcheck="false" (keydown)="keydown($event)"
                 [value]="communicationsService.settings?.password ?? ''"/>
          <i class="fa-solid fa-key"></i>
          <label>Password</label>
          <span class="underline"></span>
        </div>
        <button class="button-login" (click)="submit()">LOGIN</button>
      </div>
    </div>

    <div class="connecting" [ngClass]="{ expand: communicationsService.status != ConnectionStatus.Disconnected }">
      <!--                <app-spinner *ngIf="communicationsService.status == ConnectionStatus.Connecting"></app-spinner>-->
      <!--                <i class="fa-solid fa-square-exclamation" *ngIf="communicationsService.status == ConnectionStatus.Failure"></i>-->
      <span>{{ communicationsService.statusMessage }}</span>
    </div>
  </div>

  <section class="footer" (click)="communicationsService.resetConnection()" *ngIf="lockScreenVisibility">
        <span>
          Definition AV Technologies & Construction
          <i class="fa-solid fa-copyright"></i>
          2024
        </span>
  </section>
</section>
