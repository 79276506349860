import {animate, AnimationTriggerMetadata, state, style, transition, trigger} from "@angular/animations";

export const fadeAnimation: AnimationTriggerMetadata =
  trigger('fadeAnimation', [
    state('void', style({opacity: 0})),
    transition(':enter', [
      style({opacity: 0}),
      animate(250, style({opacity: 1}))
    ]),
    transition(':leave', [
      style({opacity: 1}),
      animate(250, style({opacity: 0}))
    ])
  ]);

export const slideInOutAnimation: AnimationTriggerMetadata =
  trigger('slideInOutAnimation', [
    state('in', style({transform: 'translateY(0%)'})),
    transition(':enter', [
      style({transform: 'translateY(100%)'}),
      animate('250ms ease-in')
    ]),
    transition(':leave', [
      style({transform: 'translateY(100%)'}),
      animate('250ms ease-out')
    ])
  ]);