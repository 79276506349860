import { Injectable, OnDestroy } from '@angular/core';
import {timing} from "../time/sleep";
import {Subscription} from "rxjs";
import {IService} from "../serviceManager/IService";
import {logger} from "../support/logger";


declare var CrComLib: any;

@Injectable({
  providedIn: 'root',
})
export class CrestronService implements OnDestroy, IService {
  constructor() {
    this.valid = navigator.userAgent.toLowerCase().search('crestron') != -1;
    if (!this.valid) return;
    this._subscriptions.push(
        CrComLib.subscribeState('n', 'Csig.Light_Sensor_Value_fb', (value: number) => {
          this.lightSensorValue = value;
        })
    );
    
    logger.registerCrestronLogger();
  }
  public valid: boolean = false;
  public lightSensorValue: number = 0;
  private _subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }


  public async lowerBrightness() {
    if (!this.valid) return;

    CrComLib.publishEvent('b', 'Csig.Hard_Button_Brt_Auto_Off', true);
    CrComLib.publishEvent('b', 'Csig.Key_Backlight_Off', true);
    await new timing().sleep(200);
    CrComLib.publishEvent('b', 'Csig.Hard_Button_Brt_Auto_Off', false);
    CrComLib.publishEvent('b', 'Csig.Key_Backlight_Off', false);
    CrComLib.publishEvent('n', 'Csig.Lcd_Brt', 0);
  }

  public async resumeBrightness() {
    if (!this.valid) return;

    CrComLib.publishEvent('b', 'Csig.Key_Backlight_On', true);
    await new timing().sleep(200);
    CrComLib.publishEvent('b', 'Csig.Key_Backlight_On', false);
    CrComLib.publishEvent('n', 'Csig.Lcd_Brt', 65535);
  }
}
