import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {PageService} from "../../services/interface/page.service";
import {ClockService} from "../../services/time/clock.service";
import {CrestronService} from "../../services/communications/crestron.service";
import {SettingsService} from "../../services/storage/settings.service";
import {ConfigurationService} from "../../services/configuration/configuration.service";
import {ScreenSaverType} from "./screen-saver.type";
import {PageType} from "../../services/interface/pageType";

@Component({
  selector: 'app-screen-saver',
  templateUrl: './screen-saver.component.html',
  styleUrls: ['./screen-saver.component.scss'],
})
export class ScreenSaverComponent implements OnInit, OnDestroy, AfterViewInit {
  ScreenSavers = ScreenSaverType;
  show = false;

  constructor(public pageService: PageService, public clock: ClockService, public crestronService: CrestronService, public settings: SettingsService, public config: ConfigurationService) {}

  @Input() ssType: number = ScreenSaverType.Blank;
  @Input() preview: boolean = false;
  private timerID: any;

  private bg?: HTMLElement;
  @ViewChild('bg')
  set _bg(element: ElementRef) {
    if (!element) return;
    this.bg = element.nativeElement;
  }

  private time?: HTMLElement;
  @ViewChild('time')
  set _time(element: ElementRef) {
    if (!element) return;
    this.time = element.nativeElement;
  }
  counter(seed: number) {
    return new Array(seed);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (!this.preview) {
      clearInterval(this.timerID);
    }
  }

  ngAfterViewInit(): void {
    if (!this.bg) return;
    if (!this.preview) {
      setTimeout(() => {
        this.setPosition();
      }, 20);
      this.timerID = setInterval(() => {
        this.setPosition();
      }, 30 * 1000);
    }
    this.bg.style.zIndex = this.preview ? 'inherit' : '10001';
    setTimeout(() => (this.show = true), 20);
  }

  translateTime: string = 'translate(0px, 0px)';

  setPosition(): void {
    if (!this.time) return;
    const timeTop = Math.floor(Math.random() * (window.innerHeight - this.time.clientHeight) + 1);
    const timeLeft = Math.floor(Math.random() * (window.innerWidth - this.time.clientWidth) + 1);
    this.translateTime = `translate(${timeLeft}px, ${timeTop}px)`;
  }

  closeSS(event: Event) {
    event.stopPropagation();
    this.settings.sleepCount = 0;
    this.show = false;
    setTimeout(() => this.pageService.removePage(PageType.ScreenSaver), 250);
    event.preventDefault();
  }
}
