<section class="page area-list-page poolSpa" [ngClass]="pageService.animationClass">
    <app-area-list [controllers]="controllers" [header]="'POOL & SPA SYSTEMS'" [activeController]="activeController" (activeControllerChange)="selectController($event)"></app-area-list>
    <section class="area-list-page-controls poolSpa-main">
        <div class="page-navigation">
            <button [ngClass]="{ 'active': navigationIndex === i}" *ngFor="let body of activeController?.bodies; let i = index" (click)="controlNavigation(i, body)">{{ body.name }} Controls</button>
            <button *ngIf="activeController?.circuits?.length ?? 0 > 0"
                    [ngClass]="{ 'active': navigationIndex === activeController?.bodies?.length}"
                    (click)="controlNavigation(activeController?.bodies?.length ?? 0, undefined)">Aux Controls</button>
        </div>
        <app-pagination [pageConfigs]="poolPageConfigs"></app-pagination>
    </section>
</section>
