import {Component} from '@angular/core';
import {MediaAreaController} from "../../../pages/media/media.area.controller";
import {CardBase} from "../card.base";
import {MediaSourceController} from "../../../pages/media/media.source.controller";
import {PageService} from "../../../services/interface/page.service";
import {PageType} from "../../../services/interface/pageType";
import {MotionSlide} from "../../../services/interface/motionSlide";
import {MediaCrpcPlayerController} from "../../../pages/media/media-player-crpc/media.crpc.player.controller";

@Component({
  selector: 'app-media-card',
  templateUrl: './media-card.component.html',
  styleUrl: './media-card.component.scss'
})
export class MediaCardComponent extends CardBase<MediaAreaController> {
  constructor(private pageService: PageService) {
    super();
  }

  protected selectSource(source: MediaSourceController, _: number): void {
    if (this.controller === undefined) return;
    this.controller.selectSource(source);
    this.pageService.changePage(PageType.Media, MotionSlide.SlideUp);
  }

  protected override async controllerReady(): Promise<void> {
    this.pageService.activeMediaArea = this.controller;
    if (!this.controller || this.controller.activeSources.length == 0) return;
    for (let i = 0; i < this.controller.activeSources.length; i++) {
      const source: MediaSourceController = this.controller.activeSources[i];
      if (this.pageService.activeSource === undefined)
        this.pageService.activeSource = source;
      if (source instanceof MediaCrpcPlayerController) {
        await source.pollAsync();
        await source.registerAsync();
      }
    }
  }
}