import { Injectable, OnDestroy } from '@angular/core';
import {SettingsService} from "../storage/settings.service";
import {IService} from "../serviceManager/IService";

@Injectable({
  providedIn: 'root',
})
export class ClockService implements OnDestroy, IService {
  public day: number = 30;
  public year: number = 2023;
  public weekDay: string = "Wednesday"
  public monthShort: string = "Aug"
  public monthLong: string = "August";
  public time: string = "9:00 AM"
  private readonly timerId: any = null;
  private oldTime: number = 0;

  public weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  private monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  private monthsShort = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUNE', 'JULY', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  constructor(private settingsService: SettingsService) {
    this.setCurrentTime();
    this.timerId = setInterval(() => {
      const thisTime: number = new Date(Date.now()).getSeconds();
      this.setCurrentTime();
      if (!this.settingsService.screenSaverEnabled || this.settingsService.sleepActive) return;

      if (thisTime != this.oldTime) {
        //if (!this.settingsService.sleepActive && !this.communicationsService.intercomActive)
        this.settingsService.sleepIncrease();
        this.oldTime = thisTime;
      }
    }, 500);
  }

  ngOnDestroy(): void {
    clearInterval(this.timerId);
  }

  private setCurrentTime() {
    const date = new Date(Date.now());

    this.weekDay = this.weekDays[date.getDay()];
    this.day = date.getDate();
    this.year = date.getFullYear();
    this.monthShort = this.monthsShort[date.getMonth()];
    this.monthLong = this.monthsLong[date.getMonth()];

    let tempHrs = date.getHours();
    const flag = tempHrs >= 12 ? 'PM': 'AM';
    tempHrs = tempHrs == 0 ? 12: tempHrs;
    tempHrs = tempHrs > 12 ? tempHrs - 12: tempHrs;

    this.time = `${tempHrs.toString()}:${this.zeroPadding(date.getMinutes(), 2)} ${flag}`;
  }

  private zeroPadding(num: number, digit: number) {
    let zero = '';
    for (let i = 0; i < digit; i++) {
      zero += '0';
    }
    return (zero + num).slice(-digit);
  }
}
