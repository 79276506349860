import {EventEmitter, Injectable} from '@angular/core';
import {SettingsService} from "../storage/settings.service";
import {PageService} from "../interface/page.service";
import {PageType} from "../interface/pageType";
import {logger} from "../support/logger";
import {Camera} from "../../components/camera-display/camera";
import {CommunicationsService} from "./communications.service";
import {ControlMessage} from "./messaging/controlMessage";
import {ControlResult} from "./messaging/controlResult";
import {CrestronService} from "./crestron.service";
import {IService} from "../serviceManager/IService";

declare var CrComLib: any;

/*
Csig.Dialing_fb
Csig.Busy_fb
Csig.CallActive_fb
Csig.Ringing_fb
Csig.Incoming_fb
Csig.Hold_fb
Csig.Call_Terminated_fb
Csig.Ringback_fb
*/

@Injectable({
  providedIn: 'root'
})
export class IntercomService implements IService {
  constructor(private communicationsService: CommunicationsService, private pageService: PageService, private settingsService: SettingsService, private crestronService: CrestronService) {
    if (!crestronService.valid) return;

    this._callVolume = CrComLib.getState('n', 'Csig.All_Audio_Volume_fb');
    this.ringing = CrComLib.getState('b', 'Csig.Ringing_fb');
    this.callActive = CrComLib.getState('b', 'Csig.CallActive_fb');

    CrComLib.subscribeState('s', 'Csig.Ringing_fb', (state: boolean) => this.handleRinging(state));
    CrComLib.subscribeState('s', 'Csig.Incoming_URI_fb', (value: string) => this.handleIncomingCall(value));
    //CrComLib.subscribeState('s', 'Csig.Incoming_fb', (state: boolean) => this.handleIncomingCall(value));
    CrComLib.subscribeState('b', 'Csig.CallActive_fb', (value: boolean) => this.handleCallActive(value));
  }

  public IncomingCallEvent: EventEmitter<string> = new EventEmitter<string>();

  private _callVolume: number = 0;
  public get callVolume(): number {
    return this._callVolume;
  }

  public set callVolume(value: number) {
    if (!this.crestronService.valid) return;
    CrComLib.publishEvent('n', 'Csig.All_Audio_Volume', value);
    this._callVolume = value;
  }

  private _autoAnswer: boolean = false;

  public get AutoAnswer(): boolean {
    return this._autoAnswer;
  }

  public set autoAnswer(value: boolean) {
    if (!this.crestronService.valid) return;
    this._autoAnswer = value;
    CrComLib.publishEvent('b', 'Csig.Auto_Answer', value);
  }

  private _autoAnswerDelay: number = 0;

  public get AutoAnswerDelay(): number {
    return this._autoAnswerDelay;
  }

  public set autoAnswerDelay(value: number) {
    if (!this.crestronService.valid) return;
    if (value < 0) value = 0;
    else if (value > 5) value = 5;
    this._autoAnswerDelay = value;
    CrComLib.publishEvent('n', 'Csig.Auto_Answer_Delay', value);
  }

  private _ptt: number = 0;

  public get Ptt(): number {
    return this._ptt;
  }

  public set ptt(value: number) {
    if (!this.crestronService.valid) return;
    this._ptt = value;
    CrComLib.publishEvent('b', 'Csig.PTT_mode', value);
  }

  private _micMuted: boolean = false;
  public get micMuted(): boolean {
    return this._micMuted;
  }

  public set micMuted(value: boolean) {
    if (!this.crestronService.valid) return;
    this._micMuted = value;
    CrComLib.publishEvent('b', 'Csig.Muted', value);
  }

  public ringing: boolean = false;
  public callActive: boolean = false;
  public activeCamera: Camera | undefined;

  public handleCommand(type: string, command: string, value: boolean | number | string): void {
    if (!this.crestronService.valid) return;
    CrComLib.publishEvent(type, command, value);
  }

  private handleRinging(state: boolean)
  {
    logger.debug(`INTERCOM RINGING IS ${state}`);
    this.ringing = state
  }

  private async handleIncomingCall(uri: string | undefined): Promise<void> {

    logger.debug(`INCOMING CALL FROM ${uri}`)

    this.ringing = uri !== '';

    if (uri == '' && this.callActive == false) //this means either ignore was pressed or the other end stopped calling
    {
      this.pageService.removePopup(PageType.Intercom);
      return;
    }

    await this.settingsService.stopScreenSaverAsync();
    if (!uri) return;
    if (!this.pageService.activePages.includes(PageType.Intercom)) {
        this.pageService.addPopup(PageType.Intercom);
    }
    const displayName: string = CrComLib.getState('s', 'Csig.Incoming_Display_Name_fb');
    const sipExt: number = parseInt(uri.substring(uri.indexOf(':') + 1, uri.indexOf('@')));
    this.communicationsService.sendMessageAndSubscribe(new ControlMessage(7000, undefined, "intercomGetCamera", {
      'ext': sipExt
    }), true, (response => this.handleIntercomCameraResponse(response)));

    this.IncomingCallEvent.emit(uri);
  //   const sipInfo: intercom = this.intercomData.find((item) => item.SipExtension == sipExt);
  //   if (sipInfo) {
  //     sipInfo.sipData = uri;
  //     if (sipInfo.CameraName != 'None') {
  //       sipInfo.Camera = this.configData.Cameras.find((item) => item.Name == sipInfo.CameraName);
  //     }
  //     this.intercomIncomingData = sipInfo;
  //   } else {
  //     this.intercomIncomingData = {
  //       Name: displayName,
  //       sipData: uri,
  //       SipExtension: sipExt,
  //       CameraName: undefined,
  //       Camera: undefined,
  //     };
  //   }
  // else
  //   if (!this.intercomActive) {
  //     this.intercomIncomingData = {
  //       Name: 'Inactive',
  //       sipData: undefined,
  //       SipExtension: 0,
  //       CameraName: undefined,
  //       Camera: undefined,
  //     };
  //   }
  //   this.intercomRinging = uri;
  //   this.intercomActive = uri;
  }
  private async handleCallActive(state: boolean): Promise<void> {
    logger.debug(`CALL ACTIVE STATE IS ${state}`)
    this.ringing = false;
    this.callActive = state;
    if (!state)
      this.pageService.removePopup(PageType.Intercom);
    // if (!value)
    //   this.intercomIncommingData = {
    //     Name: 'Inactive',
    //     sipData: undefined,
    //     SipExtention: 0,
    //     CameraName: undefined,
    //     Camera: undefined,
    //   };
  }

  private handleIntercomCameraResponse(response: ControlResult): void {
    switch (response.result) {
      case "Not Found":
        logger.debug("requested camera does not exist");
        return;
      case "Not Authenticated":
        logger.error("connection isn't authenticated...")
        return;
      case "Invalid extension supplied":
        logger.error("extension doesn't exist in system");
        return;
    }

    const cameraSettings = JSON.parse(response.result);
    this.activeCamera = Object.assign(new Camera(), cameraSettings);
  }
}
