<section #control class="userControl">
  <div #title class="title">
    <div class="inputCombobox" [ngClass]="{ error: error }" *ngIf="allowInput">
      <input #input type="text" required spellcheck="false" (input)="inputChange()" (keydown)="inputKeyDown($event)" (click)="dropDownVisibility('toggle')" />
      <label *ngIf="itemSource.length > 1">{{ label | uppercase }}</label>
      <span class="underline"></span>
      <i class="fas fa-times-circle" (click)="inputClear()" *ngIf="visible && input?.value != ''"></i>
      <i class="fas fa-chevron-down" [ngClass]="{ flip: visible }" (click)="dropDownVisibility('toggle')" *ngIf="itemSource.length > 1"></i>
    </div>
    <div class="nonInputCombobox" [ngClass]="{ error: error }" *ngIf="!allowInput">
      <button #nonInput class="selected-item-label" (click)="dropDownVisibility('toggle')">
        <span *ngIf="itemProperty == ''">{{ selectedItem }}</span>
        <span *ngIf="itemProperty != ''">{{ selectedItem[itemProperty] }}</span>
      </button>
      <label [ngClass]="{ 'item-valid': selectedItem != undefined }" *ngIf="itemSource.length > 1">{{ label | uppercase }}</label>
      <span class="underline" [ngClass]="{ 'item-focus': visible }"></span>
      <i class="fas fa-chevron-down" [ngClass]="{ flip: visible }" (click)="dropDownVisibility('toggle')" *ngIf="itemSource.length > 1"></i>
    </div>
  </div>

  <div #list class="list">
    <div class="list-container">
      <button class="list-item" *ngFor="let item of filteredSource; let i = index" (click)="filterSelect(item)" [ngClass]="{ selected: listIndex == i }">
        <h2 *ngIf="itemProperty != ''">{{ item[itemProperty] }}</h2>
        <h2 *ngIf="itemProperty == ''">{{ item }}</h2>
        <h4 *ngIf="itemDescription && itemProperty != ''">{{ item[itemDescription] }}</h4>
      </button>
    </div>
  </div>
</section>
