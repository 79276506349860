import {EventEmitter, Injectable} from '@angular/core';
import {CrestronService} from "../communications/crestron.service";
import {IService} from "../serviceManager/IService";
import {LightingMode} from "./lightingMode";
import {SystemOptions} from "../../pages/login/login.response";

@Injectable({
  providedIn: 'root'
})
export class SettingsService implements IService {

  constructor(protected crestronService: CrestronService) {
    this.crestronEnv = navigator.userAgent.toLowerCase().search('crestron') != -1;
  }

  public requestScreenSaver: EventEmitter<void> = new EventEmitter<void>();

  private _sleepCount: number = 0;
  public get sleepCount(): number {
    return this._sleepCount;
  }

  public set sleepCount(value: number) {
    if (this._sleepCount == value) return;
    this._sleepCount = value;
    if (value == 0) this.stopScreenSaverAsync().then();
  }

  public screenSaverEnabled: boolean = true;
  public sleepActive: boolean = false;
  public videoPlaying: boolean = false;
  public crestronEnv: boolean = false;
  public zipCode: number = 0;
  public timeZone?: string;
  public locale?: string;
  public city?: string;
  public lightingMode?: LightingMode;

  private resumeVideo: boolean = false;

  public sleepIncrease() {
    this.sleepCount++;
    if (this.sleepCount >= 5 * 60) this.startScreenSaverAsync().then();
  }

  public importSystemOptions(options: SystemOptions): void {
    this.city = options.city;
    this.zipCode = options.zipCode;
    this.lightingMode = options.lightingMode;
    this.timeZone = options.timeZone;
    this.locale = options.locale;
  }

  public async startScreenSaverAsync() {
    this.resumeVideo = this.videoPlaying;
    this.videoPlaying = false;
    await this.crestronService.lowerBrightness();
    this.sleepActive = true;
    this.requestScreenSaver.emit();
    // this.pageService.addPage(PageType.ScreenSaver);
  }

  public async stopScreenSaverAsync() {
    this.sleepActive = false;
    await this.crestronService.resumeBrightness();
    this.videoPlaying = this.resumeVideo;
  }
}
