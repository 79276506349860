import {Component, Input} from '@angular/core';
import {MediaAreaController} from "../../pages/media/media.area.controller";

@Component({
  selector: 'app-sleep-timer',
  templateUrl: './sleep-timer.component.html',
  styleUrl: './sleep-timer.component.scss'
})
export class SleepTimerComponent {
  constructor() { }

  @Input() area: MediaAreaController | undefined;

  get sleepTimerValue(): number {
    if (!this.area || !this.area.sleepTimerDisplay) return 0;
    return this.convertTimeSpanToSeconds(this.area.sleepTimerDisplay)
  }

  set sleepTimerValue(value: number) {
    if (!this.area) return;

    this.area.setSleepTimer(value);
  }

  protected sleepTimerToggle(): void {
    if (this.area === undefined) return;
    this.area.setSleepTimer(this.area.sleepTimerActive ? 0 : 3600);
  }

  private convertTimeSpanToSeconds(timeSpan: string): number {
    const parts = timeSpan.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);

    return (hours * 3600) + (minutes * 60) + seconds;
  }
}
