import {EventEmitter, Injectable} from '@angular/core';
import {PageType} from "./pageType";
import {MotionSlide} from "./motionSlide";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {logger} from "../support/logger";
import {IService} from "../serviceManager/IService";
import {DatabaseService} from "../storage/database.service";
import {ConfigurationService} from "../configuration/configuration.service";
import {System} from "../configuration/system";
import {PageConfig} from "../../components/pagination/pagination.component";
import {MediaSourceController} from "../../pages/media/media.source.controller";
import {MediaCrpcPlayerController} from "../../pages/media/media-player-crpc/media.crpc.player.controller";
import {Area} from "../configuration/area";
import {MediaAreaController} from "../../pages/media/media.area.controller";
import {SettingsService} from "../storage/settings.service";
import {CrestronService} from "../communications/crestron.service";

@Injectable({
  providedIn: 'root'
})
export class PageService implements IService {
  constructor(private configurationService: ConfigurationService, private crestronService: CrestronService, private databaseService: DatabaseService, private settingsService: SettingsService) {
    this.getControllersByPageTypes().then();
    this.settingsService.requestScreenSaver.subscribe((): void => {
      this.addPage(PageType.ScreenSaver);
    });
    const dashboardIndex = this.configurationService.systems.findIndex((s: System) => s.pageType === PageType.Dashboard);
    this.activePageType = dashboardIndex >= 0 ? PageType.Dashboard : this.configurationService.systems[0]?.pageType;
  }

  public activeSystem: System | undefined;
  public activeSource: MediaSourceController | undefined;
  private _activeArea: Area | undefined;
  get activeArea(): Area | undefined {
    return this._activeArea;
  }

  set activeArea(value: Area | undefined) {
    this._activeArea = value;
    if (!this._activeArea?.controlId) return;

    this.assignControllerForPageTypeAsync(PageType.Dashboard, this._activeArea.controlId).then();
  }

  public activeMediaArea: MediaAreaController | undefined;
  public activePages: PageType[] = [];
  public activePopups: PageType[] = [];
  public animationTime: number = 200;
  public animationClass: string = '';
  private areasByPageTypes: Map<PageType, string> | undefined;
  private activePageType: PageType;

  public closeMenuEvent: EventEmitter<void> = new EventEmitter<void>();
  public activePageChangeEvent: EventEmitter<number> = new EventEmitter<number>();

  public reset(): void {
    this.activeArea = undefined;
    this.activeMediaArea = undefined;
    this.activeSource = undefined;
    this.activePages = [];
    this.activePopups = [];
    this.activePageType = PageType.Initialized;
  }
  
  public closeMenus(): void {
    this.closeMenuEvent.emit();
  }

  public changePage(id: PageType, motion: MotionSlide) {
    const newIndex: number = this.configurationService.systems.findIndex((s: System): boolean => s.pageType == id);

    const activePagesIndex: number = this.activePages.findIndex((id: PageType): boolean => id == this.activePageType);

    if (this.crestronService.valid) {
      if (activePagesIndex > -1) this.activePages.splice(activePagesIndex, 1);
      this.animationClass = 'page-crestron-display';
      this.activePageChangeEvent.emit(newIndex);
      this.activePageType = id;
      this.activePages.push(id);
      return;
    }

    const oldIndex: number = this.activePageType == undefined ? -1 : this.getActiveSystemIndex();
    this.activePageType = id;
    
    if (motion == MotionSlide.SlideDown) this.animationClass = 'page-slide-out-to-right';
    else this.animationClass = 'page-slide-out-to-left'

    if (this.activePages.length > 0) {
      setTimeout(() => {
        if (activePagesIndex > -1) this.activePages.splice(activePagesIndex, 1);
        this.activePageChangeEvent.emit(newIndex);
        this.activePages.push(id);
        this.animationClass = newIndex > oldIndex ? 'page-slide-in-from-right' : 'page-slide-in-from-left';
        const stop = 1;
      }, this.animationTime);
    } else {
      this.activePageChangeEvent.emit(newIndex);
      this.animationClass = 'page-slide-in-from-left';
      this.activePages.push(id);
    }
  }

  public getActiveSystemIndex(): number {
    let index = this.configurationService.systems.findIndex((s: System) => s.pageType == this.activePageType);
    if (index < 0) {
      this.activeSystem = undefined;
      index = 0;
    } else
      this.activeSystem = this.configurationService.systems[index];
    return index;
  }

  public addPage(id: PageType, allowDuplicate: boolean = false) {
    if (this.activePages.includes(id) && !allowDuplicate) return;
    this.activePages.push(id);
  }

  public removePage(id: PageType): void {
    const index = this.activePages.findIndex((p: PageType) => p == id);
    if (index == -1) return;
    this.activePages.splice(index, 1);
  }

  public addPopup(id: PageType): void {
    if (this.activePopups.includes(id)) return;
    this.activePopups.push(id);
  }

  public removePopup(id: PageType) {
    const index = this.activePopups.findIndex((p: PageType) => p == id);
    if (index == -1) return;
    this.activePopups.splice(index, 1);
  }

  public getControllerForPageType(pageType: PageType): string | undefined {
    return this.areasByPageTypes?.get(pageType);
  }

  public async assignControllerForPageTypeAsync(pageType: PageType, controllerId: string): Promise<void> {
    return new Promise((resolve, _): void => {
      // Check if the controller ID for the page type already matches the new ID
      if (this.areasByPageTypes?.get(pageType) === controllerId) {
        resolve(); // No change needed, resolve immediately
        return;
      }

      // Update the mapping with the new controller ID
      this.areasByPageTypes?.set(pageType, controllerId);

      // Persist the changes
      this.saveControllersByPageTypes();

      resolve(); // Resolve the promise after updating
    });
  }

  public getActiveSourceAsMediaCrpcPlayerController(): MediaCrpcPlayerController | undefined {
    //logger.debug('getting active source as media player', this.activeSource instanceof MediaSourceController);

    if (this.activeSource instanceof MediaCrpcPlayerController)
      return this.activeSource;

    return undefined;
  }

  private async getControllersByPageTypes(): Promise<void> {
    try {
      const records = await this.databaseService.getAllAsync('activeAreas').catch(error => console.error(`Error fetching records from 'activeAreas':`, error));
      const pageMap: Map<PageType, string> = new Map<PageType, string>();

      if (records !== undefined) {
        records.forEach(record => {
          pageMap.set(record.pageType, record.value); // Assume each record has pageType and value
        });
      }
      this.areasByPageTypes = pageMap
    } catch (error) {
      logger.error('failed to get areas by page types with the message of', error)
    }
  }

  private async saveControllersByPageTypes(): Promise<void> {
    if (this.areasByPageTypes == undefined) {
      logger.error("why is areasByPageTypes undefined?!?!")
    }
    for (let [pageType, value] of this.areasByPageTypes!) {
      const dataObject = {pageType, value}; // Convert to the expected object structure
      await this.databaseService.addOrUpdateAsync('activeAreas', dataObject).catch(error => console.error(`Error saving ${pageType}:`, error));
    }
  }
}
