<section class="page area-list-page media" [ngClass]="pageService.animationClass">
  <app-area-list [controllers]="controllers" [header]="'MEDIA AREAS'" [activeController]="activeController"
                 (activeControllerChange)="selectController($event)"></app-area-list>
  <section class="area-list-page-controls media-main" *ngIf="activeController != undefined">
    <div class="page-navigation">
      <button [ngClass]="{ 'active': navigationIndex === 0}" (click)="navigationIndex = 0">Sources</button>
      <button [ngClass]="{ 'active': navigationIndex === i + 1}"
              *ngFor="let source of activeController?.activeSources; let i = index"
              (click)="controlNavigation(i + 1, source)">
              <app-marquee [text]="source.name + ' Controls'"></app-marquee>
      </button>
    </div>

    <section class="media-selection" *ngIf="navigationIndex == 0">
      <button class="card-container" *ngFor="let source of activeController!.sources; let i = index"
              [ngClass]="{'active': activeController!.activeSources.includes(source)}"
              (click)="selectSource(source, i)">
        <div class="card">
          <!--                <div class="card" [ngClass]="{'flipped': activeController!.activeSources.includes(source)}">-->
          <div class="front">
            <i class="icon" [ngClass]="source.icon"></i>
            <span class="label">{{ source.name }}</span>

            <span class="used-by-label" *ngIf="source.inUseBy.length > 0"
                  [ngClass]="{'active-label': activeController!.activeSources.includes(source)}">IN USE BY:</span>
            <div class="used-by-list" *ngIf="source.inUseBy.length > 0">
              <span *ngFor="let areaController of source.inUseBy">{{ areaController.name }}</span>
            </div>
          </div>
          <!--                  this does NOT render properly on TSW-1060's -->
          <!--                    <div class="back">-->
          <!--                        &lt;!&ndash; Back side content &ndash;&gt;-->
          <!--                        <p>More information...</p>-->
          <!--                    </div>-->
        </div>
      </button>
    </section>
    <section class="media-controls"
             *ngIf="navigationIndex >= 1 && activeSource && !activeSource!.interfaces.includes('ICrpcMediaPlayer')">
      <!--            <section class="favorites" *ngIf="activeControlIndex == 1"></section>-->

      <app-pagination [hideDots]="true" [(activePage)]="activePage" [pageConfigs]="mediaPageConfigs"></app-pagination>
      <section class="control-selection">
        <button (click)="selectPage(this.navigationIndexes[0])" *ngIf="mediaPageConfigs[0].visible"><i
          class="fa-light fa-heart"></i></button>
        <div *ngIf="!mediaPageConfigs[0].visible"></div>
        <button (click)="selectPage(this.navigationIndexes[1])" *ngIf="mediaPageConfigs[1].visible"><i
          class="fa-light fa-grid"></i></button>
        <div *ngIf="!mediaPageConfigs[1].visible"></div>
        <button (click)="selectPage(this.navigationIndexes[2])" *ngIf="mediaPageConfigs[2].visible"><i
          class="fa-light fa-arrows-up-down-left-right"></i></button>
        <div *ngIf="!mediaPageConfigs[2].visible"></div>
        <button (click)="selectPage(this.navigationIndexes[3])" *ngIf="mediaPageConfigs[3].visible"><i
          class="fa-light fa-play-pause"></i></button>
        <div *ngIf="!mediaPageConfigs[3].visible"></div>
        <button (click)="selectPage(this.navigationIndexes[4])" *ngIf="mediaPageConfigs[4].visible"><i
          class="fa-light fa-ellipsis-stroke"></i></button>
        <div *ngIf="!mediaPageConfigs[4].visible"></div>
        <button (click)="selectPage(this.navigationIndexes[5])" *ngIf="mediaPageConfigs[5].visible"><i
          class="fa-light fa-tv"></i><i class="fa-solid fa-music-note"></i></button>
        <div *ngIf="!mediaPageConfigs[5].visible"></div>
      </section>
    </section>

    <app-media-player-crpc [source]="pageService.getActiveSourceAsMediaCrpcPlayerController()"
                           *ngIf="navigationIndex >= 1 && pageService.getActiveSourceAsMediaCrpcPlayerController()"></app-media-player-crpc>
  </section>
</section>
