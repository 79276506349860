import {Component, Input} from '@angular/core';
import {LightingController} from "../lighting.controller";
import {logger} from "../../../services/support/logger";

@Component({
  selector: 'app-load-list',
  templateUrl: './load-list.component.html',
  styleUrl: './load-list.component.scss'
})
export class LoadListComponent {
  private _loads: LightingController[] | undefined;
  get loads(): LightingController[] | undefined {
    return this._loads;
  }

  @Input() set loads(value: LightingController[] | undefined) {
    this._loads = value;
  }
}
