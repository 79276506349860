import {Injectable} from '@angular/core';
import {ControlMessage} from "./messaging/controlMessage";
import {Subject} from "rxjs";
import {ControlResult} from "./messaging/controlResult";
import {logger} from "../support/logger";
import {ControllerRegistry} from "../registries/controller.registry.service";
import {AreaRegistry} from "../registries/area.registry.service";
import {ContractHandler} from "../configuration/contract.handler";
import {AreaController} from "../configuration/area.controller";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private subjects: { [key: string | number]: Subject<ControlMessage | ControlResult> } = {};

  constructor() {
  }

  public addMessage(message: ControlMessage | ControlResult): void {
    if (message instanceof ControlMessage) {
      if (message.method === undefined) return;
      if (!this.subjects[message.method]) {
        if (message.messageId === 0) //this is an event update
        {
          if (message.controlId === undefined) return;

          const controllerHandler: ContractHandler | undefined = ControllerRegistry.getControllerById(message.controlId);
          if (controllerHandler !== undefined) {
            controllerHandler.processStatusEvent(message);
            return;
          }

          const areaHandlers: AreaController[] | undefined = AreaRegistry.getControllersById(message.controlId);
          if (areaHandlers === undefined) {
            logger.error("Got status update and couldn't find the controller or area");
            return;
          }

          areaHandlers.forEach((areaHandler: AreaController) => areaHandler.processStatusEvent(message));
          return;
        }
        this.subjects[message.method] = new Subject<ControlMessage | ControlResult>();
      }
      this.subjects[message.method].next(message);
    }
    if (message instanceof ControlResult) {
      if (message.messageId === undefined) return;
      if (!this.subjects[message.messageId]) {
        logger.error("Received result without a callback", message);
        return;
      }
      this.subjects[message.messageId].next(message);
    }
  }

  public subscribe(subject: string | number, callback: (response: ControlMessage | ControlResult) => void): void {
    if (!this.subjects[subject]) {
      this.subjects[subject] = new Subject<ControlMessage | ControlResult>();
    }
    this.subjects[subject].subscribe(callback);
  }

  public unsubscribe(subject: string | number): void {
    if (this.subjects[subject]) {
      this.subjects[subject].complete();
      this.subjects[subject].unsubscribe();
      delete this.subjects[subject];
    }
  }
}
