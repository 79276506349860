import {CameraType} from "./cameraType";
import {ConnectionSetting} from "./connectionSetting";

export class Camera {
    name: string = "Camera";
    uriPostfix: string | undefined;
    connection?: ConnectionSetting;
    type: CameraType = CameraType.None;

    public static Parse(settings: any) : Camera {
      const camera = new Camera();
      camera.name = settings.name;
      camera.uriPostfix = settings.uriPostfix;
      camera.connection = settings.connection;
      camera.type = settings.type.value;
      return camera;
    }
}
