import {Component, OnDestroy} from '@angular/core';
import {PageService} from "../../services/interface/page.service";
import {CommunicationsService} from "../../services/communications/communications.service";
import {PageSystem} from "../pageSystem";
import {ClimateController} from "./climate.controller";
import {SystemTypes} from "../../services/support/isystem";
import {ConfigurationService} from "../../services/configuration/configuration.service";

@Component({
  selector: 'app-climate',
  templateUrl: './climate.component.html',
  styleUrls: ['./climate.component.scss']
})
export class ClimateComponent extends PageSystem<ClimateController> implements OnDestroy {
  constructor(communicationsService: CommunicationsService, configurationService: ConfigurationService, pageService: PageService) {
    super(communicationsService, configurationService, pageService, SystemTypes.Climate);
  }
  
  ngOnDestroy(): void {
    this.destroy();
  }
}
