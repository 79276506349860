export class ControlResult {
  constructor(public messageId: number, public method: string, public controlId: string | undefined, public result: any) {
  }

  static parseControlMessage(data: string): ControlResult | string {
    try {
      const parsed = JSON.parse(data);
      if (typeof parsed !== 'object' || parsed === null) {
        return 'SyntaxError: Parsed data should be an object';
      }
      if (parsed.messageId === undefined || parsed.messageId === null) {
        return 'SyntaxError: Missing id parameter';
      }
      if (typeof parsed.result !== 'string') {
        return 'SyntaxError: Missing or invalid result parameter';
      }
      return new ControlResult(parsed.messageId, parsed.method, parsed.controlId, parsed.result);
    } catch (err: any) {
      return `Error: ${err.toString()}`;
    }
  }

  public toJSON(): string {
    const obj = {
      messageId: this.messageId,
      controlId: this.controlId,
      result: this.result,
    };
    return JSON.stringify(obj);
  }
}
