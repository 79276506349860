export enum PageType {
  //These are the types from C#
  None = 0,
  Cameras,
  Climate,
  DoorLocks,
  Heaters,
  Intercom,
  Lighting,
  Media,
  PoolSpa,
  Power,
  Shades,
  Security,
  Settings,
  Sources,
  Dashboard,
  Outlets,
  FireFeatures,

  //These types are used purely in the UI
  Debug = 1000,
  Initialized = 4000,
  Startup = 4001,
  Login = 4002,
  MediaUuid = 4003,

  OffCheck = 10000,
  ScreenSaver,
  MediaShare,
  MediaPopup,
  DoorLocksOptions,
}
