<section class="area-list-component-small" (click)="lockScreen($event)" [ngClass]="{'expanded': menuVisibility}"
         [ngStyle]="{ '--selected-area-name': activeController?.name ?? 'Select an Area'}">
  <div class="overlay"></div>
  <button class="small-circle" *ngIf="!menuVisibility">
    <i class="fa-light fa-square-list"></i>
    <span class="controller-label">{{ activeController?.name ?? 'Select an Area' | uppercase }}</span>
  </button>

  <div class="list-content" *ngIf="menuVisibility">
    <label>{{ header | uppercase }}</label>
    <div class="area-list-container" *ngIf="groupedControllers">
      <ng-container *ngFor="let parentKey of objectKeys(groupedControllers)">
        <div class="group-header" (click)="toggleGroupVisibility(parentKey)">
          <i
            [ngClass]="{'fa-solid fa-chevron-down': isGroupVisible(parentKey), 'fa-regular fa-chevron-right': !isGroupVisible(parentKey)}"></i>
          <span>{{ parentKey }}</span>
        </div>
        <div class="group-content" *ngIf="isGroupVisible(parentKey)">
          <button class="area-list-item" *ngFor="let controller of groupedControllers[parentKey]; let i = index"
                  [ngClass]="getAreaListItemClasses(controller)"
                  (click)="selectGroupedController(controller, parentKey)"
                  [ngStyle]="{ '--active-index': activeIndex, '--header-index': headerIndex}">
            <span class="label">{{ controller.name }}</span><br>
            <span class="description">{{ controller.description }}</span>
          </button>
        </div>
      </ng-container>
    </div>
    <div class="area-list-container" *ngIf="groupedControllers == undefined">
      <button class="area-list-item" *ngFor="let controller of controllers; let i = index"
              (click)="selectController(controller, i)" [ngStyle]="{ '--active-index': activeIndex }"
              [ngClass]="{ 'active': activeController == controller }">
        <span class="label">{{ controller.name }}</span><br>
        <span class="description">{{ controller.description }}</span>
      </button>
    </div>
  </div>
</section>

<section class="area-list">
  <label>{{ header | uppercase }}</label>
  <div class="area-list-container" *ngIf="groupedControllers">
    <ng-container *ngFor="let parentKey of objectKeys(groupedControllers)">
      <div class="group-header" (click)="toggleGroupVisibility(parentKey)">
        <i
          [ngClass]="{'fa-solid fa-chevron-down': isGroupVisible(parentKey), 'fa-regular fa-chevron-right': !isGroupVisible(parentKey)}"></i>
        <span>{{ parentKey }}</span>
      </div>
      <div class="group-content" *ngIf="isGroupVisible(parentKey)">
        <button class="area-list-item" *ngFor="let controller of groupedControllers[parentKey]; let i = index"
                [ngClass]="getAreaListItemClasses(controller)"
                (click)="selectGroupedController(controller, parentKey)"
                [ngStyle]="{ '--active-index': activeIndex, '--header-index': headerIndex}">
          <span class="label">{{ controller.name }}</span><br>
          <span class="description">{{ controller.description }}</span>
        </button>
      </div>
    </ng-container>
  </div>
  <div class="area-list-container" *ngIf="groupedControllers == undefined">
    <button class="area-list-item" *ngFor="let controller of controllers; let i = index"
            (click)="selectController(controller, i)" [ngStyle]="{ '--active-index': activeIndex }"
            [ngClass]="{ 'active': activeController == controller }">
      <span class="label">{{ controller.name }}</span><br>
      <span class="description">{{ controller.description }}</span>
    </button>
  </div>
</section>
