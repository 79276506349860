import {Component, HostListener} from '@angular/core';
import {ClockService} from "./services/time/clock.service";
import {CommunicationsService} from "./services/communications/communications.service";
import {SettingsService} from "./services/storage/settings.service";
import {PageService} from "./services/interface/page.service";
import {PageType} from './services/interface/pageType';
import {ConnectionValid} from './services/communications/connection.valid';
import {ScreenSaverType} from "./pages/screen-saver/screen-saver.type";
import {LoggingService} from "./services/support/logging.service";
import {IntercomService} from "./services/communications/intercom.service";
import {CrestronService} from "./services/communications/crestron.service";
import {ServiceManager} from "./services/serviceManager/service-manager.service";
import {AreaRegistry} from "./services/registries/area.registry.service";
import {ControllerRegistry} from "./services/registries/controller.registry.service";
import {ClimateController} from "./pages/climate/climate.controller";
import {LightingController} from "./pages/lighting/lighting.controller";
import {MediaSourceController} from "./pages/media/media.source.controller";
import {MediaAreaController} from "./pages/media/media.area.controller";
import {LightingAreaController} from "./pages/lighting/lighting.area.controller";
import {SystemTypes} from "./services/support/isystem";
import {PoolSpaController} from "./pages/pool-spa/pool-spa.controller";
import {ControlType} from "./services/registries/control.type";
import {PoolSpaCircuitController} from "./pages/pool-spa/pool-spa.circuit.controller";
import {PoolSpaBodyController} from "./pages/pool-spa/pool-spa.body.controller";
import {ConfigurationService} from "./services/configuration/configuration.service";
import {DoorLocksController} from "./pages/door-locks/door-locks.controller";
import {DoorLocksAreaController} from "./pages/door-locks/door-locks.area.controller";
import {CrpcService} from "./services/media/crpc.service";
import {MediaCrpcPlayerController} from "./pages/media/media-player-crpc/media.crpc.player.controller";
import {LightingSceneController} from "./pages/lighting/lighting.scene.controller";
import {WeatherController} from "./services/weather/weather.controller";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  PageType = PageType;
  ConnectionValid = ConnectionValid;
  ScreenSaverType = ScreenSaverType;
  title = 'Definition Control';

  //constructor(public clockService: ClockService, public pageService: PageControlService, public intercom: IntercomService, public communicationsService: CommunicationsService, public config: ConfigurationService, public settingsService: SettingsService) {
  constructor(
    public configurationService: ConfigurationService,
    public serviceManager: ServiceManager,
    public crpcService: CrpcService,
    public clockService: ClockService,
    public crestronService: CrestronService,
    public communicationsService: CommunicationsService,
    public IntercomService: IntercomService,
    public loggingService: LoggingService,
    public pageService: PageService,
    public settingsService: SettingsService) {
    //REMEMBER! if these services aren't called right at the start, they don't load until first used
    
    ServiceManager.register("configurationService", configurationService);
    ServiceManager.register("crpcService", crpcService);
    ServiceManager.register("clockService", clockService);
    ServiceManager.register("crestronService", crestronService);
    ServiceManager.register("communicationsService", communicationsService);
    ServiceManager.register("intercomService", IntercomService);
    ServiceManager.register("loggingService", loggingService);
    ServiceManager.register("pageService", pageService);
    ServiceManager.register("settingsService", settingsService);
        
    AreaRegistry.register(SystemTypes.Media, MediaAreaController);
    AreaRegistry.register(SystemTypes.Lighting, LightingAreaController);
    AreaRegistry.register(SystemTypes.DoorLocks, DoorLocksAreaController);
        
    ControllerRegistry.register(ControlType.Climate, ClimateController);
    ControllerRegistry.register(ControlType.Lighting, LightingController);
    ControllerRegistry.register(ControlType.LightingScene, LightingSceneController);
    ControllerRegistry.register(ControlType.AudioSource, MediaSourceController);
    ControllerRegistry.register(ControlType.VideoSource, MediaSourceController);
    ControllerRegistry.register(ControlType.AudioVideoSource, MediaSourceController);
    ControllerRegistry.register(ControlType.AudioSourceMediaPlayer, MediaCrpcPlayerController);
    ControllerRegistry.register(ControlType.Pool, PoolSpaController);
    ControllerRegistry.register(ControlType.PoolBody, PoolSpaBodyController);
    ControllerRegistry.register(ControlType.PoolCircuit, PoolSpaCircuitController);
    ControllerRegistry.register(ControlType.DoorLock, DoorLocksController);
    ControllerRegistry.register(ControlType.Weather, WeatherController);
    
    setTimeout(() => {
      console.clear();
    }, 60000);
  }

  @HostListener('window:pointerdown', ['$event'])
  onPointerDown(_: PointerEvent) {
    this.settingsService.sleepCount = 0;
  }

  @HostListener('pointermove', ['$event'])
  onPointerMove(_: PointerEvent) {
    this.settingsService.sleepCount = 0;
  }

  @HostListener('window:pointerup', ['$event'])
  onPointerUp(_: PointerEvent) {
    this.communicationsService.clearPressedMessages();
  }

  @HostListener('window:contextmenu', ['$event'])
  onContextMenu(event: Event) {
    event.preventDefault();
    const target = event.target as any;
    target.blur();
  }
}
