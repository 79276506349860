import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Camera} from "./camera";
import {CommunicationsService} from "../../services/communications/communications.service";
import {CameraType} from "./cameraType";

@Component({
  selector: 'app-camera-display',
  templateUrl: './camera-display.component.html',
  styleUrls: ['./camera-display.component.scss']
})
export class CameraDisplayComponent implements OnInit, OnDestroy {
  private _activeCamera: Camera | undefined;
  @Input()
  set activeCamera(value: Camera | undefined) {
    this._activeCamera = value;
    if (this.imageWindow) this.imageWindow.src = '';
    const needToClose = this.streamSocket == undefined ? false : this.streamSocket.readyState == 1;
    if (needToClose) {
      this.streamSocket?.close();
    } else {
      this.subscribeCamera();
    }
  }
  get activeCamera(): Camera | undefined {
    return this._activeCamera;
  }
  private blankImage =
      'data:image/gif;base64,R0lGODlhAQABAPcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAABAAEAAAgEAP8FBAA7';

  private imageWindow?: HTMLImageElement;
  private cameraSelectSocket?: WebSocket | null;
  private streamSocket?: WebSocket | null;
  private proxyURL: string = '';

  private isClosing: boolean = false;
  @ViewChild('image')
  set _image(element: ElementRef) {
    if (element) {
      this.imageWindow = element.nativeElement;
      if (!this.imageWindow) return;
      this.imageWindow.src = this.blankImage;
    }
  }
  constructor(public communicationsService: CommunicationsService) {
    this.proxyURL = `${communicationsService.settings!.useWss ? 'wss' : 'ws'}://${communicationsService.settings!.url}:42081`;
    this.cameraSelectSocket = new WebSocket(`${this.proxyURL}/cameras`);

    this.cameraSelectSocket.onopen = () => {
      if (this.activeCamera) this.subscribeCamera();
    };

    this.cameraSelectSocket.onmessage = (select) => {
      if (!this.imageWindow) return;
      this.imageWindow.src = this.blankImage;
      const cam = select.data;
      const allowChange: boolean = this.streamSocket == null ? true : this.streamSocket.readyState == this.streamSocket.CLOSED;
      if (!this.activeCamera) return;
      if (cam == `/cameras/${this.activeCamera.name}` && allowChange) {
        this.streamSocket = null;
        this.streamSocket = new WebSocket(`${this.proxyURL}${cam}`);
        this.streamSocket.onopen = () => {};
        this.streamSocket.onmessage = (msg) => {
          if (!this.imageWindow) return;
          const info = JSON.parse(msg.data);
          if (info.message == 'Image') this.imageWindow.src = `data:image/jpeg;base64,${info.jpeg}`;
        };
        this.streamSocket.onclose = () => {
          if (!this.isClosing) this.subscribeCamera();
        };
      }
    };
  }
  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.isClosing = true;
    if (this.cameraSelectSocket != null) {
      this.cameraSelectSocket.close();
      this.cameraSelectSocket = null;
    }
    if (this.streamSocket != null) {
      this.streamSocket.close();
      this.streamSocket = null;
    }
    if (this.imageWindow != null) this.imageWindow.src = this.blankImage;
  }

  subscribeCamera() {
    if (!this.cameraSelectSocket || !this.activeCamera || !this.imageWindow) return;
    if (this.cameraSelectSocket.readyState == 1) {
      switch (this.activeCamera.type) {
        case CameraType.Rtsp:
        case CameraType.Mjpeg:
          this.proxyURL = `${this.communicationsService.settings!.useWss ? 'wss' : 'ws'}://${this.communicationsService.settings!.url}:42081`;
          this.cameraSelectSocket.send(this.activeCamera.name);
          break;
        case CameraType.MjpegProxy:
        case CameraType.RtspProxy:
          if (!this.activeCamera.connection) return;
          if (this.activeCamera.connection.proxyIPAddress == undefined || this.activeCamera.connection.proxyIPAddress == '' ||
              this.activeCamera.connection.proxyPort == undefined || this.activeCamera.connection.proxyPort == 0) return;
          this.proxyURL = `${this.communicationsService.settings!.useWss ? 'wss' : 'ws'}://${this.activeCamera.connection.proxyIPAddress}:${this.activeCamera.connection.proxyPort}`;
          this.cameraSelectSocket.send(this.activeCamera.name);
          break;
        case CameraType.PanelMjpeg:
          if (!this.activeCamera.connection) return;
          const ip = this.activeCamera.connection.ipAddress == '' ? this.activeCamera.connection.remoteIPAddress : this.activeCamera.connection.ipAddress;
          const port = this.activeCamera.connection.ipAddress == '' ? this.activeCamera.connection.remotePort : this.activeCamera.connection.port;
          if (ip == undefined || ip == '' ||
              port == undefined || port == 0) return;

          let imgURL: string = `http://${ip}:${port}/${this.activeCamera.uriPostfix}`;
          //   if (!this.config.isLocal && this.activeCamera.connection.remoteIpAddress) imgURL = `http://${this.activeCamera.connection.remoteIpAddress}:${this.activeCamera.connection.remotePort}/${this.activeCamera.postfix}`;
          this.imageWindow.src = imgURL;
          break;
      }
    }
  }
}
