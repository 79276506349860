import {
  AfterViewInit,
  Component,
  ComponentRef, EmbeddedViewRef, EventEmitter, Input,
  OnDestroy,
  OnInit, Output, output, QueryList,
  ViewChild, ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {Subscription} from "rxjs";
import {PageService} from "../../services/interface/page.service";
import {logger} from "../../services/support/logger";

export interface PageConfig {
  visible: boolean,
  component: any;
  inputs: { [key: string]: any };
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnDestroy, AfterViewInit {
  @Input() hideDots: boolean = false;
  private _activePage: number = 0;
  @Input()
  set activePage(value: number) {
    this._activePage = value;
    this.navigateToPage(value);
  }
  private _pageConfigs: PageConfig[] | undefined;
  get pageConfigs(): PageConfig[] | undefined {
    return this._pageConfigs;
  }
  @Input() set pageConfigs(value: PageConfig[] | undefined) {
    this._pageConfigs = value;
    this.updateDisplayableIndices();
    if (this.visible) this.loadComponents();
  }
  
  @Output() activePageChange : EventEmitter<number> = new EventEmitter<number>();
  
  constructor(protected pageService: PageService) {}

  @ViewChild('pageContainer', { read: ViewContainerRef }) container: ViewContainerRef | undefined;

  protected displayableIndices: number[] = [];
  protected currentPageIndex: number = 0;
  
  private componentReferences: ComponentRef<any>[] = [];
  private configSubscription: Subscription | undefined;
  private touchStartPosition: { x: number, y: number } | null = null;
  private visible: boolean = false;
  

  public ngOnDestroy(): void {
    this.clearComponentReferences();
    this.configSubscription?.unsubscribe();
  }
  
  public ngAfterViewInit(): void {
    this.visible = true;
    this.loadComponents();
    this.navigateToPage(this._activePage);
  }

  protected nextPage(): void {
    const currentIndex = this.displayableIndices.indexOf(this.currentPageIndex);
    if (currentIndex + 1 < this.displayableIndices.length)
      this.navigateToPage(currentIndex + 1);
  }

  protected prevPage(): void {
    const currentIndex = this.displayableIndices.indexOf(this.currentPageIndex);
    if (currentIndex - 1 >= 0)
      this.navigateToPage(currentIndex - 1);
  }

  public navigateToPage(index: number): void {
    if (index === -1) return;
    this.currentPageIndex = this.displayableIndices[index];
    this.activePageChange?.emit(index);
    const component = this.componentReferences[index];
    if (component?.hostView === undefined) return;
    const hostElement: HTMLElement = (component.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    hostElement.scrollIntoView({behavior: 'smooth', block: "start"});
  }

  protected handleTouchStart(event: TouchEvent) : void {
    this.touchStartPosition = event.target instanceof HTMLElement && event.target.classList[0] === "slider" ? null
      : {
      x: event.changedTouches[0].screenX,
      y: event.changedTouches[0].screenY
    }
  }

  protected handleTouchEnd(event: TouchEvent) : void {
    if (this.touchStartPosition === null) return;
    this.handleSwipeGesture(event.changedTouches[0].screenX, event.changedTouches[0].screenY);
  }

  protected handleSwipeGesture(touchEndX: number, touchEndY: number) : void {
    if (this.touchStartPosition === null) return;
    if (this.touchStartPosition.x - touchEndX > 100) {
      // Swipe Left, Next Page
      this.nextPage();
    } else if (this.touchStartPosition.x - touchEndX < -100) {
      // Swipe Right, Previous Page
      this.prevPage();
    }
  }
  
  private clearComponentReferences(): void {
    this.container?.clear();
    this.componentReferences.forEach(componentRef => {
      componentRef.destroy();
    })
    this.componentReferences = [];
  }

  private updateDisplayableIndices(): void {
    if (this._pageConfigs === undefined) return;
    this.clearComponentReferences();
    this.displayableIndices = this._pageConfigs
        .map((config, index) => ({ index, display: config.visible }))
        .filter(config => config.display)
        .map(config => config.index);
    this.currentPageIndex = this.displayableIndices.includes(this.currentPageIndex) ? this.currentPageIndex : this.displayableIndices[0] || 0;
  }

  private loadComponents(): void {
    if (this.container === undefined || this._pageConfigs === undefined) return;
    for (let i: number = 0; i < this.displayableIndices.length; i++) {
      const pageConfigIndex = this.displayableIndices[i];
      const pageConfig: PageConfig = this._pageConfigs![pageConfigIndex];
      const component : ComponentRef<any> | undefined = this.container.createComponent(pageConfig.component);
      if (component === undefined) return;

      const hostElement: HTMLElement = (component.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      hostElement.style.flex = '0 0 auto'; // Prevents flex items from growing or shrinking
      hostElement.style.width = '100%'; // Assuming you want each page to be the full width of the container
      hostElement.style.height = '100%'; // Assuming you want each page to be the full height of the container
      hostElement.style.scrollSnapAlign = 'start'; // Optional: Aligns to the start of the container when scrolling
      hostElement.style.display = 'block'; // Makes sure the page occupies its own space
      
      if (i == this.currentPageIndex)
        hostElement.scrollIntoView({ behavior: 'smooth', block: "start"});

      this.componentReferences.push(component);
      if (pageConfig.inputs) {
        Object.keys(pageConfig.inputs).forEach(key => {
          component.instance[key] = pageConfig.inputs[key];
        });
      }
    }
    this.navigateToPage(0);
  }
}
