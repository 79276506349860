export class helpers {
  public static findParentWithClass(element: HTMLElement, className: string): HTMLElement | undefined {
    let parent: HTMLElement | null = element.parentElement;
    while (parent !== null) {
      if (parent.classList.contains(className)) {
        return parent;
      }
      parent = parent.parentElement;
    }
    return undefined;
  }

  public static getAttribute(attributeName: string, target: EventTarget | null): string | undefined {
    let el: HTMLElement | null = <HTMLElement>target;
    let command: string | undefined = undefined;
    while (command == null || command == undefined) {
      if (el == null) break;
      command = el.dataset[attributeName];
      if (command == undefined) el = el.parentElement;
    }
    return command;
  }

  public static sort<T extends { [key: string]: any }>(a: T, b: T, propertyName: string): number {
    if (a[propertyName] === undefined && b[propertyName] === undefined) return 0;
    if (a[propertyName] !== undefined && b[propertyName] === undefined) return -1;
    if (a[propertyName] === undefined && b[propertyName] !== undefined) return 1;
    if (a[propertyName]! < b[propertyName]!) return -1;
    if (a[propertyName]! > b[propertyName]!) return 1;
    return 0;
  }
}
