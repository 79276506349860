import { logger } from "src/app/services/support/logger";
import {Controller} from "../../services/configuration/controller";
import {Input, Directive} from "@angular/core";

@Directive()
export abstract class CardBase<TController extends Controller> {
  private _controller: TController | undefined;
  get controller(): TController | undefined {
    return this._controller;
  }

  @Input()
  set controller(value: TController | undefined) {
    if (this._controller && this._controller !== value) {
      this._controller.unsubscribeFromStatusMessages();
    }
    if (value === undefined) {
      this._controller = value;
      return;
    }
    value.pollAsync().then(async (): Promise<void> => {
      this._controller = value;
      this.controllerReady();
    });
  }

  protected controllerReady(): void {
  }
}