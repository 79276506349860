import {APP_BASE_HREF, NgOptimizedImage} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import {LoginComponent} from './pages/login/login.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {MainComponent} from './pages/main/main.component';
import {ProperCasePipe} from './support/pipes/proper-case.pipe';
import {ScreenSaverComponent} from './pages/screen-saver/screen-saver.component';
import {SliderComponent} from './components/slider/slider.component';
import {SliderMiniComponent} from './components/slider-mini/slider-mini.component';
import {IntercomComponent} from './pages/intercom/intercom.component';
import {CameraDisplayComponent} from './components/camera-display/camera-display.component';
import {CamerasComponent} from './pages/cameras/cameras.component';
import {PoolSpaComponent} from './pages/pool-spa/pool-spa.component';
import {ToggleComponent} from './components/toggle/toggle.component';
import {TempGaugeComponent} from './components/temp-gauge/temp-gauge.component';
import {ClimateComponent} from './pages/climate/climate.component';
import {LightingComponent} from './pages/lighting/lighting.component';
import {SceneListComponent} from "./pages/lighting/scene-list/scene-list.component";
import {LoadListComponent} from "./pages/lighting/load-list/load-list.component";
import {AreaListComponent} from './components/area-list/area-list.component';
import {ColorPickerModule} from "ngx-color-picker";
import {AuxListComponent} from './pages/pool-spa/aux-list/aux-list.component';
import {TempControlsComponent} from './pages/pool-spa/temp-controls/temp-controls.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {DoorLocksComponent} from './pages/door-locks/door-locks.component';
import {LockOptionsPopupComponent} from './pages/door-locks/lock-options-popup/lock-options-popup.component';
import {MediaFavoritesComponent} from './pages/media/media-favorites/media-favorites.component';
import {MediaNavigationComponent} from './pages/media/media-navigation/media-navigation.component';
import {MediaMiscComponent} from './pages/media/media-misc/media-misc.component';
import {MediaTransportsComponent} from './pages/media/media-transports/media-transports.component';
import {MediaAvSettingsComponent} from './pages/media/media-av-settings/media-av-settings.component';
import {MediaNumpadComponent} from './pages/media/media-numpad/media-numpad.component';
import {MediaPlayerCrpcComponent} from './pages/media/media-player-crpc/media-player-crpc.component';
import {PlayerViewComponent} from './pages/media/media-player-crpc/player-view/player-view.component';
import {BrowserViewComponent} from './pages/media/media-player-crpc/browser-view/browser-view.component';
import {ProgressBarComponent} from "./components/progress-bar/progress-bar.component";
import {MediaPlayerCrpcPopupComponent} from "./pages/media/media-player-crpc/popup/media-player-crpc-popup.component";
import {VolumeControlComponent} from "./components/volume-control/volume-control.component";
import {MarqueeComponent} from "./components/marquee/marquee/marquee.component";
import {WeatherCardComponent} from "./components/cards/weather-card/weather-card.component";
import {MediaCardComponent} from "./components/cards/media-card/media-card.component";
import {LightingCardComponent} from "./components/cards/lighting-card/lighting-card.component";
import {ClimateCardComponent} from "./components/cards/climate-card/climate-card.component";
import {DropListComponent} from "./components/drop-list/drop-list.component";
import {MediaComponent} from "./pages/media/media.component";
import {CardRegistryService} from "./services/registries/card.registry.service";
import {ControlType} from "./services/registries/control.type";
import {ControllerContainerCardComponent} from "./components/cards/controller-container-card/controller-container-card.component";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {PowerControlComponent} from "./components/power-control/power-control.component";
import {FormsModule} from "@angular/forms";
import {SleepTimerComponent} from "./components/sleep-timer/sleep-timer.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SpinnerComponent,
    DashboardComponent,
    MainComponent,
    ProperCasePipe,
    ScreenSaverComponent,
    SliderComponent,
    SliderMiniComponent,
    IntercomComponent,
    CameraDisplayComponent,
    CamerasComponent,
    PoolSpaComponent,
    ToggleComponent,
    TempGaugeComponent,
    ClimateComponent,
    LightingComponent,
    SceneListComponent,
    LoadListComponent,
    AreaListComponent,
    AuxListComponent,
    TempControlsComponent,
    PaginationComponent,
    DoorLocksComponent,
    LockOptionsPopupComponent,
    MediaComponent,
    MediaFavoritesComponent,
    MediaNavigationComponent,
    MediaMiscComponent,
    MediaTransportsComponent,
    MediaAvSettingsComponent,
    MediaNumpadComponent,
    MediaPlayerCrpcComponent,
    PlayerViewComponent,
    BrowserViewComponent,
    MediaPlayerCrpcPopupComponent,
    ProgressBarComponent,
    VolumeControlComponent,
    MarqueeComponent,
    WeatherCardComponent,
    MediaCardComponent,
    LightingCardComponent,
    ClimateCardComponent,
    DropListComponent,
    ControllerContainerCardComponent,
    PowerControlComponent,
    SleepTimerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgOptimizedImage,
    ColorPickerModule,
    HttpClientModule,
    ScrollingModule,
    FormsModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocation,
    },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    CardRegistryService.register(ControlType.Climate, ClimateCardComponent);
    CardRegistryService.register(ControlType.Lighting, LightingCardComponent);
    CardRegistryService.register(ControlType.MediaArea, MediaCardComponent);
    CardRegistryService.register(ControlType.Weather, WeatherCardComponent);
  }
}

export function getBaseLocation() {
  let ip = window.location.host;
  if (ip.indexOf(':') > -1) ip = ip.substring(0, ip.indexOf(':'));
  return ip == 'localhost' ? '/' : '/webpanel/';
}
