import {Component} from '@angular/core';
import {PageService} from "../../services/interface/page.service";
import {Camera} from "../../components/camera-display/camera";
import {PageType} from "../../services/interface/pageType";
import {ControlMessage} from "../../services/communications/messaging/controlMessage";
import {CommunicationsService} from "../../services/communications/communications.service";
import {ControlResult} from "../../services/communications/messaging/controlResult";
import {logger} from "../../services/support/logger";
import {PageSystem} from "../pageSystem";
import {CameraController} from "./camera.controller";
import {ConfigurationService} from "../../services/configuration/configuration.service";
import {SystemTypes} from "../../services/support/isystem";

@Component({
  selector: 'app-cameras',
  templateUrl: './cameras.component.html',
  styleUrls: ['./cameras.component.scss']
})
export class CamerasComponent extends PageSystem<CameraController> {
  constructor(communicationsService: CommunicationsService, configurationService: ConfigurationService, pageService: PageService)
  {
    super(communicationsService, configurationService, pageService, SystemTypes.Cameras);
    this.communicationsService.sendMessageAndSubscribe(new ControlMessage(7000, undefined, "intercomGetCamera", {
      'ext': '201'
    }), true, (response => this.handleIntercomCameraResponse(response)));
  }

  public activeCamera: Camera | undefined;
  protected readonly PageType = PageType;

  private handleIntercomCameraResponse(response: ControlResult): void {
    switch (response.result) {
      case "Not Found":
        logger.debug("requested camera does not exist");
        return;
      case "Not Authenticated":
        logger.error("connection isn't authenticated...")
        return;
      case "Invalid extension supplied":
        logger.error("extension doesn't exist in system");
        return;
    }

    const cameraSettings = JSON.parse(response.result);
    //this.activeCamera = Object.assign(new Camera(), cameraSettings);
    this.activeCamera = Camera.Parse(cameraSettings);
  }
}
