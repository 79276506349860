import {Component, Input, OnDestroy} from '@angular/core';
import {CommunicationsService} from "../../services/communications/communications.service";
import {PageService} from "../../services/interface/page.service";
import {IntercomService} from "../../services/communications/intercom.service";
import {helpers} from "../../services/support/helpers";
import {Subscription} from "rxjs";
import {PageType} from "../../services/interface/pageType";

@Component({
  selector: 'app-intercom',
  templateUrl: './intercom.component.html',
  styleUrls: ['./intercom.component.scss']
})
export class IntercomComponent implements OnDestroy {
  constructor(public communicationsService: CommunicationsService, public pageService: PageService, public IntercomService: IntercomService) {
    this._subscriptions.push(
      IntercomService.IncomingCallEvent.subscribe((uri: string) => {
        //this uri should look something like sip:203@192.168.68.169:5060
        this.incomingData = uri;
      })
    );

    //TODO: get current call data in case this is a popup window
  }

  @Input() displayMode: string = 'full';

  incomingData: string = '';
  private _subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }
  public commandPushGet(event: PointerEvent): void {
    const command = helpers.getAttribute("command", event.target);
    if (!command) return;
    this.IntercomService.handleCommand('b', command, true);
  }
  public commandReleaseGet(event: PointerEvent): void {
    const command = helpers.getAttribute("command", event.target);
    if (!command) return;
    if (command == 'close') {
      this.IntercomService.handleCommand('b', "Csig.Hangup", false);
      this.pageService.removePopup(PageType.Intercom);
      return;
      ;
    }
    this.IntercomService.handleCommand('b', command, false);
  }

  public close(): void {
    this.pageService.removePopup(PageType.Intercom);
  }
}
