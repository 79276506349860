import {
  Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-drop-list',
  templateUrl: './drop-list.component.html',
  styleUrls: ['./drop-list.component.scss'],
})
export class DropListComponent implements OnInit, OnDestroy {
  @Input() positionMethod = 1;
  @Input() label = '';
  @Input() itemProperty = '';
  @Input() itemDescription = '';
  @Input() required = true;
  @Input() parentLabel = 'content';
  @Input() allowInput = true;
  @Input() maxHeight = 150;

  private _itemsSource: any[] = [];
  private _selectedItem: any = '';

  @Input()
  set itemSource(value: any[]) {
    this._itemsSource = value ? Object.values(value) : [];
    if (this.init) this.setFilter();
  }

  get itemSource(): any[] {
    return this._itemsSource;
  }

  @Input()
  set selectedItem(value: any) {
    this._selectedItem = value || '';
    if (this.inputElement) {
      this.inputElement.nativeElement.value = this.itemProperty ? value[this.itemProperty] : value;
    }
  }

  get selectedItem(): any {
    return this._selectedItem;
  }

  @Input()
  set close(value: boolean) {
    if (value) {
      this.dropDownVisibility('false');
      this.ignoreToggle = true;
      setTimeout(() => this.ignoreToggle = false, 300);
    }
  }

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedItemChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('control') userControl?: ElementRef;
  @ViewChild('title') title?: ElementRef;
  @ViewChild('input') inputElement?: ElementRef;
  @ViewChild('nonInput') nonInputElement?: ElementRef;
  @ViewChild('list') listElement?: ElementRef;

  constructor() {
  }

  protected filteredSource: any[] = [];
  protected listIndex: number = -1;
  protected error: boolean = false;

  private ignoreToggle = false;
  private init = false;
  private subWindowResize?: Subscription;
  private subSettingsScreenSaver?: Subscription;
  private _visible = false;
  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
    if (value) {
      this.redraw();
      if (this.allowInput) {
        this.inputElement?.nativeElement.focus();
      } else {
        this.nonInputElement?.nativeElement.focus();
      }
    } else {
      this.inputElement?.nativeElement.blur();
      if (this.listElement) this.listElement.nativeElement.style.height = '0px';
    }
  }

  public ngOnInit(): void {
    this.init = true;
    this.setFilter();
  }

  public ngOnDestroy(): void {
    this.subWindowResize?.unsubscribe();
    this.subSettingsScreenSaver?.unsubscribe();
  }


  protected dropDownVisibility(method: string): void {
    if (!this.ignoreToggle) {
      if (this.itemSource.length <= 1) return;
      if (method == 'toggle') this.visible = !this.visible;
      else if (method == 'true') this.visible = true;
      else if (method == 'false') this.visible = false;
    }
  }


  private setFilter(): void {
    if (!this.itemSource) return;
    const value = this.inputElement ? this.inputElement.nativeElement.value.toLowerCase() : '';
    this.filteredSource = this.itemSource.filter(item =>
      this.itemProperty ? item[this.itemProperty].toLowerCase().includes(value) : item.toLowerCase().includes(value)
    );
  }

  protected filterSelect(filterItem: any): void {
    this.visible = false;
    if (filterItem !== this.selectedItem) {
      this.selectedItem = filterItem;
      this.change.emit(filterItem);
      this.selectedItemChange.emit(filterItem);
    }
  }

  protected inputChange(): void {
    this.setFilter();
    setTimeout(() => this.redraw(), 10);
  }

  protected inputClear(): void {
    if (this.inputElement) this.inputElement.nativeElement.value = '';
    this.change.emit('clear');
    this.selectedItemChange.emit('clear');
    this.close = true;
  }

  protected inputKeyDown(e: KeyboardEvent): void {
    if (this.itemSource.length <= 1) return;
    switch (e.key) {
      case 'ArrowUp':
        this.listIndex = this.listIndex - 1 < 0 ? this.filteredSource.length - 1 : this.listIndex - 1;
        this.updateListOnKey();
        e.preventDefault();
        break;
      case 'ArrowDown':
        this.listIndex = (this.listIndex + 1) % this.filteredSource.length;
        this.updateListOnKey();
        e.preventDefault();
        break;
      case 'Enter':
        this.filterSelect(this.filteredSource[this.listIndex]);
        break;
      case 'Escape':
        this.visible = false;
        break;
      case 'Backspace':
      case 'Delete':
        this.setFilter();
        setTimeout(() => this.redraw(), 10);
    }
  }

  private updateListOnKey(): void {
    if (this.visible && this.listElement) {
      const listNode = this.listElement.nativeElement.childNodes[0];
      const currentItem = listNode.childNodes[this.listIndex];
      const elTop = currentItem.offsetTop;
      const elHeight = currentItem.clientHeight;
      if (elTop < listNode.scrollTop) {
        listNode.scrollTop = elTop;
      } else if (elTop + elHeight > listNode.clientHeight + listNode.scrollTop) {
        listNode.scrollTop = elTop - listNode.clientHeight + elHeight;
      }
    }
  }

  private redraw(): void {
    if (!this.title || !this.userControl || !this.listElement) return;
    const titleRect = this.title.nativeElement.getBoundingClientRect();
    const top = titleRect.top + titleRect.height;
    this.maxHeight = window.innerHeight - top - 8;

    if (this.filteredSource.length > 0) {
      const item = this.listElement.nativeElement.childNodes[0].childNodes[0];
      const itemHeight = item.scrollHeight;
      const itemsHeight: number = this.filteredSource.length * itemHeight + 2;
      const height: number = itemsHeight > this.maxHeight ? this.maxHeight : itemsHeight;
      const maxWidth = item.clientWidth;
      let width = this.title.nativeElement.clientWidth > maxWidth ? this.title.nativeElement.clientWidth : maxWidth;
      if (width > window.innerWidth) width = window.innerWidth - 16;
      if (this.listElement.nativeElement.getClientRects()[0].left + width > window.innerWidth) {
        this.listElement.nativeElement.style.transform = `translateX(${this.title.nativeElement.clientWidth - width}px)`;
      }

      if (this.positionMethod === 1) {
        this.listElement.nativeElement.style.top = `${top}px`;
      } else {
        this.listElement.nativeElement.style.top = `${this.userControl.nativeElement.offsetTop + this.userControl.nativeElement.offsetHeight}px`;
      }
      this.listElement.nativeElement.style.left = `${this.title.nativeElement.getBoundingClientRect().left}px`;
      this.listElement.nativeElement.style.width = `${width}px`;
      this.listElement.nativeElement.style.height = `${height}px`;
    }
  }

  @HostListener('document:click', ['$event'])
  public activity(event: MouseEvent): void {
    if (!this.visible) return;
    //let node: HTMLElement = event.target as HTMLElement;
    const path: EventTarget[] = event.composedPath ? event.composedPath() : [];
    const inComponent: boolean = path.some((n: EventTarget) => (n as HTMLElement).classList?.contains('userControl'));

    if (!inComponent) {
      this.close = true;
    }
  }
}
