import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, AfterViewInit {

  private _level: number = 0;
  @Input()
  set level(value: number) {
    if (this.userInteracting) return;
    this._level = value;
    this.scaleLevel(value);
    this.updateRender();
  }

  get level(): number {
    return this._level;
  }

  @Input() settable: boolean = true;
  @Input() minLevel: number = 0;
  @Input() maxLevel: number = 65535;
  @Input() mini: boolean = false;
  @Output() levelChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('fill') fill: ElementRef | undefined;
  @ViewChild('bg') bg: ElementRef | undefined;
  @ViewChild('bubble') bubble: ElementRef | undefined;
  @ViewChild('label') label: ElementRef | undefined;
  @ViewChild('angledLine') angledLine: ElementRef | undefined;
  @ViewChild('flatLine') flatLine: ElementRef | undefined;
  @ViewChild('container') componentContainer: ElementRef | undefined;

  private bubbleSize: number = 26;
  private bubbleBuffer: number = (this.bubbleSize / 2) + 4;
  private userInteracting: boolean = false;

  levelScaled: number = 0;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.scaleLevel(this.level);
    this.updateRender();
  }

  protected updateSlider(event: Event): void {
    const level = parseInt((event.target as HTMLInputElement).value);
    this.scaleLevel(level);
    this.updateRender();
    this.levelChange.emit(level);
  }

  protected sliderPointerEvent(event: PointerEvent): void {
    this.userInteracting = event.buttons > 0;
    if (!this.userInteracting) return;

    //event.stopPropagation();
  }

  protected sliderChangeEvent(event: Event): void {
    this.userInteracting = false;
  }

  protected sliderPreventScroll(event: WheelEvent): void {
    event.stopPropagation();
  }

  private updateRender(): void {
    if (!this.fill || !this.bg || !this.bubble || !this.angledLine || !this.flatLine || !this.label || !this.componentContainer) return;

    const requiredHeight: number = 50; // Height of angled line plus label. Adjust as necessary.
    const requiredWidth: number = 70 + this.bubbleSize; //Width of the angled line and line, then offset by bubble size

    this.renderer.setStyle(this.fill.nativeElement, 'width', `calc(${this.levelScaled}% - ${this.bubbleBuffer}px)`);
    this.renderer.setStyle(this.bg.nativeElement, 'left', `calc(${this.levelScaled}% + ${this.bubbleBuffer}px)`);
    this.renderer.setStyle(this.bubble.nativeElement, 'left', `${this.levelScaled}%`);

    const bubbleRect = this.bubble.nativeElement.getBoundingClientRect();
    const componentContainer = this.componentContainer.nativeElement;
    const containerRect = componentContainer.getBoundingClientRect();

    let angledLineLeft = `calc(${this.levelScaled}% + ${this.bubbleSize / 2 + 4}px)`;
    let flatLineLeft = `calc(${this.levelScaled}% + ${this.bubbleSize / 2 + 14}px)`;

    if (containerRect.top - requiredHeight < 0) {
      this.renderer.addClass(this.label.nativeElement, 'inverse');
      this.renderer.addClass(this.angledLine.nativeElement, 'inverse');
      this.renderer.addClass(this.flatLine.nativeElement, 'inverse');
    } else {
      this.renderer.removeClass(this.label.nativeElement, 'inverse');
      this.renderer.removeClass(this.angledLine.nativeElement, 'inverse');
      this.renderer.removeClass(this.flatLine.nativeElement, 'inverse');
    }
    if ((bubbleRect.left - containerRect.left) + requiredWidth > componentContainer.clientWidth) {
      this.renderer.addClass(this.angledLine.nativeElement, 'flip-x');
      this.renderer.addClass(this.label.nativeElement, 'flip-x');
      angledLineLeft = `calc(${this.levelScaled}% - ${this.bubbleSize / 2 + 4}px)`;
      flatLineLeft = `calc(${this.levelScaled}% - ${this.bubbleSize / 2 + 60}px)`;
    } else {
      this.renderer.removeClass(this.angledLine.nativeElement, 'flip-x');
      this.renderer.removeClass(this.label.nativeElement, 'flip-x');
    }

    this.renderer.setStyle(this.angledLine.nativeElement, 'left', angledLineLeft);
    this.renderer.setStyle(this.flatLine.nativeElement, 'left', flatLineLeft);
  }

  private scaleLevel(level: number): void {
    if (this.maxLevel === this.minLevel) {
      this.levelScaled = this.minLevel;  // To avoid division by zero
    } else {
      const scaledValue = ((level - this.minLevel) * 100) / (this.maxLevel - this.minLevel);
      this.levelScaled = Math.round(scaledValue);  // Round to the nearest integer
    }
  }
}
