import {EventEmitter, Injectable} from '@angular/core';
import {ISystem, SystemTypes} from "../support/isystem";
import {CrestronService} from "../communications/crestron.service";
import {ControlResult} from "../communications/messaging/controlResult";
import {CommunicationsService} from "../communications/communications.service";
import {IService} from "../serviceManager/IService";
import {ServiceManager} from "../serviceManager/service-manager.service";
import {System} from './system';
import {Area} from "./area";
import {AreaRegistry} from "../registries/area.registry.service";
import {ControllerRegistry} from "../registries/controller.registry.service";
import {ControlMessage} from "../communications/messaging/controlMessage";
import {Controller} from "./controller";
import {logger} from "../support/logger";
import {PageService} from "../interface/page.service";

type ControllerResponse = {
  [key in string]?: string[]; // A mapping from ControlType string to an array of GUID strings
};

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService implements IService {

  constructor(private crestronService: CrestronService) {
  }

  public systems: System[] = []

  public areas: { [key: string]: Area } = {}

  public readonly systemReady: EventEmitter<void> = new EventEmitter<void>();

  public clear(): void {
    this.systems.forEach((system: System): void => {
      system.dispose();
    });
    AreaRegistry.clear();
    ControllerRegistry.clear();
    this.systems = [];

    for (const areaName in this.areas) {
      this.areas[areaName].clear();
    }
    const pageService = ServiceManager.getService('pageService') as PageService;
    if (pageService === undefined) return;
    pageService.reset();
  }

  public async parseControllersAsync(response: ControlResult): Promise<void> {
    const communicationsService = ServiceManager.getService("communicationsService");
    if (communicationsService === undefined || !(communicationsService instanceof CommunicationsService)) {
      return;
    }

    const controllers: ControllerResponse = JSON.parse(response.result);

    if (controllers['Area'] || controllers['MediaArea']) this.systems.push(new System(communicationsService, SystemTypes.Dashboard, []));
    if (controllers['AudioVideoSource'] || controllers['AudioSource'] || controllers['VideoSource'] || controllers['AudioSourceMediaPlayer'] || controllers['VideoSourceMediaPlayer']) {
      const sourceControllers: any[] = [
        ...controllers['AudioVideoSource'] ?? [],
        ...controllers['AudioSource'] ?? [],
        ...controllers['VideoSource'] ?? [],
        ...controllers['AudioSourceMediaPlayer'] ?? [],
        ...controllers['VideoSourceMediaPlayer'] ?? []
      ];
      this.systems.push(new System(communicationsService, SystemTypes.Media, sourceControllers));
    }
    if (controllers['Climate']) this.systems.push(new System(communicationsService, SystemTypes.Climate, controllers['Climate']));
    if (controllers['Lighting'] || controllers['LightingScene']) {
      let lightingControllers: any[] = [];
      if (controllers['Lighting']) lightingControllers.push(...controllers['Lighting']);
      if (controllers['LightingScene']) lightingControllers.push(...controllers['LightingScene']);

      this.systems.push(new System(communicationsService, SystemTypes.Lighting, lightingControllers));
    }
    if (controllers['Shade']) this.systems.push(new System(communicationsService, SystemTypes.Shades, controllers['Shade']));
    if (controllers['Pool']) this.systems.push(new System(communicationsService, SystemTypes.PoolSpa, controllers['Pool']));
    if (controllers['Camera']) this.systems.push(new System(communicationsService, SystemTypes.Cameras, controllers['Camera']));
    if (controllers['Power']) this.systems.push(new System(communicationsService, SystemTypes.Power, controllers['Power']));
    if (controllers['DoorLock']) this.systems.push(new System(communicationsService, SystemTypes.DoorLocks, controllers['DoorLock']));
    if (controllers['Weather']) this.systems.push(new System(communicationsService, SystemTypes.Weather, controllers['Weather']));

    const systemCreationPromises: Promise<void>[] = [];
    //logger.debug(`creating ${this.systems.length} systems`);
    this.systems.forEach((system: System) => {
      const promise = system.createControllersAsync();
      systemCreationPromises.push(promise);

      promise.then(() => {
        //logger.debug(`[ Configuration Service ] Successfully created system ${system.label}`, system);
      }).catch(error => {
        //logger.error(`[ Configuration Service ] Failed to create system ${system.label}`, error);
      });
    });
    Promise.all(systemCreationPromises).then(async () => {
      await this.createAreasAsync(controllers, communicationsService);
      if (this.crestronService.valid) {
        this.systems.push(new System(communicationsService, SystemTypes.Intercom, []));
      }
      this.systemReady.emit();
    }).catch(error => {
      logger.error(`[ Configuration Service ] Failed to create areas after systems`);
    });
  }
  
  private async createAreasAsync(controllers: ControllerResponse, communicationsService: CommunicationsService) : Promise<void> {
    const areaCreationPromises: Promise<void>[] = [];
    if (controllers['Area']) {
      for (const guid of controllers['Area']) {
        const areaCreationPromise = new Promise<void>((resolve, reject) => {
          communicationsService.sendMessageAndSubscribe(
            new ControlMessage(ControlMessage.IncrementId(), guid, "getApiContracts", {}), true, (response: ControlResult) => {
              try {
                const responseObject = JSON.parse(response.result);

                if (responseObject.name == 'Exterior' || responseObject.name == 'Master Suite' || responseObject.name == 'Upstairs' || responseObject.name == 'Downstairs') {
                  const stop = 1;
                }

                if (this.areas[responseObject.name] === undefined)
                  this.areas[responseObject.name] = new Area(communicationsService, responseObject.controlId);

                this.areas[responseObject.name].parseContractResponse(responseObject);
                resolve();
              } catch (error) {
                reject(error);
              }
            }
          );
        });
        areaCreationPromises.push(areaCreationPromise);
      }
    }
    await Promise.all(areaCreationPromises);
    
    for (const areaName in this.areas) {
      const area: Area = this.areas[areaName];
      if (!area.areaContainer) continue;
      area.setAreaParent(this);
    }
  }

  public findAreaByControlId(controlId: string): Area | undefined {
    for (const key in this.areas) {
      if (this.areas.hasOwnProperty(key)) {
        const area = this.areas[key];
        if (area.controlId === controlId) {
          return area;
        }
      }
    }
    return undefined;
  }

  public getAreaControllers<TController extends Controller>(systemType: ISystem): TController[] {
    let areas: TController[] = [];
    for (const areaName in this.areas) {
      const area = this.areas[areaName];
      const controller: TController | undefined = area.controllers.get(systemType) as unknown as TController;
      if (controller === undefined) continue;

      areas.push(controller);
    }

    return areas;
  }
}
