<div class="page cameras" [ngClass]="pageService.animationClass">
    <!-- <app-drop-list style="width: 100%" [label]="'Select a Camera'" (change)="cameraSelect($event)" [itemSource]="cameras" [itemProperty]="'name'"></app-drop-list> -->

    <app-camera-display [activeCamera]="activeCamera" *ngIf="!pageService.activePages.includes(PageType.ScreenSaver)"></app-camera-display>
    <!-- <video #video style="position: absolute; width: 90%; height: 90%" autoplay></video> -->
    <!-- <canvas #canvas style="position: absolute; width: 90%; height: 90%"></canvas> -->
    <!-- <ch5-video aspectratio="16:9" zindex="0" stretch="true" controls="false" show="true" disabled="false" userid="root" password="k1o2o3n4" url="rtsp://32boulderview.mycrestron.com:40544/axis-media/media.amp?streamprofile=Crestron" sourcetype="Network" debug></ch5-video> -->
    <!-- <ch5-video aspectratio="16:9" zindex="0" stretch="true" controls="false" show="true" disabled="false" userid="" password="" url="rtsp://192.168.68.101:8554/dirt" sourcetype="Network" debug></ch5-video> -->

</div>
