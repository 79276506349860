<section class="power-control-component" (click)="powerToggle($event)" [ngClass]="{'expanded': lockScreenVisibility}">
  <button class="small-circle" *ngIf="!lockScreenVisibility">
    <i class="fa-light fa-power-off"></i>
  </button>

  <div class="power-control-content" *ngIf="lockScreenVisibility">
    <div class="power-form" [@slideUpDown]="lockedLoginForm ? 'up' : 'down'">
      <span class="question">Are you sure you want to turn off <span class="accent">{{ pageService.activeMediaArea?.name }}</span> audio & video systems?</span>
      <i class="user-icon fa-light fa-power-off"></i>
      <div class="button-container">
        <button class="button-shut-down" (click)="shutDown()">SHUT DOWN</button>
      </div>
      <app-sleep-timer [area]="pageService.activeMediaArea"
                       *ngIf="pageService.activeMediaArea && pageService.activeMediaArea.sleepTimerEnabled"></app-sleep-timer>
    </div>
  </div>
</section>
