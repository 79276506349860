<div class="scene-list-container">
  <div class="scene-list-item" *ngFor="let scene of scenes; let i = index">
    <div class="scene-title">
      <h3>{{ scene.name | uppercase }}</h3>
      <span class="scene-description">{{ scene.description }}<span class="warning" *ngIf="!scene.interfaces.includes('IPowerFeedback')">WARNING: This is a preset that does not include feedback</span></span>
    </div>
    <div class="scene-control">
      <app-toggle [id]="i" [state]="scene.powerState" (stateChange)="scene.toggle($event)" *ngIf="scene.interfaces.includes('IPowerFeedback')"></app-toggle>
      <button *ngIf="!scene.interfaces.includes('IPowerFeedback')">Turn On</button>
      <button *ngIf="!scene.interfaces.includes('IPowerFeedback')">Turn Off</button>
    </div>
  </div>
</div>
