import { Injectable } from '@angular/core';
import {IService} from "./IService";

@Injectable({
  providedIn: 'root'
})
export class ServiceManager {
  private static services : Map<string, IService> = new Map<string, IService>();

  public static register(serviceType: string, service: IService): void {
    if (this.services.has(serviceType)) {
      throw new Error(`Service of type ${serviceType} is already registered.`);
    }
    this.services.set(serviceType, service);
  }

  public static getService(serviceType: string) : IService | undefined {
    return this.services.get(serviceType) as IService | undefined;
  }
}
