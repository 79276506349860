import {Component, Input} from '@angular/core';
import {PoolSpaCircuitController} from "../pool-spa.circuit.controller";

@Component({
  selector: 'app-aux-list',
  templateUrl: './aux-list.component.html',
  styleUrls: ['./aux-list.component.scss']
})
export class AuxListComponent {
  @Input() activeCircuits: PoolSpaCircuitController[] | undefined;
}
