<section class="page area-list-page climate" [ngClass]="pageService.animationClass">
  <app-area-list [controllers]="controllers" [header]="'CLIMATE AREAS'" [activeController]="activeController" (activeControllerChange)="selectController($event)"></app-area-list>
  <section class="area-list-page-controls climate-controls" *ngIf="activeController">
      <section class="climate-set-points">
        <app-temp-gauge
          [setPoints]="activeController.setPoints"
          [temperature]="activeController.temperature?.value"
        ></app-temp-gauge>
      </section>
      <section class="climate-modes" *ngIf="activeController.availableModes !== undefined && activeController.mode !== undefined">
        <span>MODES:</span>
        <div class="horizontal-tab-bar" [ngStyle]="{ '--active-tab-index': activeController.mode!.index}">
          <button [ngClass]="{'active': i === activeController.mode!.index}" *ngFor="let mode of activeController.availableModes; let i = index" (click)="activeController.setMode(mode)">{{ mode.label }}</button>
        </div>
      </section>
      
      <section class="climate-fan-modes" *ngIf="activeController.availableFanModes !== undefined && activeController.fanMode !== undefined">
        <span>FAN:</span>
        <div class="horizontal-tab-bar" [ngStyle]="{ '--active-tab-index': activeController.fanMode!.index}">
          <button [ngClass]="{'active': i === activeController.fanMode!.index}" *ngFor="let mode of activeController.availableFanModes; let i = index" (click)="activeController.setFanMode(mode)">{{ mode.label }}</button>
        </div>
      </section>

      <section class="climate-humidity-modes" *ngIf="activeController.availableHumidityModes !== undefined && activeController.humidityMode !== undefined">
        <span>HUMIDITY:</span>
        <div class="horizontal-tab-bar" [ngStyle]="{ '--active-tab-index': activeController.humidityMode!.index}">
          <button [ngClass]="{'active': i === activeController.humidityMode!.index}" *ngFor="let mode of activeController.availableHumidityModes; let i = index" (click)="activeController.setHumidityMode(mode)">{{ mode.label }}</button>
        </div>
      </section>

      <section class="climate-floor-modes" *ngIf="activeController.availableFloorModes !== undefined && activeController.floorMode !== undefined">
        <span>FLOOR:</span>
        <div class="horizontal-tab-bar" [ngStyle]="{ '--active-tab-index': activeController.floorMode!.index}">
          <button [ngClass]="{'active': i === activeController.floorMode!.index}" *ngFor="let mode of activeController.availableFloorModes; let i = index" (click)="activeController.setFloorMode(mode)">{{ mode.label }}</button>
        </div>
      </section>
    </section>
</section>