import {EventEmitter, Injectable} from '@angular/core';
import {logger} from "./logger";
import {IService} from "../serviceManager/IService";

@Injectable({
  providedIn: 'root'
})
export class LoggingService implements IService {

  constructor() {
    let myConsole: any = {};

    // Capture console.error
    myConsole.defaultError = console.error.bind(console);
    myConsole.errors = [];
    myConsole.newerror = new EventEmitter<any>();

    console.error = function () {
      // Default & console.error()
      if (arguments.length == 2 && arguments[1].code === -100) return;
      myConsole.defaultError.apply(console, arguments);
      // New & array data
      myConsole.errors.push(Array.from(arguments));
      myConsole.newerror.emit(arguments);
    };

    myConsole.newerror.subscribe((args: any) => {
      this.debugMessage(args[1]);
    });

    // Capture console.log
    myConsole.defaultLog = console.log.bind(console);
    myConsole.logs = [];
    myConsole.newlog = new EventEmitter<any>();

    console.log = function () {
      // Default & console.log()
      myConsole.defaultLog.apply(console, arguments);
      // New & array data
      myConsole.logs.push(Array.from(arguments));
      myConsole.newlog.emit(arguments);
    };

    myConsole.newlog.subscribe((args: any) => {
      this.debugMessage(args[0]);  // This can be changed based on how you want to handle the log messages.
    });
  }

  public logs: string[] = [];

  public debugMessage(message: string) {
    const date = new Date(Date.now());
    let tempHrs = date.getHours();
    const flag = tempHrs > 12 ? 'PM' : 'AM';
    tempHrs = tempHrs == 0 ? 12 : tempHrs;
    tempHrs = tempHrs > 12 ? tempHrs - 12 : tempHrs;
    const dt = `${tempHrs}:${this.zeroPadding(date.getMinutes(), 2)}:${this.zeroPadding(date.getSeconds(), 2)}`;

    if (this.logs.length > 100) this.logs.splice(100, 1);
    this.logs.splice(0, 0, `${dt} - ${message}`);
    //CrComLib.publishEvent('s', serverID.Debug, `start[${message}]end`);
  }

  private zeroPadding(num: number, digit: number) {
    let zero = '';
    for (let i = 0; i < digit; i++) {
      zero += '0';
    }
    return (zero + num).slice(-digit);
  }
}
