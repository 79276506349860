import {CommunicationsService} from "../../services/communications/communications.service";
import {ControllerRegistry} from "../../services/registries/controller.registry.service";
import {AreaController} from "../../services/configuration/area.controller";
import {DoorLocksController} from "./door-locks.controller";
import {ControlType} from "../../services/registries/control.type";

export class DoorLocksAreaController extends AreaController {
  constructor(communicationsService: CommunicationsService, controlId: string, controllerIds: string[], controlType: ControlType) {
    super(communicationsService, controlId, controlType)
  }
  
  public loads: DoorLocksController[] = [];

  public override processControllerIds(): void {
    this.controllerIds.forEach((controllerId: string) => {
      const controller = ControllerRegistry.getControllerById(controllerId);
      if (controller instanceof DoorLocksController && !this.loads.includes(controller))
        this.loads.push(controller)
    });
  }
  public override async pollAsync(): Promise<void> {
    await super.pollAsync();
    await Promise.all(this.loads.map(load => load.pollAsync()));
  }

  public override unsubscribeFromStatusMessages(): void {
    super.unsubscribeFromStatusMessages();
    this.loads.forEach(load => load.unsubscribeFromStatusMessages());
  }
  
  private activeSourcesUpdate() {
    const stop = 1;
  }
}