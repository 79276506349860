import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {MediaCrpcPlayerController} from "../media.crpc.player.controller";

@Component({
  selector: 'app-browser-view',
  templateUrl: './browser-view.component.html',
  styleUrls: ['./browser-view.component.scss']
})
export class BrowserViewComponent {
  constructor() {
  }

  @Input() source: MediaCrpcPlayerController | undefined;

  imageLoaded: boolean = false;
  
  protected logError(_: ErrorEvent): void {
    this.imageLoaded = false;
  }
  
  protected logLoad(_: Event): void {
    this.imageLoaded = true;
  }
}
