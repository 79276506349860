<section class="card" *ngIf="controller">
  <div>
    <h5 class="card-type">CLIMATE</h5>
    <span class="card-title">{{ controller!.name }}</span>
  </div>
  <section class="temperature">
    <div class="readout" *ngIf="controller!.temperature?.value">
      {{ controller!.temperature?.value }}<span class="fade-in-out">º</span>F
    </div>
  </section>
  <app-drop-list [label]="'Mode'"
                 (change)="controller!.setMode($event)"
                 [itemSource]="controller!.availableModes" [itemProperty]="'label'"
                 [selectedItem]="controller!.mode" [allowInput]="false"
                 *ngIf="controller!.availableModes !== undefined && controller!.mode !== undefined"
  />
  <app-drop-list [label]="'Humidity Mode'"
                 (change)="controller!.setHumidityMode($event)"
                 [itemSource]="controller!.availableHumidityModes" [itemProperty]="'label'"
                 [selectedItem]="controller!.humidityMode" [allowInput]="false"
                 *ngIf="controller!.availableHumidityModes !== undefined && controller!.humidityMode !== undefined"
  />
  <app-drop-list [label]="'Floor Mode'"
                 (change)="controller!.setFloorMode($event)"
                 [itemSource]="controller!.availableFloorModes" [itemProperty]="'label'"
                 [selectedItem]="controller!.floorMode" [allowInput]="false"
                 *ngIf="controller!.availableFloorModes !== undefined && controller!.floorMode !== undefined"
  />
  <app-drop-list [label]="'Fan Mode'"
                 (change)="controller!.setFanMode($event)"
                 [itemSource]="controller!.availableFanModes" [itemProperty]="'label'"
                 [selectedItem]="controller!.fanMode" [allowInput]="false"
                 *ngIf="controller!.availableFanModes !== undefined && controller!.fanMode !== undefined"
  />
  <div class="drop-blank" *ngIf="controller?.availableFanModes === undefined">
    
  </div>
</section>