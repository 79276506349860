<section class="gauge-container">
    <div class="temperature">
        <div class="notches" *ngIf="setPoints && setPoints.length > 0">
            <div class="notch" *ngFor="let notch of notchArray" [style.transform]="notch.rotate" [attr.data-value]="notch.degree">
                <ng-container *ngFor="let setPoint of setPoints">
                    <div *ngIf="notch.degree == setPoint.value" [ngClass]="getIndicatorClasses(setPoint)">
                        <label class="set-point-label">{{ setPoint.value }}º</label>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="readout">
            <div class="readout" *ngIf="temperature !== undefined">
                {{ temperature }}<span class="fade-in-out">º</span>F
            </div>
        </div>
    </div>
    <ng-container *ngFor="let setPoint of setPoints">
        <div [class]="getSetPointLocation(setPoint) + '-adjustments'">
            <span class="set-point-label">
                {{ setPoints!.length > 1 ? setPoint.mode.label : ' ' }}
            </span>
            <button class="set-point-up" (pointerdown)="setPointIncrease($event, setPoint)" (pointerup)="setPointIncrease($event, setPoint)"><i class="fa-duotone fa-angles-up"></i></button>
            <label class="set-point-temp" >{{ setPoint.value }}ºF</label>
            <button class="set-point-down" (pointerdown)="setPointDecrease($event, setPoint)" (pointerup)="setPointDecrease($event, setPoint)"><i class="fa-duotone fa-angles-down"></i></button>
        </div>
    </ng-container>
</section>
