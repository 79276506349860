import {EventEmitter, Injectable, Output} from '@angular/core';
import {ConnectionStatus} from "./connection.status";
import {DatabaseService} from "../storage/database.service";
import {ConnectionValid} from './connection.valid';
import {ControlMessage} from "./messaging/controlMessage";
import {ControlResult} from "./messaging/controlResult";
import {logger} from "../support/logger";
import {SubscriptionService} from "./subscription.service";
import {ControlError} from "./messaging/controlError";
import {PressAndReleaseHandler} from "./press.and.release.handler";
import {DictionaryService} from "../storage/dictionary.service";
import {ConfigurationService} from "../configuration/configuration.service";
import {IService} from "../serviceManager/IService";
import {LoginResponse} from "../../pages/login/login.response";
import {SettingsService} from "../storage/settings.service";

@Injectable({
  providedIn: 'root',
})
export class CommunicationsService implements IService {
  constructor(private configurationService: ConfigurationService, private databaseService: DatabaseService, private settingsService: SettingsService, private subscriptionService: SubscriptionService) {
    this.statusMessage = 'Starting Services..';
    this.pressAndReleaseHandler = new PressAndReleaseHandler(this);
    this.settings = {
      authType: "default"
    };

    this.initializeAsync().then();
  }

  private readonly pressAndReleaseHandler: PressAndReleaseHandler;
  private webSocket?: WebSocket;
  private webSocketConnectionRetryTimeout: any | undefined = undefined;
  private appClosing: boolean = false;

  public settings?: {
    url?: string,
    username?: string,
    password?: string,
    useWss?: boolean,
    authType: string
  };

  public statusMessage: string = '';
  public valid: ConnectionValid = ConnectionValid.Unknown;
  public authenticated: boolean = false;
  private _status: ConnectionStatus = ConnectionStatus.Disconnected;
  public get status(): ConnectionStatus {
    return this._status;
  }

  public set status(state: ConnectionStatus) {
    if (this._status == state) return;
    this._status = state;
    this.connectionChanged.emit(state);
  }

  @Output() connectionChanged: EventEmitter<ConnectionStatus> = new EventEmitter<ConnectionStatus>();
  @Output() loginResponse: EventEmitter<LoginResponse> = new EventEmitter<LoginResponse>();

  private allowReconnection: boolean = true;
  private authenticationBusy: boolean = false;

  private async initializeAsync(): Promise<void> {
    await this.getConnectionSettingsAsync();

    this.subscriptionService.subscribe("authentication", (response: ControlMessage | ControlResult): void => {
      this.authenticate(response)
    });

    if (this.settings == undefined || this.settings.authType != 'default' || this.settings.url == undefined) return;

    this.connect(this.settings.url, 'crestron', '', this.settings.useWss ?? false, true);
  }

  public connect(url: string | undefined, username: string | undefined, password: string | undefined, useWss: boolean, allowReconnect: boolean) {
    this.webSocket = new WebSocket(`${useWss ? 'wss' : 'ws'}://${url}:42081/ux`);
    this.settings!.username = username;
    this.settings!.password = password;
    this.settings!.useWss = useWss;
    this.statusMessage = 'Connecting to system';
    this.status = ConnectionStatus.Connecting;

    this.webSocket.onopen = (): void => {
      if (this.webSocketConnectionRetryTimeout)
        clearTimeout(this.webSocketConnectionRetryTimeout);

      this.settings!.url = url;
      this.status = ConnectionStatus.Authenticating;
      this.statusMessage = 'Logging into system';
      this.allowReconnection = true;
      this.updateConnectionSettingsAsync().then();
    };

    this.webSocket.onmessage = (message: MessageEvent): void => {
      //first lets take the message data and deserialize it into a known message format
      const method = ControlMessage.parseControlMessage(message.data);
      if (method instanceof ControlMessage) {
        this.subscriptionService.addMessage(method);
        return;
      }

      const result = ControlResult.parseControlMessage(message.data);
      if (result instanceof ControlResult) {
        this.subscriptionService.addMessage(result);
        return;
      }

      const error = ControlError.parseControlMessage(message.data);
      if (error instanceof ControlError) {
        // TODO: implement error popup system
        return;
      }

      logger.error(`Received unknown data: ${message.data}`);
    }

    this.webSocket.onclose = (event: any): void => {
      this.configurationService.clear();
      if (this.appClosing || !this.allowReconnection) {
        if (event.reason == '') this.statusMessage = 'Failed to find server';
        else this.statusMessage = event.reason;
        setTimeout(() => {
          this.valid = ConnectionValid.False;
          this.status = ConnectionStatus.Disconnected;
        }, 2500);
        return;
      }
      this.valid = ConnectionValid.False;
      this.status = ConnectionStatus.Disconnected;
      this.statusMessage = 'Reconnecting to system';
      this.webSocketConnectionRetryTimeout = setTimeout((): void => {
        logger.debug("retrying connection");
        this.connect(url, username, password, useWss, allowReconnect);
      }, 5000);
    };
  }

  private savedMessageIds: DictionaryService<{ systemId: string, method: string }, number> = new DictionaryService<{
    systemId: string;
    method: string
  }, number>();

  public sendControlMessage(controlId: string, method: string, params: any = {}, buttonState: boolean | undefined = undefined): void {
    const controlKey = {systemId: controlId, method: method};
    let pressMessageId = this.savedMessageIds.tryGet(controlKey);
    if (pressMessageId == undefined) {
      pressMessageId = ControlMessage.IncrementId();
      this.savedMessageIds.add(controlKey, pressMessageId);
    } else {
      this.savedMessageIds.delete(controlKey);
    }
    const message = new ControlMessage(pressMessageId, controlId, method, params);
    if (buttonState !== undefined) {
      params.buttonState = buttonState;
      buttonState ? this.pressMessage(message) : this.releaseMessage(message);
      return;
    }
    this.sendMessage(message);
  }

  // Send a message to the server
  public sendMessage(message: ControlMessage): void {
    if (!this.webSocket || (this.status != ConnectionStatus.Connected && this.status != ConnectionStatus.Authenticating) || !message.method) return;
    if (this.webSocket.readyState != WebSocket.OPEN) return;
    this.webSocket.send(message.toJSON());
  }

  private pressMessage(message: ControlMessage): void {
    this.pressAndReleaseHandler.addMessage(message);
  }

  private releaseMessage(message: ControlMessage): void {
    this.pressAndReleaseHandler.removeMessage(message.messageId);
    this.sendMessage(message);
  }

  public clearPressedMessages(): void {
    this.pressAndReleaseHandler.clearAll((message: ControlMessage) => {
      this.sendMessage(message);
    });
  }

  public sendMessageAndSubscribe(message: ControlMessage, removeAfterCall: boolean, callback: (response: ControlResult) => void): void {
    if (!this.webSocket || (this.status != ConnectionStatus.Connected && this.status != ConnectionStatus.Authenticating)) return;

    this.subscriptionService.subscribe(message.messageId, (response: ControlMessage | ControlResult) => {
      if (response instanceof ControlMessage) {
        logger.error("Somehow a result callback got response of ControlMessage");
        return;
      }
      if (removeAfterCall)
        this.subscriptionService.unsubscribe(message.messageId);

      callback(response);
    });

    this.sendMessage(message);
  }

  public removeSubscription(messageId: number): void {
    this.subscriptionService.unsubscribe(messageId);
  }

  // Clear connection to connect to a different processor
  public resetConnection() {
    this.settings = {
      authType: 'default'
    };
    this.updateConnectionSettingsAsync().then();
    this.allowReconnection = false;
    this.webSocket?.close(1000, "Changing servers");
    this.status = ConnectionStatus.Disconnected;
    this.valid = ConnectionValid.False;
  }

  public logout(): void {
    this.sendMessage(new ControlMessage(ControlMessage.IncrementId(), undefined, "logout", null));
    this.configurationService.clear();
    this.settings!.username = undefined;
    this.settings!.password = undefined;
    this.updateConnectionSettingsAsync().then();
    this.valid = ConnectionValid.False
    this.allowReconnection = false;
    this.webSocket?.close(1000, "User logged out");
  }

  public login(username: string, password: string): void {
    if (this.authenticationBusy) return;
    this.authenticationBusy = true;
    this.settings!.username = username;
    this.settings!.password = password;
    this.sendMessage(new ControlMessage(ControlMessage.IncrementId(), undefined, "login", null));
  }

  private authenticate(response: ControlMessage | ControlResult): void {
    if (response instanceof ControlResult) {
      logger.error("Somehow authenticate got a response of ControlResult");
      return;
    }

    const loginMessage: ControlMessage = new ControlMessage(ControlMessage.IncrementId(), undefined, "authenticate", {
      username: this.settings!.username,
      password: this.settings!.password
    })

    if (response.params["type"] == "default") {
      this.sendMessageAndSubscribe(loginMessage, true, (response: ControlResult) => {
        const result: LoginResponse = JSON.parse(response.result);
        this.authenticated = result.success;
        this.setupControllersAsync(result).then();
      });
      return;
    }

    this.statusMessage = `Authenticating user ${this.settings!.username}`;
    if (response.params["type"] == "none") {
      this.sendMessageAndSubscribe(loginMessage, true, (response: ControlResult) => {
        const result: LoginResponse = JSON.parse(response.result);
        this.statusMessage = result.message;

        if (!result.success) return;
        this.setupControllersAsync(result).then();
      });
    }
  }

  private async setupControllersAsync(result: LoginResponse): Promise<void> {
    this.configurationService.clear();
    const controllerRequestMessage: ControlMessage = new ControlMessage(ControlMessage.IncrementId(), undefined, "getControllers", null);
    this.sendMessageAndSubscribe(controllerRequestMessage, true, (controllersResponse: ControlResult): void => {
      this.configurationService.parseControllersAsync(controllersResponse).then();
    });
    if (result.options) {
      this.settingsService.importSystemOptions(result.options);
    }
    this.authenticationBusy = false;
    this.loginResponse.emit(result);
  }

  private async getConnectionSettingsAsync() {
    try {
      const result = await this.databaseService.getByKeysAsync('connection', ['url', 'username', 'useWss', 'authType']);
      this.valid = ConnectionValid.False;
      this.settings!.url = result['url'];
      this.settings!.username = result['username'];
      // this.settings!.password = result['password'];
      this.settings!.useWss = result['useWss'];
      this.settings!.authType = result['authType'] ?? 'default';
    } catch (error) {
      this.valid = ConnectionValid.False;
      this.statusMessage = '';
    }
  }

  private async updateConnectionSettingsAsync() {
    if (this.settings === undefined) return;
    try {
      const currentSettings = await this.databaseService.getByKeysAsync('connection', ['url', 'username', 'password', 'useWss']);

      const updates: any = {}; // Object to hold potential updates

      // Loop over settings, compare, and prepare updates
      Object.entries(this.settings).forEach(([key, value]) => {
        // @ts-ignore
        if (currentSettings[key] !== value) {
          updates[key] = value;
        }
      });

      // If there are updates, proceed to update the database
      if (Object.keys(updates).length > 0) {
        await Promise.all(Object.entries(updates).map(([key, value]) => {
            if (key == 'password') return;
            this.databaseService.addAllAsync('connection', [key], [value]);
          }
        ));
      }
    } catch (error) {
      console.error("Failed to update settings:", error);
    }
  }
}