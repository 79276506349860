<!--<section class="page area-list-page lighting" [ngClass]="pageService.animationClass">-->
<!--  <app-area-list [controllers]="controllers" [header]="'LIGHTING AREAS'" [activeController]="activeController" (activeControllerChange)="selectController($event)"></app-area-list>-->
<!--  <section class="area-list-page-controls lighting-controls">-->
<!--    <div class="page-navigation">-->
<!--      <button *ngIf="activeController !== undefined && activeController!.scenes.length > 0"-->
<!--              [ngClass]="{ 'active': navigationIndex === 0}"-->
<!--              (click)="controlNavigation(0)">Scenes</button>-->
<!--      <button *ngIf="activeController !== undefined && activeController!.loads.length > 0"-->
<!--              [ngClass]="{ 'active': navigationIndex === 1}"-->
<!--              (click)="controlNavigation(1)">Loads</button>-->
<!--    </div>-->
<!--  </section>-->
<!--</section>-->


<section class="page area-list-page lighting" [ngClass]="pageService.animationClass">
  <app-area-list [controllers]="controllers" [header]="'LIGHTING AREAS'" [activeController]="activeController" (activeControllerChange)="selectController($event)"></app-area-list>
  <section class="area-list-page-controls lighting-controls" *ngIf="settingsService.lightingMode == LightingMode.Standard">
    <div class="page-navigation">
      <button [ngClass]="{ 'active': navigationIndex === 0}" (click)="controlNavigation(0)" *ngIf="activeController?.scenes?.length ?? 0 > 0">Scenes</button>
      <button [ngClass]="{ 'active': navigationIndex === 1}" (click)="controlNavigation(1)" *ngIf="activeController?.loads?.length ?? 0 > 0">Loads</button>
    </div>
    <app-pagination [(activePage)]="navigationIndex" [pageConfigs]="lightingPageConfigs"></app-pagination>
  </section>

  <section class="area-list-page-controls lighting-controls" *ngIf="settingsService.lightingMode == LightingMode.Grouped">
    We need to make this work.
  </section>
</section>
