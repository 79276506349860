import {ControlOption} from "../../services/support/control.option";

export class DoorLocksState {
  static readonly Unknown: ControlOption = { value: 0, label: "Unknown" };
  static readonly Locked: ControlOption = { value: 1, label: "Locked", iconClass: 'fa-regular fa-lock' };
  static readonly Unlocked: ControlOption = { value: 2, label: "Unlocked", iconClass: 'fa-regular fa-lock-open' };
  static readonly Latched: ControlOption = { value: 3, label: "Latched", iconClass: 'fa-solid fa-door-open' };
  static readonly Unlatched: ControlOption = { value: 4, label: "Unlatched", iconClass: 'fa-solid fa-door-open' };
  static readonly Open: ControlOption = { value: 5, label: "Open", iconClass: 'fa-solid fa-door-open' };
  static readonly Closed: ControlOption = { value: 7, label: "Closed", iconClass: 'fa-solid fa-door-closed' };
  
  static toControlOptionArray(): ControlOption[] {
    return Object.values(DoorLocksState);
  }
}
