export class BrowserAction
{
  constructor(label: string) {
    this.label = label;
    this.icon = BrowserAction.icons[label];
  }
  private static readonly icons: Record<string, string> = {
    'Create': 'fa-solid fa-list-check',
    'QuickList': 'fa-solid fa-list-music',
    'BackToTop': 'fa-solid fa-home',
    'Advanced': 'fa-solid fa-sliders-up',
    'Favorites': 'fa-regular fa-heart',
  };
  
  public readonly label: string;
  public readonly icon: string;
}