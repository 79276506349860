import {Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {WeatherController} from "../../../services/weather/weather.controller";
import {logger} from "../../../services/support/logger";
import {IWeatherData} from "../../../services/weather/IWeatherData";
import {SettingsService} from "../../../services/storage/settings.service";
import {CardBase} from "../card.base";


@Component({
  selector: 'app-weather-card',
  templateUrl: './weather-card.component.html',
  styleUrls: ['./weather-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WeatherCardComponent extends CardBase<WeatherController> {
  constructor(private settingService: SettingsService) {
    super();
  }

  private _forecastScroll: ElementRef | undefined;
  get forecastScroll(): ElementRef | undefined {
    return this._forecastScroll;
  }

  @ViewChild('forecastScroll') set forecastScroll(value: ElementRef | undefined) {
    if (this._forecastScroll == value) return;
    this._forecastScroll = value;
    // setTimeout(() => {
    //   this.scrollToCurrentTime();
    // }, 500);
  }

  private scrollToCurrentTime(): void {
    if (this.forecastScroll === undefined) {
      logger.error('failed to scroll to the current time due to the forecastScroll container being undefined');
      return;
    }
    const forecastScrollElement = this.forecastScroll.nativeElement;
    const hourlyWeatherElements = this.forecastScroll.nativeElement.querySelectorAll('.hourly-weather') as NodeListOf<HTMLElement>;
    //const compareTo = new Date().toLocaleTimeString(this.settingService.locale, this.timeLabelOptions);
    const compareTo = 'Now';

    for (let i = 0; i < hourlyWeatherElements.length; i++) {
      const element: HTMLElement = hourlyWeatherElements[i];
      const text = element.querySelector('.time')?.textContent;
      if (text === null || text === undefined || text == '') return;
      if (text != compareTo) continue;
      forecastScrollElement.scrollTo({
        left: element.offsetLeft - 16,
        behavior: 'smooth'
      });
      //element.scrollIntoView({behavior: 'smooth', block: 'start'});
      break;
    }
  }

  private readonly timeLabelOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    hour12: true,
  };

  private readonly dayLabelOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long'
  }

  protected getAirTemperature(weatherData: IWeatherData): number | undefined {
    for (let i = 0; i < weatherData.temperatures.length; i++) {
      if (weatherData.temperatures[i].type.value == 10)
        return weatherData.temperatures[i].temperature.value;
    }

    return undefined;
  }

  protected getHighTemperature(weatherData: IWeatherData): number | undefined {
    for (let i = 0; i < weatherData.temperatures.length; i++) {
      if (weatherData.temperatures[i].type.value == 13)
        return weatherData.temperatures[i].temperature.value;
    }

    return undefined;
  }

  protected getLowTemperature(weatherData: IWeatherData): number | undefined {
    for (let i = 0; i < weatherData.temperatures.length; i++) {
      if (weatherData.temperatures[i].type.value == 12)
        return weatherData.temperatures[i].temperature.value;
    }

    return undefined;
  }

  protected getFeelsLikeTemperature(weatherData: IWeatherData): number | undefined {
    for (let i = 0; i < weatherData.temperatures.length; i++) {
      if (weatherData.temperatures[i].type.value == 11)
        return weatherData.temperatures[i].temperature.value;
    }

    return undefined;
  }

  protected getTimeLabel(updatedAt: string): string {
    const date = new Date(updatedAt);
    const compareTo = new Date().toLocaleTimeString(this.settingService.locale, this.timeLabelOptions);
    const label = date.toLocaleTimeString(this.settingService.locale, this.timeLabelOptions);

    return label == compareTo ? 'Now' : label;
  }

  protected getDayLabel(updatedAt: string): string {
    const today = new Date();
    const date = new Date(updatedAt);
    if (date.toDateString() === today.toDateString()) return "Today";

    return date.toLocaleDateString(this.settingService.locale, this.dayLabelOptions);
  }
}
