export class jsonRpcTx {
  readonly jsonrpc: string = '2.0';
  
  constructor(id: number, method: string, params: {} | undefined) {
    this.id = id;
    this.method = method;
    this.params = params;
  }
  
  method: string;
  id: number;
  params: {} | undefined;
}


