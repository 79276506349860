export enum ControlType
{
    Unknown = 0,
    AudioSwitcher,
    VideoSwitcher,
    AudioVideoSwitcher,
    Display,
    PreAmplifier,
    Receiver,
    Amplifier,
    AudioSource,
    VideoSource,
    AudioVideoSource,
    AudioSourceMediaPlayer,
    VideoSourceMediaPlayer,
    AudioVideoSourceMediaPlayer,
    Lighting,
    LightingScene,
    Shade,
    ShadeScene,
    Area,
    AreaScene,
    Climate,
    Camera,
    Power,
    Pool,
    PoolBody,
    PoolCircuit,
    Support,
    PreAmpOutput,
    AmplifiedOutput,
    SwitcherOutput,
    MediaArea,
    Outlet,
    Intercom,
    DoorLock,
    VideoScaler,
    Weather
}