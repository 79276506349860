import {Component, Input, OnDestroy} from '@angular/core';
import {MediaCrpcPlayerController} from "../media.crpc.player.controller";

@Component({
  selector: 'app-player-view',
  templateUrl: './player-view.component.html',
  styleUrls: ['./player-view.component.scss']
})
export class PlayerViewComponent {
  constructor() {}

  @Input() source: MediaCrpcPlayerController | undefined;
  @Input() mini: boolean = false;

  protected toTimeString(time: number | undefined): string {
    if (time === undefined) return '';
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${this.zeroPadding(minutes, 2)}:${this.zeroPadding(seconds, 2)}`;
  }

  private zeroPadding(num: number, digit: number): string {
    let zero: string = '';
    for (let i: number = 0; i < digit; i++) {
      zero += '0';
    }
    return (zero + num).slice(-digit);
  }
}
