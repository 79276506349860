import {Component, OnDestroy, ViewChild} from '@angular/core';
import {PageSystem} from "../pageSystem";
import {CommunicationsService} from "../../services/communications/communications.service";
import {PageService} from "../../services/interface/page.service";
import {LightingAreaController} from "./lighting.area.controller";
import {ConfigurationService} from "../../services/configuration/configuration.service";
import {SystemTypes} from "../../services/support/isystem";
import {PageConfig, PaginationComponent} from "../../components/pagination/pagination.component";
import {SceneListComponent} from "./scene-list/scene-list.component";
import {LoadListComponent} from "./load-list/load-list.component";
import {SettingsService} from "../../services/storage/settings.service";
import {LightingMode} from "../../services/storage/lightingMode";


@Component({
  selector: 'app-lighting',
  templateUrl: './lighting.component.html',
  styleUrls: ['./lighting.component.scss']
})
export class LightingComponent extends PageSystem<LightingAreaController> implements OnDestroy {
  constructor(communicationsService: CommunicationsService, configurationService: ConfigurationService, pageService: PageService, protected settingsService: SettingsService) {
    super(communicationsService, configurationService, pageService, SystemTypes.Lighting);
  }
  
  protected lightingPageConfigs: PageConfig[] = [ ];

  protected controlNavigation(index: number): void {
    this.navigationIndex = index;
  }
    
  protected override processNewController(): void {
    if (this.activeController === undefined) return;
    if (this.activeController.scenes.length > 0)
      this.navigationIndex = 0;
    else if (this.activeController.loads.length > 0)
      this.navigationIndex = 1;
    
    this.updatePageConfigs();
  }
  
  private updatePageConfigs() : void {
    if (this.activeController === undefined) return;

    this.lightingPageConfigs = [
      {
        visible: this.activeController.scenes.length > 0,
        component: SceneListComponent,
        inputs: {
          scenes: this.activeController.scenes
        }
      },
      {
        visible: this.activeController.loads.length > 0,
        component: LoadListComponent,
        inputs: {
          loads: this.activeController.loads
        }
      }
    ];
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  protected readonly LightingMode = LightingMode;
}
