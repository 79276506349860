import {Component, Input, OnDestroy} from '@angular/core';
import {CrpcService} from "../../../services/media/crpc.service";
import {MediaCrpcPlayerController} from "./media.crpc.player.controller";
import {PageService} from "../../../services/interface/page.service";
import {fadeAnimation} from "../../../services/support/animations";

@Component({
  selector: 'app-media-player-crpc',
  templateUrl: './media-player-crpc.component.html',
  styleUrls: ['./media-player-crpc.component.scss'],
  animations: [fadeAnimation]
})
export class MediaPlayerCrpcComponent implements OnDestroy {
  constructor(protected pageService: PageService, protected crpcService: CrpcService) {
    
  }

  private _source: MediaCrpcPlayerController | undefined;
  get source(): MediaCrpcPlayerController | undefined {
    return this._source;
  }

  @Input() set source(value: MediaCrpcPlayerController | undefined) {
    if (this._source === value) return;
    this._source = value;
    if (this.source === undefined) return;
    this.source.registerAsync().then();
  }

  public ngOnDestroy(): void {
  }
}
