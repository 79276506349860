import { Injectable } from '@angular/core';
import {CommunicationsService} from "../communications/communications.service";
import {AreaController} from "../configuration/area.controller";
import {SystemTypes} from "../support/isystem";
import {ControlType} from "./control.type";

type AreaConstructor = new (communicationsService: CommunicationsService, controlId: string, controllerIds: string[], controlType: ControlType) => AreaController;

@Injectable({
  providedIn: 'root'
})
export class AreaRegistry {

  constructor() {
  }

  public static areaControllers: Map<SystemTypes, AreaController[]> = new Map<SystemTypes, AreaController[]>();
  private static areaControllersById: Map<string, AreaController[]> = new Map<string, AreaController[]>();
  private static registry:Map<SystemTypes, AreaConstructor> = new Map<SystemTypes, AreaConstructor>();

  static getControllersById(controlId: string): AreaController[] | undefined {
    return AreaRegistry.areaControllersById.get(controlId);
  }

  static register(systemType: SystemTypes, constructor: AreaConstructor) {
    AreaRegistry.registry.set(systemType, constructor);
  }

  static clear() {
    AreaRegistry.areaControllers.clear();
    AreaRegistry.areaControllersById.clear();
  }

  static create(systemType: SystemTypes, communicationsService: CommunicationsService, contractResponse: any, controllerIds: string[]): AreaController | undefined {
    const constructor = AreaRegistry.registry.get(systemType);
    if (constructor) {
      // Call the controller constructor
      const controller: AreaController = new constructor(communicationsService, contractResponse.controlId, controllerIds, contractResponse.controlType);
      if (contractResponse.interfaces !== undefined)
        controller.interfaces = contractResponse.interfaces;

      // Get the existing array of Areas for the given SystemTypes, or initialize it if it doesn't exist
      const controllers: AreaController[] = AreaRegistry.areaControllers.get(systemType) || [];
      const controllersById: AreaController[] = AreaRegistry.areaControllersById.get(controller.controlId) || [];
      // Push the new Area to the array
      controllers.push(controller);
      controllersById.push(controller);

      AreaRegistry.areaControllersById.set(controller.controlId, controllersById);

      // Update the map with the new array of Areas
      AreaRegistry.areaControllers.set(systemType, controllers);

      return controller;
    }
    return undefined;
  }
}