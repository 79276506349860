<section class="page"
         [ngClass]="pageService.animationClass"
         *ngIf="displayMode === 'full'"
         (pointerdown)="commandPushGet($event)"
         (pointerup)="commandReleaseGet($event)"
         (pointercancel)="commandReleaseGet($event)">

  <section class="intercom">
    <section class="intercomEndpoints">
  <!--    <button *ngFor="let endpoint of IntercomService.intercomData" (click)="call(endpoint)">{{ endpoint.Name }}</button>-->
      <span> Incoming Uri:<br>{{ incomingData }} </span>
    </section>
    <section class="callSettings">
      <label>Call Volume</label>
      <app-slider [(level)]="IntercomService.callVolume" [minLevel]="0" [maxLevel]="65535"></app-slider>
        <span>{{ IntercomService.callVolume }}</span>
    </section>
      <button class="answer" *ngIf="IntercomService.ringing" data-command="Csig.Answer">
          <i class="fas fa-phone fa-2x"></i>
          <h3>ANSWER</h3>
      </button>
      <button class="hangup" *ngIf="IntercomService.ringing || IntercomService.callActive" data-command="Csig.Hangup">
          <i class="fas fa-phone-slash fa-2x"></i>
          <h3 *ngIf="IntercomService.ringing">IGNORE</h3>
          <h3 *ngIf="IntercomService.callActive">HANG UP</h3>
      </button>
  </section>
</section>

<section class="intercom-popup"
         [ngClass]="pageService.animationClass"
         *ngIf="displayMode === 'popup'"
         (pointerdown)="commandPushGet($event)"
         (pointerup)="commandReleaseGet($event)"
         (pointercancel)="commandReleaseGet($event)">
    <div class="overlay"></div>
    <section class="intercom-display">
        <div class="topBar">
            <!--        <h3 *ngIf="intercomData">{{ intercomData.Name }}</h3>-->
            <h3>Incoming Call From: <b>Garage</b></h3>
            <button class="close" data-command="close">
                <i class="fas fa-times-circle"></i>
            </button>
        </div>
        <div class="content">
            <div class="cameraWindow" *ngIf="IntercomService.activeCamera">
              <app-camera-display [activeCamera]="IntercomService.activeCamera"></app-camera-display>
            </div>
            <div class="controls">
                <button class="answer" *ngIf="IntercomService.ringing" data-command="Csig.Answer">
                    <i class="fa-solid fa-phone-volume fa-shake fa-2x"></i>
                    <h3>ANSWER</h3>
                </button>
                <button class="hangup" *ngIf="IntercomService.ringing || IntercomService.callActive" data-command="Csig.Hangup">
                    <i class="fas fa-phone-slash fa-2x"></i>
                    <h3 *ngIf="IntercomService.ringing">IGNORE</h3>
                    <h3 *ngIf="IntercomService.callActive">HANG UP</h3>
                </button>
            </div>
        </div>
    </section>
</section>
