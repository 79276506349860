import { Injectable } from '@angular/core';
import {IService} from "../serviceManager/IService";

@Injectable({
  providedIn: 'root'
})
export class DictionaryService<K, V> implements IService {
  private store: { [key: string]: V } = {};

  private keyStringifier(key: K): string {
    return JSON.stringify(key);
  }

  add(key: K, value: V): void {
    const keyStr = this.keyStringifier(key);
    this.store[keyStr] = value;
  }

  tryGet(key: K): V | undefined {
    const keyStr = this.keyStringifier(key);
    return this.store[keyStr];
  }

  contains(key: K): boolean {
    const keyStr = this.keyStringifier(key);
    return keyStr in this.store;
  }

  delete(key: K): void {
    const keyStr = this.keyStringifier(key);
    delete this.store[keyStr];
  }
}
