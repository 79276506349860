<section class="page dashboard" [ngClass]="pageService.animationClass">
  <span class="header">{{ pageService.activeArea?.name ?? 'No Selected Area' }}</span>
  <div class="dashboard-card-container">
    <app-controller-container-card
      *ngIf="ControllerRegistry.controllers.get(ControlType.Weather)"
      [controllers]="ControllerRegistry.controllers.get(ControlType.Weather)"></app-controller-container-card>
    
    <app-controller-container-card
      *ngIf="pageService.activeArea && pageService.activeArea.controllers.get(SystemTypes.Media)"
      [area]="pageService.activeArea"
      [controllers]="pageService.activeArea.controllers.get(SystemTypes.Media)"></app-controller-container-card>

    <app-controller-container-card
      *ngIf="pageService.activeArea && pageService.activeArea.controllers.get(SystemTypes.Lighting)"
      [area]="pageService.activeArea"
      [controllers]="pageService.activeArea.controllers.get(SystemTypes.Lighting)"></app-controller-container-card>

    <app-controller-container-card
      *ngIf="pageService.activeArea && pageService.activeArea.devices.get(SystemTypes.Climate)"
      [area]="pageService.activeArea"
      [controllers]="pageService.activeArea.devices.get(SystemTypes.Climate)"></app-controller-container-card>
  </div>
</section>
