import {ControlOption} from "../../services/support/control.option";
import {CommunicationsService} from "../../services/communications/communications.service";
import {PressAndReleaseHandler} from "../../services/communications/press.and.release.handler";

export class ClimateSetPoint {
  constructor(private communicationsService: CommunicationsService, private controlId: string, mode: ControlOption, value: number, minimum: number, maximum: number) {
    this.mode = mode;
    this.value = value;
    this.minimum = minimum;
    this.maximum = maximum;
    this.pressReleaseHandler = new PressAndReleaseHandler(communicationsService);
  }

  public mode: ControlOption;
  public value: number;
  public minimum: number;
  public maximum: number;
  
  private readonly pressReleaseHandler: PressAndReleaseHandler;

  public update(setPoint: { mode: ControlOption, value: number, minimum: number, maximum: number}): void {
    this.value = setPoint.value;
  }

  public setPointIncrease(buttonState: boolean): void {
    this.communicationsService.sendControlMessage(this.controlId, "setPointIncrease", {mode: {value: this.mode.value }, buttonState: buttonState});
  }

  public setPointDecrease(buttonState: boolean): void {
    this.communicationsService.sendControlMessage(this.controlId, "setPointDecrease", {mode: {value: this.mode.value }, buttonState: buttonState});
  }

  public setSetPoint(value: number): void {
    this.communicationsService.sendControlMessage(this.controlId, "setSetPoint", {mode: {value: this.mode.value }, value: value});
  }
}
