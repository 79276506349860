import {Component, OnDestroy, ViewChild} from '@angular/core';
import {PageService} from "../../services/interface/page.service";
import {CommunicationsService} from "../../services/communications/communications.service";
import {PageSystem} from "../pageSystem";
import {ConfigurationService} from "../../services/configuration/configuration.service";
import {MediaAreaController} from "./media.area.controller";
import {MediaSourceController} from "./media.source.controller";
import {SystemTypes} from "../../services/support/isystem";
import {PageConfig, PaginationComponent} from "../../components/pagination/pagination.component";
import {MediaFavoritesComponent} from "./media-favorites/media-favorites.component";
import {MediaNavigationComponent} from "./media-navigation/media-navigation.component";
import {MediaMiscComponent} from "./media-misc/media-misc.component";
import {MediaTransportsComponent} from "./media-transports/media-transports.component";
import {MediaAvSettingsComponent} from "./media-av-settings/media-av-settings.component";
import {MediaNumpadComponent} from "./media-numpad/media-numpad.component";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent extends PageSystem<MediaAreaController> implements OnDestroy {
  constructor(communicationsService: CommunicationsService, configurationService: ConfigurationService, pageService: PageService) {
    super(communicationsService, configurationService, pageService, SystemTypes.Media)
  }

  protected activeSource: MediaSourceController | undefined;
  protected activeControlIndex: number = 0;
  protected mediaPageConfigs: PageConfig[] = [];

  protected activePage: number = 0;
  protected navigationIndexes: number[] = [0, 0, 0, 0, 0, 0];

  private activeSourcesChangedSubscription: Subscription | undefined;
  private ignoreSourceChange: boolean = false;

  protected selectSource(source: MediaSourceController, _: number): void {
    if (this.activeController === undefined) return;
    this.ignoreSourceChange = true;
    this.activeControlIndex = 0;
    this.activeSource = source;
    this.activeController.selectSource(source);
    this.setupPageConfigs(source);
    this.navigationIndex = 1;
    this.pageService.activeSource = source;
  }

  protected controlNavigation(tabIndex: number, source: MediaSourceController): void {
    this.setupPageConfigs(source);
    this.navigationIndex = tabIndex;
    this.activeSource = source;
    this.pageService.activeSource = source;
  }

  public ngOnDestroy(): void {
    //TODO: this might be bad?
    if (this.activeController !== undefined)
      this.activeController?.unsubscribeFromStatusMessages();
  }

  protected selectPage(pageIndex: number): void {
    this.activePage = pageIndex;
  }

  protected override removeOldController() {
    if (this.activeSourcesChangedSubscription === undefined) return;
    this.activeSourcesChangedSubscription.unsubscribe();
  }

  protected override processNewController(): void {
    if (this.activeController === undefined) return;
    if (this.activeController.activeSources.length === 0)
      this.activeSource = undefined;
    else
      this.activeSource = this.activeController.activeSources[0];

    this.activeSourcesChangedSubscription = this.activeController.activeSourcesChangedEvent.subscribe(() => {
      if (this.activeController?.activeSources === undefined || this.activeController.activeSources.length === 0) {
        this.activeSource = undefined;
        this.pageService.activeSource = undefined;
        this.navigationIndex = 0;
      } else {
        if (!this.ignoreSourceChange) {
          const source: MediaSourceController = this.activeController.activeSources[0];
          this.setupPageConfigs(source);
          this.pageService.activeSource = source;
          this.activeSource = source;
        }
      }

      this.ignoreSourceChange = false;
    });
    this.pageService.activeSource = this.activeSource;
  }

  private setupPageConfigs(source: MediaSourceController): void {
    const mediaPageConfigs: PageConfig[] = [
      {
        visible: source.useFavorites,
        component: MediaFavoritesComponent,
        inputs: {
          activeController: source
        }
      },
      {
        visible: (source.keypadActions?.length ?? 0) > 0,
        component: MediaNumpadComponent,
        inputs: {
          activeController: source
        }
      },
      {
        visible: (source.navigationActions?.length ?? 0) > 0,
        component: MediaNavigationComponent,
        inputs: {
          activeController: source
        }
      },
      {
        visible: (source.transportActions?.length ?? 0) > 0,
        component: MediaTransportsComponent,
        inputs: {
          activeController: source
        }
      },
      {
        visible: (source.miscActions?.length ?? 0) > 0,
        component: MediaMiscComponent,
        inputs: {
          activeController: source
        }
      },
      {
        visible: (this.activeController?.avSettings.length ?? 0) > 0,
        component: MediaAvSettingsComponent,
        inputs: {
          activeController: source
        }
      }
    ];

    let startIndex: number = 0;
    for (let i = 0; i < mediaPageConfigs.length; i++) {
      const config: PageConfig = mediaPageConfigs[i];
      this.navigationIndexes[i] = startIndex;
      if (config.visible) startIndex++;
    }

    this.mediaPageConfigs = mediaPageConfigs;
    this.selectPage(0);
  }
}
