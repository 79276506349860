import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OnOffState} from "../../services/support/on.off.state";
import {ControlOption} from "../../services/support/control.option";

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {
  @Input() state: boolean | OnOffState = false;
  @Input() id: number = 0;
  @Output() stateChange: EventEmitter<boolean | OnOffState> = new EventEmitter<boolean | OnOffState>();

  get isChecked(): boolean {
    if (typeof this.state === 'boolean')
      return this.state;
    else
      return this.state === OnOffState.On;
  }
  
  public handleChange(event: Event)
  {
    event.stopPropagation();
    event.preventDefault();
    const checked: boolean = (event.target as HTMLInputElement).checked;
    if (typeof this.state === 'boolean')
      this.stateChange.emit(checked);
    else
      this.stateChange.emit(checked ? OnOffState.On : OnOffState.Off);
  }
}
