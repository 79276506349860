import {LightingController} from "./lighting.controller";
import {CommunicationsService} from "../../services/communications/communications.service";
import {ControllerRegistry} from "../../services/registries/controller.registry.service";
import {AreaController} from "../../services/configuration/area.controller";
import {LightingSceneController} from "./lighting.scene.controller";
import {ControlType} from "../../services/registries/control.type";

export class LightingAreaController extends AreaController {
  constructor(communicationsService: CommunicationsService, controlId: string, controllerIds: string[], controlType: ControlType) {
    super(communicationsService, controlId, controlType)
  }
  
  public scenes: LightingSceneController[] = [];
  public loads: LightingController[] = [];

  public override processControllerIds(): void {
    this.controllerIds.forEach((controllerId: string) => {
      const controller = ControllerRegistry.getControllerById(controllerId);
      if (controller instanceof LightingController && !this.loads.includes(controller))
        this.loads.push(controller);
      else if (controller instanceof LightingSceneController && !this.scenes.includes(controller))
        this.scenes.push(controller);
    });
  }

  public override async pollAsync(): Promise<void> {
    await super.pollAsync();
    await Promise.all([
      ...this.loads.map(load => load.pollAsync()),
      ...this.scenes.map(scene => scene.pollAsync())
    ]);
  }

  public override unsubscribeFromStatusMessages(): void {
    super.unsubscribeFromStatusMessages();
    this.loads.forEach(load => load.unsubscribeFromStatusMessages());
    this.scenes.forEach(scene => scene.unsubscribeFromStatusMessages());
  }
}