import {Component, Input} from '@angular/core';
import {MediaSourceController} from "../media.source.controller";
import {ControlOption} from "../../../services/support/control.option";
import {StandardActions} from "../standard.actions";

@Component({
  selector: 'app-media-transports',
  templateUrl: './media-transports.component.html',
  styleUrls: ['./media-transports.component.scss']
})
export class MediaTransportsComponent {
  @Input() activeController: MediaSourceController | undefined;

  protected getGridAreaClass(transportAction: ControlOption): string {
    switch (transportAction.value)
    {
      case StandardActions.Play.value:
      case StandardActions.PlayPause.value:
        return 'center';
      case StandardActions.Stop.value:
        return 'center-bottom';
      case StandardActions.Pause.value:
        return 'center-top';
      case StandardActions.Rewind.value:
        return 'mid-left-top';
      case StandardActions.FastForward.value:
        return 'mid-right-top';
      case StandardActions.Replay.value:
      case StandardActions.SkipBack.value:
      case StandardActions.Previous.value:
        return 'mid-left-bottom';
      case StandardActions.Next.value:
        return 'mid-right-bottom';
      case StandardActions.Advance.value:
        return 'left';
      case StandardActions.Record.value:
        return 'right';
    }
    return '';
  }
}
