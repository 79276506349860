<div class="circuit-list-container" *ngIf="activeCircuits !== undefined">
    <div class="circuit-list-item" *ngFor="let circuit of activeCircuits; let i = index">
        <div class="circuit-title">
            <h3>{{ circuit.name | uppercase }}</h3>
            <span class="circuit-description">{{ circuit.description }}</span>
        </div>
        <div class="circuit-control" *ngIf="circuit.interfaces.includes('IPowerControl')">
            <app-toggle [id]="i" [state]="circuit.powerState" (stateChange)="circuit.toggle($event)"></app-toggle>
        </div>
        <app-slider [level]="circuit.level" (levelChange)="circuit.setLevel($event)" [minLevel]="0" [maxLevel]="65535" *ngIf="circuit.interfaces.includes('ILevelSetControl')"></app-slider>
        <!--        <app-rgb *ngIf="circuit.interfaces.includes('IRgbControl')" [circuit]="circuit"></app-rgb>-->

        <section class="app-rgb" *ngIf="circuit.interfaces.includes('IRgbControl') || circuit.interfaces.includes('IRgbwControl')">
            <input class="my-color-picker"
                   [colorPicker]="circuit.color"
                   (colorPickerChange)="circuit.setRgbColor($event)"
                   [style.background]="circuit.color"
                   [cpAlphaChannel]="'disabled'"
                   [cpOutputFormat]="'rgba'"
                   [cpDisableInput]="true"
            />
        </section>
    </div>
</div>
