import {CrpcService} from "./crpc.service";
import {jsonRpcRx} from "./jsonRpcRx";
import {logger} from "../support/logger";
import {BrowserAction} from "../../pages/media/media-player-crpc/browser-view/browser.action";
import {BusyState} from "./busy.state";
import {BrowserLine} from "../../pages/media/media-player-crpc/browser-view/browser.line";
import {CrpcPopupInfo} from "./crpc.popup-info";

export class CrpcBrowserData {
  constructor(private crpcService: CrpcService, public name: string) {
    this.crpcService.browserInstance = this;
    this.crpcService.addEventHandler(`${name}.BusyChanged`, this.busyStateEventHandler.bind(this));
    this.crpcService.addEventHandler(`${name}.ClearChanged`, this.clearChangedEventHandler.bind(this));
    this.crpcService.addEventHandler(`${name}.ListChanged`, this.listChangedEventHandler.bind(this));
    this.crpcService.addEventHandler(`${name}.StateChanged`, this.stateChangedEventHandler.bind(this));
    this.crpcService.addEventHandler(`${name}.StatusMsgMenuChanged`, this.statusMessageMenuChangedEventHandler.bind(this));
  }

  private initialEvents: string[] = ['BusyChanged', 'ClearChanged', 'ListChanged', 'StateChanged', 'StatusMsgMenuChanged'];
  private readonly initialProperties: string[] = ['MaxReqItems', 'Level', 'StatusMsgMenu', 'Instance'];
  private readonly listProperties: string[] = ['IsMenuAvailable', 'ListSpecificFunctions', 'Title', 'Subtitle', 'TransactionId', 'ItemCnt'];

  private _listSpecificFunctions: string[] = [];
  public set listSpecificFunctions(value: string[]) {
    if (this._listSpecificFunctions == value) return;
    this._listSpecificFunctions = value;
    this.listActions = [];
    if (value == undefined) return;
    value.forEach((func: string): void => {
      const action: BrowserAction = new BrowserAction(func);
      this.listActions.push(action);
    })
  }

  private maxReqItems: number = 0;
  private _itemCnt: number = 0;
  protected get itemCnt(): number {
    return this._itemCnt;
  }

  protected set itemCnt(value: number) {
    this._itemCnt = value;
    this._itemStart = 0;
    this._itemCount = this.itemCnt - this._itemStart;
    if (this._itemCount > this.maxReqItems) this._itemCount = this.maxReqItems;
    this.tryGetBrowserMenuAsync().then();
  }

  private _itemCount: number = 0;
  private _itemStart: number = 0;
  private busyTimeout: any;

  public title: string = '';
  public subtitle: string = '';
  public isMenuAvailable: boolean = false;
  public statusMsgMenu: any;
  public busy: BusyState = {on: true, timeoutSec: 0};
  public popupInfo: CrpcPopupInfo | undefined;
  public listActions: BrowserAction[] = [];
  public level: number = 0;
  public listItems: BrowserLine[] = [];

  public async initAsync(): Promise<void> {
    await this.crpcService.sendAsync(
      `${this.name}.Reset`,
      undefined,
      this.genericActionReplyHandler.bind(this)
    );

    for (const eventName of this.initialEvents) {
      await this.crpcService.sendAsync(
        `${this.name}.RegisterEvent`,
        {
          ev: eventName,
          handle: this.crpcService.handle,
        },
        this.registerEventHandler.bind(this)
      );
    }

    for (const propertyName of this.initialProperties) {
      await this.crpcService.sendAsync(
        `${this.name}.GetProperty`,
        {
          propName: propertyName,
        },
        this.getPropertyReplyHandler.bind(this)
      );
    }
  }

  public back(): void {
    this.crpcService.sendAsync(
      `${this.name}.Back`,
      undefined,
      this.genericActionReplyHandler.bind(this)
    ).then();
  }

  public selectListItem(itemIndex: number): void {
    this.crpcService.sendAsync(
      `${this.name}.Select`,
      {
        item: itemIndex + 1,
      },
      this.genericActionReplyHandler.bind(this)
    ).then();
  }

  public selectListAction(actionIndex: number): void {
    this.crpcService.sendAsync(
      `${this.name}.${this.listActions[actionIndex].label}`,
      undefined,
      this.genericActionReplyHandler.bind(this)
    ).then();
  }

  public async destroyAsync(): Promise<void> {
    this.crpcService.browserInstance = undefined;

    for (const eventName of this.initialEvents) {
      await this.crpcService.sendAsync(
        `${this.name}.DeregisterEvent`,
        {
          ev: eventName,
          handle: this.crpcService.handle,
        },
        this.registerEventHandler.bind(this)
      );
    }

    this.crpcService.removeEventHandler(`${this.name}.BusyChanged`);
    this.crpcService.removeEventHandler(`${this.name}.ClearChanged`);
    this.crpcService.removeEventHandler(`${this.name}.ListChanged`);
    this.crpcService.removeEventHandler(`${this.name}.StateChanged`);
    this.crpcService.removeEventHandler(`${this.name}.StatusMsgMenuChanged`);
  }

  private async getListAsync(): Promise<void> {
    for (const propertyName of this.listProperties) {
      await this.crpcService.sendAsync(
        `${this.name}.GetProperty`,
        {
          propName: propertyName,
        },
        this.getPropertyReplyHandler.bind(this)
      );
    }
  }

  private busyStateEventHandler(event: jsonRpcRx): void {
    if (event.params === undefined) return;
    this.busy = event.params.parameters ?? {on: false, timeoutSec: 0};

    if (this.busy.timeoutSec ?? 0 > 0) {
      this.busyTimeout = setTimeout(() => {
        this.busy = {on: false, timeoutSec: 0};
        this.busyTimeout = undefined;
      }, this.busy.timeoutSec * 1000);
    } else if (this.busyTimeout) {
      clearTimeout(this.busyTimeout);
    }
  }

  private clearChangedEventHandler(_: jsonRpcRx): void {
    this.listSpecificFunctions = [];
    this.title = '';
    this.subtitle = '';
    this.isMenuAvailable = false;
    this.statusMsgMenu = undefined;
    this.busy = {on: false, timeoutSec: 0};
    this.listItems = [];
    this._itemStart = 0;
    this.popupInfo = undefined;
  }

  private listChangedEventHandler(event: jsonRpcRx): void {
    logger.debug('listChangedEventHandler:', event);
  }

  private stateChangedEventHandler(event: jsonRpcRx): void {
    if (event.params?.parameters?.Title && event.params.parameters.Level) {
      //browser changed level
      this.title = event.params.parameters.Title;
      this.level = event.params.parameters.Level;
      this.getListAsync().then();
    }
  }

  private statusMessageMenuChangedEventHandler(event: jsonRpcRx): void {
    logger.debug('statusMessageMenuChangedEventHandler:', event);
    logger.debug('Popup Box:', this.popupInfo);
    if (event.params?.parameters === undefined) {
      this.popupInfo = undefined;
      return;
    }

    this.popupInfo = event.params.parameters.show ? {
      browserInstanceName: this.name,
      text: event.params.parameters.text,
      timeoutSec: event.params.parameters.timeoutSec,
      userInputRequired: event.params.parameters.userInputRequired,
      initialUserInput: event.params.parameters.initialUserInput,
      show: event.params.parameters.show,
      textForItems: event.params.parameters.textForItems,
      localExit: event.params.parameters.localExit,
      listShown: event.params.parameters.listShown,
      listDescriptionText: event.params.parameters.listDescriptionText,
      listItems: event.params.parameters.listItems,
      listItemsIndicesInitiallySelected: event.params.parameters.listItemsIndicesInitiallySelected,
      listMode: event.params.parameters.listMode
    } : undefined;
  }

  // private registerEventHandler(event: jsonRpcRx): void {
  //   logger.debug("registerEventHandler:", event);
  // }
  //
  // private genericActionReplyHandler(reply: jsonRpcRx): void {
  //   logger.debug('generic reply handler:', reply);
  // }

  private registerEventHandler(_: jsonRpcRx): void {
  }

  private genericActionReplyHandler(_: jsonRpcRx): void {
  }

  private getPropertyReplyHandler(reply: jsonRpcRx): void {
    if (reply.error != undefined) return;
    const propName: string = Object.keys(reply.result)[0];
    const camelPropName: string = this.crpcService.toCamelCase(propName);
    // @ts-ignore
    this[camelPropName] = reply.result[propName];
    if (propName == 'Level') this.getListAsync().then();
  }

  private async tryGetBrowserMenuAsync(): Promise<void> {
    if (this.level == undefined || this.itemCnt == undefined) return;
    await this.crpcService.sendAsync(
      `${this.name}.GetData`,
      {
        count: this._itemCount,
        item: this._itemStart + 1,
      },
      this.getMenuCallbackAsync.bind(this)
    );
  }

  private async getMenuCallbackAsync(reply: jsonRpcRx): Promise<void> {
    if (reply.error) {
      logger.error('browser getMenuCallback error', reply);
      return;
    }
    if (this._itemStart == 0) this.listItems = [];
    if (reply.result instanceof Array) {
      reply.result.forEach((lineData: any): void => {
        this.listItems.push(new BrowserLine(lineData.L1, lineData.L2, lineData.URL));
      });
      if (this._itemCount + this._itemStart != this.itemCnt) {
        this._itemStart = this._itemStart + this._itemCount;
        this._itemCount = this.itemCnt - this._itemStart > 100 ? 100 : this.itemCnt - this._itemStart;
        await this.tryGetBrowserMenuAsync();
      } else
        this.busy = {on: false, timeoutSec: 0};
    } else logger.debug(reply);
  }
}