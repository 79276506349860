import {Component, Input} from '@angular/core';
import {MediaSourceController} from "../media.source.controller";

@Component({
  selector: 'app-media-misc',
  templateUrl: './media-misc.component.html',
  styleUrls: ['./media-misc.component.scss']
})
export class MediaMiscComponent {
  @Input() activeController: MediaSourceController | undefined;
}
