import {Component, Input} from '@angular/core';
import {ClimateSetPoint} from "../../pages/climate/climate.setpoint";

@Component({
  selector: 'app-temp-gauge',
  templateUrl: './temp-gauge.component.html',
  styleUrls: ['./temp-gauge.component.scss']
})
export class TempGaugeComponent {
  constructor() {
    this.generateNotches();
  }
    // @ts-ignore
  protected notchArray: [{degree: number, rotate: string}] = [];
  
  @Input() setPoints: ClimateSetPoint[] | undefined;  
  @Input() temperature: number | undefined;
  
  private generateNotches(): void {
    for (let i = 0; i <= 50; i++) {
      const notch = {
        degree: i + 60,
        rotate: `rotate(${-150 + (i * 6)}deg)`
      }
      this.notchArray.push(notch);
    }
  }

  protected getIndicatorClasses(setPoint: ClimateSetPoint): any {
    const classes = {
      'cool-indicator': setPoint.mode.label.toLowerCase().includes('cool'),
      'heat-indicator': setPoint.mode.label.toLowerCase().includes('heat'),
      'auto-indicator': setPoint.mode.label.toLowerCase().includes('auto')
    };
    return classes;
  }

  protected  setPointIncrease(event: PointerEvent, setPoint: ClimateSetPoint): void {
    if (this.setPoints === undefined) return;
    const buttonState = event.buttons > 0;
    
    setPoint.setPointIncrease(buttonState);
  }

  protected  setPointDecrease(event: PointerEvent, setPoint: ClimateSetPoint): void {
    if (this.setPoints === undefined) return;
    const buttonState = event.buttons > 0;
    
    setPoint.setPointDecrease(buttonState);
  }

  protected getSetPointLocation(setPoint: ClimateSetPoint) : string {
    let location = 'heat';
    if (setPoint.mode.label.toLowerCase().includes('cool') || setPoint.mode.label.toLowerCase().includes('dehumidify'))
      location = 'cool';
    else if (setPoint.mode.label.toLowerCase().includes('heat') || setPoint.mode.label.toLowerCase().includes('humidify'))
      location = 'heat';
    else
      location = 'auto';

    return location;
  }
}
