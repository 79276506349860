import {Controller} from "../../services/configuration/controller";
import {CommunicationsService} from "../../services/communications/communications.service";
import {ControlOption} from "../../services/support/control.option";
import {logger} from "../../services/support/logger";
import {OnOffState} from "../../services/support/on.off.state";
import {ControlType} from "../../services/registries/control.type";

export class LightingController extends Controller {
  constructor(communicationsService: CommunicationsService, controlId: string, apiContracts: any, controlType: ControlType) {
    super(communicationsService, controlId, apiContracts, controlType);
    //logger.debug(`[ ${this.constructor.name} ] - [ ${apiContracts.name} ] has contracts of`, apiContracts);
  }

  public powerState: boolean = false;
  public level: number = 0;
  public speed: ControlOption | undefined;

  public availableSpeeds: ControlOption[] | undefined;
  
  public color: string = 'rgb(0, 0, 0)';
  public toggle(state: OnOffState | boolean): void {
    
    let newState: boolean = false;
    if (typeof state === 'boolean')
      newState = state;
    else
      newState = state == OnOffState.On;
    
    if (this.interfaces.includes('IRgbControl'))
    {
      if (newState)
        this.sendRgbColor(65535, 65535, 65535);
      else
        this.sendRgbColor(0, 0, 0);
      return;
    }
    if (this.interfaces.includes('IRgbwControl'))
    {
      if (newState)
        this.sendRgbwColor(65535, 65535, 65535, 65535);
      else
        this.sendRgbwColor(0, 0, 0, 0);
      return;
    }
    this.communicationsService.sendControlMessage(this.controlId, "setPower", {action: newState ? 2 : 1});
  }

  public setLevel(value: number): void {
    this.communicationsService.sendControlMessage(this.controlId, "setLevel", {percent: value});
  }

  public setSpeed(speed: ControlOption): void {
    this.communicationsService.sendControlMessage(this.controlId, "setFanSpeed", {speed: speed});
  }
  
  public setRgbColor(rgb: string): void {
    // Regular expression to extract numbers from the RGB string
    const regex = /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/;

    // Extract RGB values
    const match = rgb.match(regex);
    if (!match) return;

    // Parse RGB values to integers
    const [r, g, b] = match.slice(1).map(Number);

    // Scale values from 0-255 to 0-65535
    const scale = (value: number) => Math.round(value * 65535 / 255);
    const scaledR: number = scale(r);
    const scaledG: number = scale(g);
    const scaledB: number = scale(b);
    
    this.sendRgbColor(scaledR, scaledG, scaledB);
  }

  private sendRgbColor(r: number, g: number, b: number): void
  {
    this.communicationsService.sendControlMessage(this.controlId, "setRgbColor", {red: r,  green: g,  blue: b});
  }

  private sendRgbwColor(r: number,  g: number, b: number, w: number): void
  {
    this.communicationsService.sendControlMessage(this.controlId, "setRgbwColor", {red: r,  green: g,  blue: b, white: w});
  }

  /*#region "Contract Handlers"*/
  private setPowerContractHandler(contract: any): void {
  }

  private upContractHandler(contract: any): void {
  }

  private downContractHandler(contract: any): void {
  }

  private setLevelContractHandler(contract: any): void {
  }

  private setFanSpeedContractHandler(contract: any): void {
    this.availableSpeeds = contract.availableOptions.map((option: ControlOption, index: number) => {
      return {
        ...option,
        index                 // Add the index property
      };
    });
  }

  private setRgbColorContractHandler(contract: any): void {
  }

  /*#endregion*/

  /*#region "Status Update Handlers"*/
  private powerStateUpdate(params: any): void {
    this.powerState = params.state.value == 2;
  }

  private levelUpdate(params: any): void {
    //this.level = (params.level * 100) / 65535;
    this.level = params.level;
  }

  private fanSpeedUpdate(params: any): void {
    if (this.availableSpeeds === undefined) return;
    const index = this.availableSpeeds.findIndex(speed => speed.value === params.speed.value);
    if (index === -1) {
      logger.error(`[ ${this.constructor.name} ] We received a speed update with a value not in the available options`);
      return;
    }
    this.speed = this.availableSpeeds[index];
  }

  private rgbColorUpdate(params: any): void {
    const r: number = params.color.red * 255 / 65535;
    const g: number = params.color.green * 255 / 65535;
    const b: number = params.color.blue * 255 / 65535;
    this.color = `rgb(${r},${g},${b})`;
    this.powerState = r > 0 || g > 0 || b > 0;
  }

  private rgbwColorUpdate(params: any): void {
  }

  /*#endregion*/
}
