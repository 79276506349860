import {ControlOption} from "../../services/support/control.option";

export class ClimateModes {
  static readonly Unknown: ControlOption = {
    value: 0,
    label: "Unknown"
  };

  static readonly Off: ControlOption = {
    value: 1,
    label: "Off"
  };

  static readonly Heat: ControlOption = {
    value: 2,
    label: "Heat"
  };

  static readonly Cool: ControlOption = {
    value: 3,
    label: "Cool"
  };

  static readonly Auto: ControlOption = {
    value: 4,
    label: "Auto"
  };

  static readonly AutoDual: ControlOption = {
    value: 5,
    label: "AutoDual"
  };

  static readonly Fan: ControlOption = {
    value: 6,
    label: "Fan"
  };

  static readonly FloorOff: ControlOption = {
    value: 7,
    label: "FloorOff"
  };

  static readonly FloorHeat: ControlOption = {
    value: 8,
    label: "FloorHeat"
  };

  static readonly FloorCool: ControlOption = {
    value: 9,
    label: "FloorCool"
  };

  static readonly FloorAuto: ControlOption = {
    value: 10,
    label: "FloorAuto"
  };

  static readonly FloorAutoDual: ControlOption = {
    value: 11,
    label: "FloorAutoDual"
  };
}
