<section class="popup">
  <div class="overlay"></div>
  <div class="popup-info">
    <h3 class="popup-header">{{ popupInfo?.text }}</h3>
    <div class="content">
      <!-- input field -->
      <div class="inputbox" *ngIf="popupInfo?.userInputRequired != 'confirmation'">
        <input #input type="text" required spellcheck="false" (keyup)="inputChange($event)"/>
        <span class="underline"></span>
      </div>

      <!-- buttons -->
      <div class="popup-buttons">
        <button *ngFor="let button of popupInfo?.textForItems; let i = index"
                [ngClass]="{ default: i == 0, negative: button == 'Cancel' }" (click)="selectItemAsync(i)">
          <span>{{ button }}</span>
        </button>
      </div>
    </div>
  </div>
</section>