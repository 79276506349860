import {Component, Input} from '@angular/core';
import {DoorLocksController} from "../door-locks.controller";
import {PageService} from "../../../services/interface/page.service";
import {PageType} from "../../../services/interface/pageType";

@Component({
  selector: 'app-lock-options-popup',
  templateUrl: './lock-options-popup.component.html',
  styleUrls: ['./lock-options-popup.component.scss'],
})
export class LockOptionsPopupComponent {
  constructor(protected pageService: PageService) {
  }
  
  // @ts-ignore
  @Input() lock: DoorLocksController;
  
  protected close() {
    this.pageService.removePopup(PageType.DoorLocksOptions);
  }
}
