import {Controller} from "../../services/configuration/controller";
import {CommunicationsService} from "../../services/communications/communications.service";
import {ControlType} from "../../services/registries/control.type";
import {IWeatherData} from "./IWeatherData";


export class WeatherController extends Controller {
    constructor(communicationsService: CommunicationsService, controlId: string, apiContracts: any, controlType: ControlType) {
        super(communicationsService, controlId, apiContracts, controlType);
    }

    weatherData: IWeatherData[] = [];

    /*#region "API Handlers"*/
    /*#endregion*/

    /*#region "Contract Handlers"*/
    /*#endregion*/

    /*#region "Status Update Handlers"*/
    private weatherUpdate(params: any): void {
        if (params.weatherData)
            this.weatherData = params.weatherData;
    }
    /*#endregion*/
}
