import { Injectable } from '@angular/core';
import {IService} from "../serviceManager/IService";

interface IDatabaseSchema {
  version: number;
  stores: IStoreSchema[];
}

interface IStoreSchema {
  name: string;
  keyPath: string;
  indexes?: IIndexSchema[];
  // Optionally, you can define the expected data structure for each store
  // This is more about your application logic rather than IndexedDB's implementation
  initialData?: any[]; // Example of how you might preload data
}

interface IIndexSchema {
  name: string;
  keyPath: string | string[];
  options?: IDBIndexParameters;
}

const databaseSchemas: IDatabaseSchema[] = [
  {
    version: 1,
    stores: [
      {
        name: "connection",
        keyPath: "key",
        indexes: [
          { name: "by_key", keyPath: "key", options: { unique: true } }
        ],
        initialData: [
          { key: "url", value: undefined },
          { key: "username", value: undefined },
          { key: "password", value: undefined },
          { key: "useWss", value: false },
        ],
      },
      {
        name: "activeAreas",
        keyPath: "pageType",
      },
      {
        name: "mediaPlayer",
        keyPath: "key",
        indexes: [
          { name: "by_key", keyPath: "key", options: { unique: true } }
        ],
        initialData: [
          { key: "uuid", value: undefined },
        ],
      }
    ],
  },
  // Future versions with schema modifications go here
];

@Injectable({
  providedIn: 'root',
})
export class DatabaseService implements IService {
  private dbVersion: number = 1;
  private readonly dbName: string = "definition-ux";

  constructor() {
    //const deleteRequest = indexedDB.deleteDatabase(this.dbName);

    const latestVersion = databaseSchemas[databaseSchemas.length - 1].version; // Get the latest version
    const openRequest = indexedDB.open(this.dbName, latestVersion);

    openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db: IDBDatabase = openRequest.result;
      //const upgradeTransaction = event.target.transaction;
      const existingStoreNames = db.objectStoreNames;

      databaseSchemas.forEach(schema => {
        if (event.oldVersion < schema.version) {
          schema.stores.forEach(storeSchema => {
            if (!existingStoreNames.contains(storeSchema.name)) {
              const store = db.createObjectStore(storeSchema.name, {keyPath: storeSchema.keyPath});
              storeSchema.indexes?.forEach(index => {
                store.createIndex(index.name, index.keyPath, index.options);
              });
            }
            // Add logic here for modifying existing stores if needed
          });
        }
      });

      openRequest.onerror = () => {
        console.error('Error', openRequest.error);
      };

      openRequest.onsuccess = function () {
      };
    }
  }

  public async addOrUpdateAsync(storeName: string, data: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const dbOpenRequest = indexedDB.open(this.dbName, this.dbVersion);

      dbOpenRequest.onerror = () => reject(dbOpenRequest.error);

      dbOpenRequest.onsuccess = () => {
        const db = dbOpenRequest.result;
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const putRequest = store.put(data); // Directly use the data object

        putRequest.onsuccess = () => resolve();
        putRequest.onerror = () => reject(putRequest.error);
        transaction.onerror = () => reject(transaction.error);
      };
    });
  }


  public addAllAsync(storeName: string, keys: string[], values: any[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const openRequest = indexedDB.open(this.dbName, this.dbVersion);

      openRequest.onerror = () => reject(openRequest.error);

      openRequest.onsuccess = () => {
        const db = openRequest.result;
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const requests = keys.map((key, index) => {
          return new Promise((resolve, reject) => {
            const request = store.put({ key: key, value: values[index] });
            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject(request.error);
          });
        });

        Promise.all(requests)
            .then(() => resolve())
            .catch(error => reject(error));

        transaction.oncomplete = () => resolve();
        transaction.onerror = () => reject(transaction.error);
      };
    });
  }

  public async getAsync(storeName: string, key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Open a connection to the database
      const dbOpenRequest = indexedDB.open(this.dbName, this.dbVersion);

      dbOpenRequest.onerror = () => {
        reject(dbOpenRequest.error);
      };

      dbOpenRequest.onsuccess = () => {
        const db = dbOpenRequest.result;
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);
        const getRequest = store.get(key);

        getRequest.onsuccess = () => {
          resolve(getRequest.result); // Resolve with the fetched result
        };

        getRequest.onerror = () => {
          reject(getRequest.error);
        };
      };
    });
  }

  public async getAllAsync(storeName: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const dbOpenRequest = indexedDB.open(this.dbName, this.dbVersion);

      dbOpenRequest.onerror = () => {
        reject(dbOpenRequest.error);
      };

      dbOpenRequest.onsuccess = () => {
        const db = dbOpenRequest.result;
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);
        const getAllRequest = store.getAll(); // Use getAll() to fetch all records

        getAllRequest.onsuccess = () => {
          resolve(getAllRequest.result); // Resolve with all fetched records
        };

        getAllRequest.onerror = () => {
          reject(getAllRequest.error);
        };
      };
    });
  }

  public getByKeysAsync(storeName: string, keys: string[]): Promise<{ [key: string]: any }> {
    return new Promise((resolve, reject) => {
      const openRequest = indexedDB.open(this.dbName, this.dbVersion);

      openRequest.onerror = () => reject(openRequest.error);

      openRequest.onsuccess = () => {
        const db = openRequest.result;
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);
        const resultObject: { [key: string]: any } = {};
        const requests = keys.map(key =>
            new Promise<void>((resolve, reject) => {
              const request = store.get(key);
              request.onsuccess = () => {
                if (request.result) resultObject[request.result.key] = request.result.value;
                resolve();
              };
              request.onerror = () => reject(request.error);
            })
        );

        Promise.all(requests)
            .then(() => resolve(resultObject)) // Resolve with the resultObject containing all settings
            .catch(error => reject(error));
      };
    });
  }
}
