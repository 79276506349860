import {Component} from '@angular/core';
import {PageService} from "../../services/interface/page.service";
import {CommunicationsService} from "../../services/communications/communications.service";
import {ConfigurationService} from "../../services/configuration/configuration.service";
import {PageType} from "../../services/interface/pageType";
import {SystemTypes} from "../../services/support/isystem";
import {ControllerRegistry} from "../../services/registries/controller.registry.service";
import {ControlType} from "../../services/registries/control.type";
import {WeatherController} from "../../services/weather/weather.controller";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  constructor(protected communicationsService: CommunicationsService, protected configurationService: ConfigurationService, protected pageService: PageService) {
    const savedControllerId: string | undefined = pageService.getControllerForPageType(PageType.Dashboard);
    if (this.pageService.activeArea === undefined && savedControllerId !== undefined) {
      for (const areaName in this.configurationService.areas) {
        const area = this.configurationService.areas[areaName];
        if (area.controlId === savedControllerId) {
          this.pageService.activeArea = area;
          return;
        }
      }
      if (this.pageService.activeArea === undefined) {
        const areas = Object.values(this.configurationService.areas);
        if (areas.length > 0) this.pageService.activeArea = areas[0];
      }
    }
  }

  protected readonly SystemTypes = SystemTypes;
  protected readonly ControllerRegistry = ControllerRegistry;
  protected readonly ControlType = ControlType;
}


// TEST LOGIC
// areas: any[] = [];
//
// private subscribed: boolean = false;
// private pressMessageId: number | string = 0;
// public buttonTest(event: PointerEvent): void {
//   const buttonState = event.buttons > 0;
//   // const controlId : string = "33ffdad2-6d48-4e41-867a-aa787e5f169d";
//   // const audioSourceId: string = "fbdbde53-f65e-4808-b892-fd6e72452e17";
//   // const videoSourceId: string = "fbdbde53-f65e-4808-b892-fd6e72452e17";
//   // this.communicationsService.sendMessage(new ControlMessage(ControlMessage.IncrementId(), controlId, "selectSource", { audioSourceId: audioSourceId, videoSourceId: videoSourceId }))
//
//   this.communicationsService.sendMessage(new ControlMessage(ControlMessage.IncrementId(), "029b97c7-e784-4719-9bf2-2378be081c6a", "setLevel", { percent: 65535 * 0.08 }));
//   //this.communicationsService.sendMessage(new ControlMessage(ControlMessage.IncrementId(), "029b97c7-e784-4719-9bf2-2378be081c6a", "down", {}));
//
//
//   // if (!this.subscribed)
//   // {
//   //   this.communicationsService.sendMessage(new ControlMessage(ControlMessage.IncrementId(), undefined, "subscribe", { controlId: controlId, methods: ["fanMode", "mode", "floorMode", "setPoint", "temperature"] }));
//   //   this.communicationsService.sendControlMessage(controlId, "poll", {});
//   // }
//   // this.communicationsService.sendControlMessage(controlId, "setPointIncrease", {}, buttonState);
// }
