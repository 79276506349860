export class logger {
  private static styleNotice = ['color: #fff', 'background-color: #01ba9a', 'padding: 10px', 'border-radius: 2px'].join(';');
  private static styleError = ['color: #fff', 'background-color: #ff8080', 'padding: 10px', 'border-radius: 2px'].join(';');

  public static debugEnabled: boolean = true;

  public static debug(message: any, obj?: any): void {
    if (!this.debugEnabled) return;
    logger.printToConsole(message, this.styleNotice, obj);
  }

  public static error(message: any, obj?: any): void {
    logger.printToConsole(message, this.styleError, obj);
  }

  private static printToConsole(message: any, style: string, obj?: any) {
    const stack = new Error().stack;
    let location = '';
    if (stack) {
      const callerLine = stack.split('\n')[2]
      location = callerLine.trimStart().slice(3);
    }

    if (obj !== undefined) {
      console.log(`${location}\n%c${message}`, style, obj);
    } else {
      console.log(`${location}\n%c${message}`, style);
    }
  }
  
  public static registerCrestronLogger(): void {
    
  }
}
