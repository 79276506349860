export class BrowserLine{
  constructor(title: string, subtitle: string, url: string) {
    this.title = title;
    this.subtitle = subtitle;
    this.url = url;
  }
  
  public readonly title: string;
  public readonly subtitle: string;
  public readonly url: string;
}