import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PageService} from "../../services/interface/page.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MediaAreaController} from "../../pages/media/media.area.controller";
import {SystemTypes} from "../../services/support/isystem";
import {logger} from "../../services/support/logger";

@Component({
  selector: 'app-power-control',
  templateUrl: './power-control.component.html',
  styleUrl: './power-control.component.scss',
  animations: [
    trigger('slideUpDown', [
      state('up', style({
        bottom: '0%',
        opacity: 1
      })),
      state('down', style({
        bottom: '-100%',
        opacity: 0
      })),
      transition('up <=> down', [
        animate('0.3s ease-in-out')
      ])
    ])
  ]
})
export class PowerControlComponent  implements OnInit {
  constructor(protected pageService: PageService) {
  }

  lockScreenVisibility: boolean = false;
  lockedLoginForm: boolean = false;

  public ngOnInit(): void {
    this.lockScreenVisibility = false;
  }

  protected powerToggle(event: PointerEvent | MouseEvent | TouchEvent): void {
    if (!(event.target instanceof HTMLElement)) return;
    if (event.target.classList.length == 0) return;

    logger.debug('target:', event.target.classList[0]);
    
    if (!(event.target.classList[0] == "power-control-component" ||
      event.target.classList[0] == "power-control-content" ||
      event.target.classList[0] == "power-form" ||
      event.target.classList[0] == "question" ||
      event.target.classList[0] == "accent" ||
      event.target.classList[0] == "button-container" ||
      event.target.classList[0] == "small-circle" ||
      event.target.classList[0] == "user-icon" ||
      event.target.classList[0] == "fa-light")
    ) return;

    if (this.lockScreenVisibility) {
      this.lockScreenVisibility = false;
      this.lockedLoginForm = false;
      return;
    }
    this.lockedLoginForm = true;

    this.pageService.closeMenus();
    this.lockScreenVisibility = true;
  }
  
  protected shutDown() : void {
    const area: MediaAreaController | undefined = this.pageService.activeMediaArea;
    if (area === undefined) return;
    area.selectSource(undefined);
    this.lockScreenVisibility = false;
    this.lockedLoginForm = false;
  }
}
