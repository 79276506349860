import {CommunicationsService} from "../../services/communications/communications.service";
import {PoolSpaCircuitController} from "./pool-spa.circuit.controller";
import {PoolSpaBodyController} from "./pool-spa.body.controller";
import {Controller} from "../../services/configuration/controller";
import {System} from "../../services/configuration/system";
import {ControlType} from "../../services/registries/control.type";


export class PoolSpaController extends Controller {
    constructor(communicationsService: CommunicationsService, controlId: string, apiContracts: any, controlType: ControlType) {
        super(communicationsService, controlId, apiContracts, controlType);
        if (Array.isArray(apiContracts?.controllers['PoolBody'])) {
            apiContracts.controllers['PoolBody'].forEach(async (bodyId) => {
                const body = await System.CreateControllerAsync(communicationsService, bodyId);
                if (body === undefined || !(body instanceof PoolSpaBodyController)) return;
                this.bodies.push(body);
            });
        }
        if (Array.isArray(apiContracts?.controllers['PoolCircuit'])) {
            apiContracts.controllers['PoolCircuit'].forEach(async (circuitId) => {
                const circuit = await System.CreateControllerAsync(communicationsService, circuitId);
                if (circuit === undefined || !(circuit instanceof PoolSpaCircuitController)) return;
                this.circuits.push(circuit);
            });
        }
    }

    public bodies: PoolSpaBodyController[] = [];
    public circuits: PoolSpaCircuitController[] = [];

    public override async pollAsync(): Promise<void> {
        await super.pollAsync();
        await Promise.all(this.bodies.map(body => body.pollAsync()));
        await Promise.all(this.circuits.map(circuit => circuit.pollAsync()));
    }

    public override unsubscribeFromStatusMessages(): void {
        super.unsubscribeFromStatusMessages();
        this.bodies.forEach(body => body.unsubscribeFromStatusMessages());
        this.circuits.forEach(circuit => circuit.unsubscribeFromStatusMessages());
    }
}